export const SEARCHING_ES = {
  header: {
    title: 'Búsqueda',
    subtitle:
      'En esta página encontrarás todos los datos de nuestra búsqueda del candidato. Cuántas personas hemos encontrado, con cuántas hemos hablado y cuántas están interesadas.',
    subtitle_done: '',
  },
  numbers: {
    title_profilesCount: 'Captadas',
    title_profilesWithJobInterviewReportCount: 'Entrevistadas',
    title_interestedProfilesCount: 'Interesadas',
    title_interestedWithConcerns: 'Personas aptas que renuncian por',
    description_profilesCount:
      'Las personas que hemos encontrado por Internet como candidato ideal y con las cuales hemos contactado.',
    description_profilesWithJobInterviewReportCount:
      'Las personas a las que hemos conseguido atraer en la primera entrevista.',
    description_interestedProfilesCount:
      'Las personas que manifestaron interés por la oferta. Hemos hablado con la mayoría de ellos o tenemos programada la primera entrevista.',
    description_brand: 'Marca',
    description_role: 'Posición',
    description_compensation: 'Retribución',
    description_location: 'Ubicación',
  },
  daysCard: {
    'intro_to-do': 'después',
    intro_doing: 'entre',
    daysNumber: '10',
    days: 'días',
    days_one: 'día',
    'body_to-do':
      'después de la firma del contrato, podrás visualizar todos los datos',
    body_doing: 'podrás visualizar todos los datos',
  },
  jraCard: {
    title: 'Tu Job Reputation Analisys',
    subtitle:
      'El resumen de la actividad de búsqueda y los análisis de mercado',
    download: 'Descargar',
  },
  tasks: {
    title:
      '¿Quieres saber cómo va la búsqueda del candidato? <strong>Esta es mi actividad más reciente</strong>',
    viewLabel_grid: 'Vista de cuadrícula',
    viewLabel_list: 'Vista de lista',
    card: {
      date: '$t(common.formatting.date.dateFormat_time) - $t(common.formatting.date.dateFormat)',
      task: '',
      taskDescription: '',
      taskDescription_job_interview_reports_filled_in_one:
        'has interviewed {{ count }} candidate',
      taskDescription_job_interview_reports_filled_in:
        'has interviewed {{ count }} candidates',
      taskDescription_profiles_added_one:
        'has carried out {{ count }} hunting activity',
      taskDescription_profiles_added:
        'has carried out {{ count }} hunting activities',
      taskDescription_application_set_first_day_on_the_job:
        'has filled in the starting date of {{ candidateFullName }}',
      taskDescription_application_handle_client_interview_feedback:
        'has facilitated the final decision on {{ candidateFullName }}',
      taskDescription_application_handle_additional_client_interviews:
        'ha preparato il colloquio con {{ candidateFullName }}',
      taskDescription_application_help_negotiate_offer:
        'has facilitated the negotiation with {{ candidateFullName }}',
      taskDescription_application_present_offer_to_candidate:
        'has facilitated the hiring of {{ candidateFullName }}',
      taskDescription_application_schedule_client_interview:
        'has scheduled the interview between {{ companyName }} and {{ candidateFullName }}',
      taskDescription_application_ask_for_client_feedback_on_curriculum:
        'has received feedback on {{ candidateFullName }}',
      taskDescription_application_prepare_for_additional_client_interview:
        'has scheduled the interview between {{ companyName }} and {{ candidateFullName }}',
      taskDescription_application_handle_additional_client_interview_outcome:
        'ha contattato {{ candidateFullName }} per informarsi sul colloquio in azienda',
      taskDescription_application_prepare_for_client_interview:
        'has scheduled the interview between {{ companyName }} and {{ candidateFullName }}',
      taskDescription_application_handle_client_interview_outcome:
        'ha contattato {{ candidateFullName }} per informarsi sul colloquio in azienda',
      taskDescription_application_schedule_client_interview_after_scout_report:
        'has scheduled the interview between {{ companyName }} and {{ candidateFullName }}',
      taskDescription_application_notify_client_of_offer_refusal:
        'ha comunicato a {{ companyName }} che {{ candidateFullName }} si \u00e8 ritirato',
      taskDescription_application_check_in_day_before_new_job:
        'has wished good luck to {{ candidateFullName }}',
      taskDescription_application_check_in_after_fifteen_days_on_new_job:
        'has asked {{ candidateFullName }} for feedback on his new experience',
      taskDescription_application_check_in_after_fifty_days_on_new_job:
        'ha chiesto un ulteriore feedback a {{ candidateFullName }} sulla sua nuova esperienza',
      taskDescription_application_prepare_for_scout_interview:
        'has scheduled the scout interview with {{ candidateFullName }}',
      taskDescription_application_handle_client_dislike:
        'has asked the reason for negative feedback on {{ candidateFullName }}',
      taskDescription_application_ask_for_scout_report:
        'has received and analyzed the scout report',
      taskDescription_application_approve_scout_report:
        'has promoted {{ candidateFullName }} after the scout interview',
      taskDescription_job_send_profiling_meeting_recap_email:
        'has sent the profiling call recap email',
      taskDescription_job_schedule_presentation_meeting:
        'has scheduled the JRA date',
      taskDescription_job_contact_client_after_profiling_meeting:
        'has sent an email with a link allowing the access to the platform',
      taskDescription_job_prepare_for_presentation_meeting:
        'has sent candidate to the client',
      taskDescription_job_hunt_candidates:
        'has published the job ad on different channels',
      taskDescription_job_find_scout: 'has chosen the scout',
      taskDescription_job_schedule_profiling_meeting:
        'has scheduled the profiling videocall',
      taskDescription_job_prepare_job_post:
        'has published the job on Reverse\u0027s platform',
      taskDescription_job_prepare_for_profiling_meeting:
        'has prepared the profiling videocall',
    },
  },
};
