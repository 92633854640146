import { Action } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export const ApplicationFeedbackActions = ({
  isCustomerInterested,
  onFeedbackUpdate,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'candidateDetails.feedback',
  });

  const changeFeedbackButtonClassName = classNames('c-app-fat-button', {
    'c-app-fat-button--positive': isCustomerInterested === true,
    'c-app-fat-button--negative': isCustomerInterested === false,
  });

  return isCustomerInterested === null ? (
    <>
      <Action
        modifier="c-app-fat-button c-app-fat-button--positive"
        icon="icn-check-24"
        label={t('actionYes')}
        onClick={() => onFeedbackUpdate(true)}
      />

      <Action
        modifier="c-app-fat-button c-app-fat-button--negative"
        icon="icn-close-big-24"
        label={t('actionNo')}
        onClick={() => onFeedbackUpdate(false)}
      />
    </>
  ) : (
    <Action
      modifier={changeFeedbackButtonClassName}
      icon="icn-chevron-big-right-24"
      iconPosition="right"
      label={t('actionChangeFeedback')}
      onClick={() => onFeedbackUpdate(!isCustomerInterested)}
    />
  );
};
