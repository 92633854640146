export const ERRORS_FR = {
  employer_view: {
    eligible_application: {
      property: {
        customer_interest_notes: {
          too_long:
            "La motivation que tu as saisie est trop longue, merci d'insérer un texte plus court.",
        },
      },
    },
  },
};
