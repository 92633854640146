export const CANDIDATE_DETAILS_DE = {
  feedback: {
    bubble:
      'Was denken Sie über {{ candidateFullName }}? <strong>Ist er/sie interessant?</strong>',
    bubble_interested:
      '<strong>{{ candidateFullName }}</strong> ist interessant',
    bubble_notInterested:
      '<strong>{{ candidateFullName }}</strong> ist uninteressant',
    bubble_discarded:
      '<strong>{{ candidateFullName }}</strong> setzt nicht fort.',
    actionYes: 'Ja',
    actionNo: 'Nein',
    actionChangeFeedback: 'Haben Sie Ihre Meinung geändert?',
    interesting: 'Es ist interessant',
  },
  intro: {
    editLink: 'Bewerbung bearbeiten',
  },
  dossier: {
    title: 'Curriculum vitae',
    subtitle: 'Vorstellungsgespräch',
    button: 'Dossier runterladen',
  },
  scout: {
    accordionOpen: 'Mehr',
    accordionClose: 'Schließen',
    title: 'Scoutbericht',
    accordionTitle_skillsAssessment: 'Kompetenzbewertung',
    accordionTitle_overallAssessment: 'Rollenzusammenfassung',
    button: 'Bericht runterladen',
  },
  negativeFeedback: {
    title: 'Warum ist er/sie nicht interessant?',
    subtitle:
      'Zu wissen, warum Sie diesen Kandidaten nicht für geeignet für die Position halten (z.B. Fähigkeiten, Erfahrung, etc.) hilft uns, nur Kandidaten vorzustellen, die perfekt Ihren Erwartungen entsprechen.',
    notesLabel: 'Schreiben Sie die Begründung:',
    notesPlaceholder:
      'Die Begründung wird an den Headhunter gesendet, der die Suche betreut.',
    submit: 'Senden',
    cancel: 'Abbrechen',
    successTitle: 'Nachricht gesendet:',
    successSubtitle:
      'Wir haben Ihre Bewertung an den Headhunter weitergeleitet, der die Suche betreut.',
    successClose: 'Ok, danke.',
  },
};
