import React from 'react';
import { Icon, Pictogram } from '@reverse-hr/pattern-library';
import { NavLink } from 'react-router-dom';

const Rider = ({
  modifier,
  deal_id,
  application_id,
  name,
  infos,
  showLink,
}) => {
  return (
    <div className={`c-app-rider ${modifier}`}>
      {showLink ? (
        <NavLink
          to={`/${deal_id}/candidate_detail/${application_id}`}
          className="c-app-rider__name"
        >
          {name}
          <span className="c-app-rider__anchor-icon">
            <Icon name="icn-link-24" />
          </span>
        </NavLink>
      ) : (
        <div className="c-app-rider__name">{name}</div>
      )}
      <div className="c-app-rider__info">
        {infos.map((info, index) => {
          return (
            <div key={`infos-${index}`} className="c-app-rider__info-item">
              <Pictogram size="small" icon={info.icon}>
                {info.label}
              </Pictogram>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Rider.defaultProps = {
  modifier: '',
  infos: [],
  showLink: true,
};

export default Rider;
