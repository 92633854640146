import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import useInitializeApp from '../../utils/hooks/use-initialize-app';
import { LoaderFullPage } from '../../loaders/LoaderFullPage';
import { Homepage } from '../../pages/Homepage';
import { selectorRootState } from '../../model';
import { DealSwitch } from './DealSwitch';
import { PageLogin } from '../../pages/Login';
import { ROUTES } from '../../constants/navigation';
import { PageLoginVerificationCode } from '../../pages/LoginVerificationCode';
import { selectorSession } from '../../model/session';
import { ErrorScreenGeneric } from '../ErrorScreen';

const MainSwitch = () => {
  const history = useHistory();

  const { appError, currentRoute, initialized, authenticationError } =
    useStoreState(selectorRootState);

  const { loading } = useStoreState(selectorSession);

  const updateDocumentTitle = useStoreActions(
    actions => actions.updateDocumentTitle,
  );

  useInitializeApp();

  const isLoginPage = useRouteMatch({
    path: [ROUTES.LOGIN, ROUTES.LOGIN_VERIFICATION_CODE],
    exact: true,
  });

  useEffect(() => {
    if (currentRoute) {
      updateDocumentTitle();
    }
  }, [currentRoute, updateDocumentTitle]);

  useEffect(() => {
    if (authenticationError && !isLoginPage) {
      history.push(ROUTES.LOGIN);
    }
  }, [authenticationError, isLoginPage]);

  if (appError) {
    return <ErrorScreenGeneric />;
  }

  if (!initialized || loading) {
    return <LoaderFullPage />;
  }

  return (
    <Switch>
      <Route
        path={[
          '/candidates',
          '/customers/:customerId?',
          '/customers/:customerId?/candidates',
        ]}
      >
        <Homepage />
      </Route>
      <Route path={ROUTES.LOGIN}>
        <Switch>
          <Route path={ROUTES.LOGIN_VERIFICATION_CODE}>
            <PageLoginVerificationCode />
          </Route>
          <Route path="/">
            <PageLogin />
          </Route>
        </Switch>
      </Route>
      <Route path="/:id/:segment?">
        <DealSwitch />
      </Route>
      <Route index>
        <Homepage />
      </Route>
    </Switch>
  );
};

export default MainSwitch;
