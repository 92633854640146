import React from 'react';
import ContentLoader from 'react-content-loader';

const Closing = () => {
  return (
    <div>
      <ContentLoader viewBox="0 0 500 600">
        <rect x="0" y="10" width="80" height="12" />
        <rect x="0" y="30" width="220" height="8" />
        <rect x="0" y="42" width="200" height="8" />
        <rect x="0" y="54" width="160" height="8" />

        <rect x="0" y="85" width="60" height="8" />
        <rect x="5" y="105" width="30" height="10" />
        <rect x="38" y="105" width="40" height="10" />
        <rect x="100" y="105" width="10" height="10" />
        <rect x="5" y="125" width="10" height="10" />
        <rect x="22" y="126" width="80" height="8" />
        <rect x="5" y="140" width="10" height="10" />
        <rect x="22" y="141" width="70" height="8" />
        <rect x="5" y="165" width="60" height="20" />

        <rect x="370" y="40" width="100" rx="40" height="80" />
      </ContentLoader>
    </div>
  );
};

export default Closing;
