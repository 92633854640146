import React from 'react';
import { useStoreState } from 'easy-peasy';

import TextBlock from '../components/TextBlock';
import Brackets from '../components/Brackets';
import { profile } from '../utils/default-values';
import { selectorDeal } from '../model/Deal';
import { selectorCustomer } from '../model/Customer';
import { selectorUser } from '../model/User';
import { AppBanner } from '../components/AppBanner';
import { useTranslation } from 'react-i18next';
import { utilityGetPageState } from '../utils/get-page-state';
import { DEAL_PROGRESS_STATES } from '../constants/deal';
import { PageStateProvider } from '../components/Providers/PageStateProvider';
import { Referents } from '../components/Referents';
import { GTM } from '../gtm';
import { USER_TYPES_MAP } from '../constants/session';

const Profiling = () => {
  const { t } = useTranslation();
  const deal = useStoreState(selectorDeal);
  const customer = useStoreState(selectorCustomer);
  const { type: userType } = useStoreState(selectorUser);

  const { header, cards } = profile();

  const pageState = utilityGetPageState({
    dealProgress: deal.progress,
    currentRoute: DEAL_PROGRESS_STATES.PROFILING,
  });

  const handleDownloadContractClick = () => {
    const eventAction = `Download contratto ${
      userType === USER_TYPES_MAP.MANAGER ? 'prospect' : 'cliente'
    }`;

    GTM.trackGTMEvent({
      action: eventAction,
      label: `${customer.companyName} - ${deal.id} - ${deal.jobTitle}`,
    });

    GTM.trackGA4Event(
      eventAction,
      customer.companyName,
      `${deal.id} - ${deal.jobTitle}`,
    );
  };

  const handleMiroClick = () => {
    const eventAction = `Miro ${
      userType === USER_TYPES_MAP.MANAGER ? 'prospect' : 'cliente'
    }`;

    GTM.trackGTMEvent({
      action: eventAction,
      label: `${userType} - ${customer.companyName} - ${deal.id} - ${deal.jobTitle}`,
    });

    GTM.trackGA4Event(
      eventAction,
      customer.companyName,
      `${userType} - ${deal.id} - ${deal.jobTitle}`,
    );
  };

  return (
    <PageStateProvider value={pageState}>
      <section data-testid="profiling-page">
        <div className="container">
          <div className="row">
            {deal.miroUrl ? (
              <div className="col col--x-padded small-12 large-6">
                <div data-testid="profiling-page-header">
                  <TextBlock {...header} />
                </div>
                <div
                  data-testid="profiling-page-miro"
                  className="o-wrapper o-wrapper--base-width"
                >
                  <Brackets
                    items={cards.items}
                    button={{
                      icon: 'icn-chevron-big-right-24',
                      iconPosition: 'right',
                      label: cards.action.label,
                      url: deal.miroUrl,
                      onClick: handleMiroClick,
                    }}
                  />
                </div>
              </div>
            ) : null}

            <div className="col col--x-padded small-12 large-6">
              <Referents />
            </div>
          </div>
        </div>

        {deal.progress ? (
          <div className="container">
            <div className="row">
              <div className="col col--x-padded u-mt-space-16">
                <AppBanner
                  title={t('profile.contract.title')}
                  ctaLabel={t('profile.contract.download')}
                  ctaUrl={deal.contractUrl}
                  onClick={handleDownloadContractClick}
                />
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </PageStateProvider>
  );
};

export default Profiling;
