import { action, thunk } from 'easy-peasy';
import {
  I18N_DEFAULT_LOCALE,
  I18N_LOCALE_LIST,
  I18N_LOCALES,
} from '../constants/locales';
import i18next from 'i18next';
import { API_ENDPOINT } from '../utils/endpoints';
import { GTM } from '../gtm';
import Sentry from '../sentry';
import { USER_TYPES_MAP } from '../constants/session';
import { utilityChangeLocale } from '../utils/locale';

export const ModelUser = {
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  locale: null,
  type: null,

  fetchUser: thunk(async (actions, _, { getState, getStoreActions }) => {
    const token = localStorage.getItem('token');
    const { id } = getState();

    const {
      session: { handleResponse },
    } = getStoreActions();

    if (!!id || typeof token === 'undefined') {
      return;
    }

    try {
      const response = await fetch(`${API_ENDPOINT}/v1/auth/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        await handleResponse({ response });
      }

      const parsedResponse = await response.json();

      actions.setUser(parsedResponse);

      await utilityChangeLocale(parsedResponse.locale);
    } catch (error) {
      await utilityChangeLocale(error?.locale);
    }
  }),

  thunkTrackHomepageAccess: thunk(
    async (_actions, isHomepageCandidatesPage, { getState, getStoreState }) => {
      const { type: userType, email } = getState();

      const {
        customer: { companyName },
      } = getStoreState();

      const eventAction = isHomepageCandidatesPage ? 'Candidates' : 'Homepage';

      if (companyName && userType !== USER_TYPES_MAP.MANAGER) {
        GTM.trackGTMEvent({
          action: eventAction,
          label: companyName,
        });

        GTM.trackGA4Event(eventAction, companyName);

        Sentry.setUser({ userType, email });
      }
    },
  ),

  switchLanguage: thunk(async (_, selectedLanguage, { getStoreActions }) => {
    try {
      const {
        session: { authFetch },
      } = getStoreActions();

      await authFetch({
        endpoint: `/v1/auth/users/me/locale/${selectedLanguage}`,
        requestOptions: { method: 'PUT' },
      });

      window.location.reload();
    } catch (error) {
      console.warn(error);
      await utilityChangeLocale(error?.locale);
    }
  }),

  setUser: action((state, user) => {
    state.id = user.id;
    state.email = user.email;
    state.firstName = user.firstName;
    state.lastName = user.lastName;
    state.locale = user.locale;
    state.type = user.type;
  }),
};

export const selectorUser = state => state.user;
export const selectorUserActions = actions => actions.user;
