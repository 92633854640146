import React, { useState, useEffect } from 'react';

const useFormValidation = initialModel => {
  /**
   * {
   *  name: {
   *    value, valid
   *  }
   * }
   */
  const setInitialValidation = () => {
    let _validation = {};
    Object.keys(initialModel).forEach(key => {
      _validation[key] = initialModel[key].valid;
    });
    return _validation;
  };
  const setInitialModel = () => {
    let _model = {};
    Object.keys(initialModel).forEach(key => {
      _model[key] = initialModel[key].value;
    });
    return _model;
  };

  const [model, setModel] = useState(setInitialModel());
  const [validation, setValidation] = useState(setInitialValidation());
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(!Object.values(validation).some(value => !value));
  }, [validation]);

  const onChange = event => {
    const { name, value, valid } = event;
    setValidation(oldValidation => ({ ...oldValidation, [name]: valid }));
    setModel(oldModel => ({ ...oldModel, [name]: value }));
  };

  const onSubmit = (onSuccess, onError = () => {}) => {
    const isInvalid = Object.values(validation).some(value => !value);
    if (!isInvalid) {
      onSuccess(model);
    } else {
      onError(validation, model);
    }
  };

  return {
    onChange,
    onSubmit,
    isFormValid,
  };
};

export { useFormValidation };
