import { useTranslation } from 'react-i18next';
import { ActionMenu } from '@reverse-hr/pattern-library';
import { useStoreActions } from 'easy-peasy';
import { selectorRootActions } from '../../model';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useStoreActions(selectorRootActions);

  const languageSwitcherItems = Object.keys(i18n.options?.resources)?.map(
    language => ({
      key: language,
      itemType: 'simple',
      label: t(`language_switcher.${language}`),
      disabled: false,
      selected: language === i18n.language,
    }),
  );

  const handleLanguageSelection = ({ key, selected }) => {
    if (selected) return;
    changeLanguage(key);
  };

  return (
    <ActionMenu
      buttonProps={{
        type: 'raw',
        label: t(`language_switcher.${i18n.language}`),
        icon: 'icn-chevron-big-down-24',
        iconPosition: 'right',
      }}
      items={languageSwitcherItems}
      onItemClick={handleLanguageSelection}
    />
  );
};

export default LanguageSwitcher;
