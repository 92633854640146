import { Avatar, Chip } from '@reverse-hr/pattern-library';
import { getConcernsCategoriesString } from '../../../../../utils/application';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const Photo = props => {
  const {
    candidateAvatar,
    candidateFirstName,
    candidateLastName,
    candidateJobTitle,
    id,
    concerns,
    isDiscarded,
  } = props;
  const history = useHistory();
  const { id: dealId } = useParams();
  const { t } = useTranslation();

  const containerClassNames = classNames({
    'c-app-showcase__cell': true,
    'c-app-showcase__cell--center-aligned': true,
    'c-app-showcase__cell--interested-but': concerns !== null && !isDiscarded,
    'c-app-showcase__cell--out': isDiscarded,
  });

  const avatarSize = concerns !== null || isDiscarded ? 24 : 72;

  const handleClick = () => {
    if (dealId && id) {
      history.push(`/${dealId}/candidate_detail/${id}`);
    }
  };

  const renderCandidateOutChip = () =>
    isDiscarded && (
      <Chip
        modifier="c-chip--no-hover u-bg-color-error-color-40 u-color-error-color-120"
        size="small"
        clickable={false}
        leftIcon="icn-user-close-24"
      >
        {t('showcase.chip.out')}
      </Chip>
    );

  const renderInterestedButChip = () => {
    if (!isDiscarded && concerns !== null) {
      const uniqueCategories = getConcernsCategoriesString(concerns, t);

      return (
        <>
          <Chip
            modifier="c-chip--no-hover u-bg-color-accent-60 u-color-accent-120"
            size="small"
            clickable={false}
            leftIcon="icn-warning-outline-24"
          >
            {t('showcase.chip.interestedBut')}
          </Chip>
          <p className="typo-text-caption">
            <strong>{uniqueCategories}</strong>
          </p>
        </>
      );
    }
    return null;
  };

  return (
    <td className={containerClassNames}>
      <div onClick={handleClick} className="c-app-showcase--photo-link">
        <Avatar avatar={candidateAvatar || null} size={avatarSize} />
        <p className="u-txt-underline typo-text-body u-color-primary-100 u-mt-space-8">
          <strong>
            {candidateFirstName || ''} {candidateLastName || ''}
          </strong>
        </p>
        <p className="typo-text-caption u-color-grey-80 u-mb-space-8">
          {candidateJobTitle}
        </p>
        {renderCandidateOutChip()}
        {renderInterestedButChip()}
      </div>
    </td>
  );
};

export default Photo;
