import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import TheHeader from '../components/TheHeader';
import TheFooter from '../components/TheFooter';
import Masthead from '../components/Masthead';
import Helper from '../components/Helper';
import Stepper from '../components/Stepper';
import Modal from '../components/Modal';
import { ShareLinkModal } from '../components/ShareLinkModal';

import { header, hero } from '../utils/default-values';
import useShareModal from '../utils/hooks/use-share-modal';
import { selectorDeal } from '../model/Deal';
import { selectorCustomer } from '../model/Customer';
import { selectorUser } from '../model/User';
import { MODAL_IDS_MAP } from '../constants/modals';
import { GTM } from '../gtm';
import { USER_TYPES_MAP } from '../constants/session';

const MainLayout = ({ children }) => {
  const [helperText, setHelperText] = useState(null);
  const [manager, setManager] = useState(null);

  const location = useLocation();

  // STATE
  const customer = useStoreState(selectorCustomer);
  const user = useStoreState(selectorUser);
  const deal = useStoreState(selectorDeal);
  const currentRoute = useStoreState(state => state.currentRoute);
  const currentStatus = useStoreState(state => state.currentStatus);
  const isModalOpened = useStoreState(state => state.isModalOpened);
  const modalId = useStoreState(state => state.modalId);

  const { handleShareButtonClick } = useShareModal();

  useEffect(() => {
    if (currentRoute) {
      const payload =
        currentRoute === 'profiling'
          ? {
              name: user.firstName,
              context: user.firstName !== null && 'withName',
            }
          : null;

      setHelperText(hero(currentStatus, payload).helperText[currentRoute]);
    }
  }, [location, currentRoute, currentStatus]);

  useEffect(() => {
    if (deal.contactPersons) {
      let manager = deal.contactPersons[0];

      if (deal.contactPersons.length > 0) {
        manager = deal.contactPersons[1];
      }

      setManager(manager);
    }
  }, [deal.contactPersons]);

  const handleAnalytics = step => {
    const eventAction = `Navigazione ${
      user.type === USER_TYPES_MAP.MANAGER ? 'prospect' : 'cliente'
    }`;

    GTM.trackGTMEvent({
      action: eventAction,
      label: `${customer.companyName} - ${deal.id} - ${deal.jobTitle} - ${step.name}`,
    });

    GTM.trackGA4Event(
      eventAction,
      customer.companyName,
      `${deal.id} - ${deal.jobTitle} - ${step.name}`,
    );
  };

  const getSteps = () => {
    return hero().steps(!!deal.miroUrl);
  };

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col col--x-padded u-py-space-16">
            <TheHeader labels={header()} onClick={handleShareButtonClick} />
          </div>
        </div>
      </div>
      <Masthead
        deal={deal}
        currentRoute={currentRoute}
        isBackToHomepageAvailable
      >
        {{
          left: (
            <div className="c-app-masthead__stepper">
              <Stepper
                steps={getSteps()}
                currentRoute={currentRoute}
                currentStep={deal.progress}
                onRouteChange={handleAnalytics}
              />
            </div>
          ),
          right: (
            <Helper
              modifier="c-app-helper--masthead"
              text={helperText}
              manager={manager}
            />
          ),
        }}
      </Masthead>
      <div className="c-main-layout">{children}</div>
      <TheFooter />
      <Modal visible={isModalOpened && modalId === MODAL_IDS_MAP.SHARE}>
        <ShareLinkModal />
      </Modal>
    </main>
  );
};

export default MainLayout;
