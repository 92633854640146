export const INTERVIEWING_FR = {
  header: {
    title: 'Entretiens',
    subtitle:
      'Vous trouverez ici la liste des candidats. Vous pourrez toujours voir à quel stade ils se trouvent et laisser un commentaire pour les faire avancer dans le processus de sélection.',
    subtitle_bis:
      'Vous trouvez ici la liste des candidats. Vous pouvez toujours voir à quel stade ils se trouvent et laisser un commentaire pour les faire avancer dans le processus de sélection.',
    alert:
      'Il y a des candidats en phase finale, pour ne pas les perdre <strong>dissipons rapidement les doutes</strong>',
  },
  referenceHeader: {
    title: 'Vos contacts',
  },
  tabs: {
    see: 'Vu par',
    all: 'Tous',
    active: 'Dans le processus de sélection',
    discarded: 'Ils ne poursuivent pas',
  },
  eligibles: {
    curriculum_seen: 'Curriculum vu',
    curriculum_seen_text: 'Laissez un commentaire',
    curriculum_not_seen: 'Curriculum non vu',
    curriculum_not_seen_text: 'Lisez et laissez un commentaire',
    interesting: 'Intéressant',
    not_interesting: 'Pas intéressant',
    withdrawn: 'Candidature retirée',
    out: "N'avance pas",
  },
  scout: {
    to_be_created: 'En attente du rapport',
    available: 'Rapport publié',
    available_text: 'Lire le rapport',
    done: 'Rapport publié',
    withdrawn: 'Candidature retirée',
    out: "N'avance pas",
  },
  interviewing: {
    to_be_created: 'À interviewer',
    to_be_created_text: "En cours d'organisation...",
    incoming: 'À interviewer',
    incoming_text: 'Téléchargez le dossier',
    outcome: 'Entretien réalisé',
    outcome_text: 'Laissez un commentaire',
    done: 'Entretien réalisé',
    withdrawn: 'Candidature retirée',
    out: "N'avance pas",
  },
  closing: {
    to_be_created: 'Faire une offre',
    to_be_created_text: 'Ne le/la perdons pas!',
    offered: 'Offre présentée',
    offered_text: "Dans l'attente de {{ name }}",
    winner: 'Gagnant/e!',
    withdrawn: 'Candidature retirée',
    out: "N'avance pas",
    offer_declined: 'Offre refusée',
  },
};
