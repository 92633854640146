import i18next from 'i18next';
const globalRules = {
  emails: {
    validate: value => {
      const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const msg = i18next.t('validation.email') || 'error';
      const arr = value.split(',');
      let error = false;
      if (arr.filter(v => !!v).length) {
        arr.forEach(mail => {
          if (!reg.test(mail.trim())) {
            error = true;
          }
        });
      }
      return error ? msg : true;
    },
  },
  email: {
    validate: value => {
      const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const msg = i18next.t('validation.email') || 'error';

      return reg.test(value) || msg;
    },
  },
  required: {
    validate: value =>
      !!value.trim() || i18next.t('validation.required') || 'error',
  },
  postpone: {
    validate: value =>
      Date.parse(new Date(value)) > Date.parse(new Date()) || 'error',
  },
  domain: {
    validate: (value, domain) =>
      value.split('@')[1] === domain ||
      i18next.t('modal.share.inputs.email.hint'),
  },
};

export const getValidation = rules => {
  let validation = {};
  rules.forEach(rule => {
    validation = {
      ...validation,
      [rule]: globalRules[rule],
    };
  });
  return validation;
};

export const validate = (rule, value, ...options) => {
  return globalRules[rule].validate(value, ...options);
};
