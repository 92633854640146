export const SUCCESS_FEE_IT = {
  backCta: 'Torna al mandato',
  moreInfo: 'Maggiori informazioni',
  successFee: {
    title: 'Success Fee',
    footer: 'Totale success fee',
    successFeeFromSalary: {
      title: '{{ successFeePercentageOfCompensationPackage }}% di RAL',
      description:
        'Package {{ annualSalaryValue, currency }} per la % di fee indicata nel contratto {{ successFeePercentageOfCompensationPackage }}%.<br/>Calcolo: {{ successFeePercentageOfCompensationPackage }}% * {{ annualSalaryValue, currency }}',
    },
    bonusBenefitOffer: {
      title: '{{ bonusBenefit.description }}',
      description:
        'Premio variabile calcolato come la percentuale {{ bonusBenefit.percentage }}% del valore massimo percepibile dal Profilo su base annua. <br/>Calcolo: {{ successFeePercentageOfCompensationPackage }}% * {{ bonusBenefit.percentage }}% * {{ bonusBenefit.amount, currency }}',
    },
    bonusBenefitSalary: {
      title: '{{ bonusBenefit.description }}',
      description:
        'Premio variabile genericamente indicato calcolato come la percentuale {{ bonusBenefit.percentage }}% della RAL del profilo. <br/>Calcolo: {{ successFeePercentageOfCompensationPackage }}% * {{ bonusBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    carBenefit: {
      title: 'Assegnazione auto',
      description:
        "Valore di {{ companyCarBenefit.value, currency }} stabilito a contratto per assegnazione dell'autovettura aziendale conteggiato nella misura del {{ successFeePercentageOfCompensationPackage }}%. <br/>Calcolo: {{ successFeePercentageOfCompensationPackage }}% * {{ companyCarBenefit.value, currency }}",
    },
    healthInsuranceBenefit: {
      title: 'Assicurazioni sanitarie',
      description:
        'Quota derivante dalle assicurazioni sanitarie nella misura del {{ healthInsuranceBenefit.percentage }}%. <br/>Calcolo: {{ successFeePercentageOfCompensationPackage }}% * {{ healthInsuranceBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    accommodationBenefit: {
      title: 'Eventuale numero di mesi alloggio',
      description:
        "Quota derivante dall'alloggio nella misura del {{ accommodationBenefit.percentage }}%. <br/>Calcolo: {{ successFeePercentageOfCompensationPackage }}% * {{ accommodationBenefit.percentage }}% * {{ annualSalaryValue, currency }}",
    },
    stockOptionsBenefit: {
      title: 'Stock options / partecipazioni societarie',
      description:
        'Quota derivante dalle Stock options/partecipazioni societarie nella misura del {{ stocksBenefit.percentage }}%. <br/>Calcolo: {{ successFeePercentageOfCompensationPackage }}% * {{ stocksBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    overallFee: {
      title: 'Fee complessiva',
    },
    minimumSuccessFeeAmount: {
      title: 'Minimale',
      description:
        'In accordo col contratto firmato il valore della fee complessiva è stato impostato a {{ minimumAmount, currency }} (minimale)',
    },
  },
  prePayments: {
    title: 'Acconti già fatturati',
    footer: 'Totale acconti',
    retainerFee: {
      title: 'Retainer fee',
    },
    confidenceFee: {
      title: 'Confidence fee',
    },
  },
  total: {
    title: 'Totale',
    successFee: {
      title: 'Success fee',
    },
    prePayments: {
      title: 'Eventuali acconti a voi già fatturati',
    },
    discount: {
      title: 'Sconto concordato ({{ discountPercentage }}%)',
    },
    minimumSuccessFeeAmount: {
      title: '$t(successFee.successFee.minimumSuccessFeeAmount.title)',
      description:
        '$t(successFee.successFee.minimumSuccessFeeAmount.description)',
    },
  },
};
