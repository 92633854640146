const SHOWCASE_FR = {
  title: 'Showcase',
  subtitle:
    'Cette page vous permet de comparer les caractéristiques de chacun des profils présentés.',
  mobileView:
    'Nous avons optimisé cette expérience sur Desktop, pour rendre plus exploitables les données et évaluations sur les candidats.',
  lastViewUpdate:
    'Consultation enregistrée le {{ date, datetime(format: dd/MM/yyyy) }} à {{ date, datetime(format: HH:mm) }}',
  yes: 'Oui',
  no: 'Non',
  bubbleText:
    'Cette page compare les caractéristiques des candidats qui vous ont été présentés. Un outil pour vous aider à choisir les bons profils.',
  emptyCandidateList: 'Vous trouverez ici la liste des candidats.',
  tableRows: {
    photo: 'Photo',
    age: 'Âge',
    seniority: 'Expérience',
    location: 'Résidence',
    must_have: 'Must have',
    nice_to_have: 'Nice to have',
    current_salary: 'Salaire actuel',
    salary: 'Prétentions',
    company: 'Entreprise',
    other_selections: 'Autres<1></1>processus<1></1>de sélection',
    motivation: "A l'écoute",
    summary: 'Synthèse',
    benefits: 'Bonus',
  },
  filters: {
    restoreView: 'Restaurer la consultation',
    interestedBut: 'Voir <1>interested but</1>',
    out: 'Voir <1>candidats out</1>',
  },
  benefitsCategories: {
    car: 'Véhicule de fonction',
    canteen: 'Cantine',
    training: 'Formation',
    welfare: 'Avantages sociaux',
    meal_vouchers: 'Tickets restaurant',
    insurance: 'Mutuelle',
    smartphone: 'Téléphone',
    remote_working: 'Télétravail',
    personal_computer: 'PC',
  },
  concernsCategories: {
    brand: 'Marque',
    role: 'Rôle',
    compensation: 'Salaire',
    location: 'Localisation',
  },
  location: {
    locationAndDistance: '<strong>{{ location }}</strong>',
    locationAndDistance_withDistance:
      '<strong>{{ location }}</strong> ({{ distance }}km)',
  },
  summary: {
    readmore: 'Lire plus',
    hide: 'Masquer',
  },
  chip: {
    interestedBut: 'Interested but',
    out: 'Out',
  },
  motivationToChangeValues: {
    low: 'Low',
    high: 'High',
    medium: 'Medium',
  },
  drivingTime: {
    hours: '<b>{{ hours }}</b> h',
    minutes: '<b>{{ minutes }}</b> min',
  },
  otherSelections: {
    currentSelectionsCount_one: '{{ count }} processus de sélection',
    currentSelectionsCount_other: '{{ count }} processus de sélection',
  },
};

export default SHOWCASE_FR;
