export const TableRowsNames = {
  PHOTO: 'photo',
  AGE: 'age',
  SENIORITY: 'seniority',
  LOCATION: 'location',
  MUST_HAVE: 'must_have',
  NICE_TO_HAVE: 'nice_to_have',
  CURRENT_SALARY: 'current_salary',
  SALARY: 'salary',
  OTHER_SELECTIONS: 'other_selections',
  BENEFITS: 'benefits',
  COMPANY: 'company',
  MOTIVATION: 'motivation',
  SUMMARY: 'summary',
};

const TableRows = [
  { id: TableRowsNames.PHOTO, hasVisibilityAction: false },
  { id: TableRowsNames.AGE, hasVisibilityAction: true },
  { id: TableRowsNames.SENIORITY, hasVisibilityAction: true },
  { id: TableRowsNames.LOCATION, hasVisibilityAction: true },
  { id: TableRowsNames.MUST_HAVE, hasVisibilityAction: true },
  { id: TableRowsNames.NICE_TO_HAVE, hasVisibilityAction: true },
  { id: TableRowsNames.CURRENT_SALARY, hasVisibilityAction: true },
  { id: TableRowsNames.SALARY, hasVisibilityAction: true },
  { id: TableRowsNames.BENEFITS, hasVisibilityAction: true },
  { id: TableRowsNames.COMPANY, hasVisibilityAction: true },
  { id: TableRowsNames.OTHER_SELECTIONS, hasVisibilityAction: true },
  { id: TableRowsNames.MOTIVATION, hasVisibilityAction: true },
  { id: TableRowsNames.SUMMARY, hasVisibilityAction: true },
];

export default TableRows;
