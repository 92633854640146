export const SUCCESS_FEE_DE = {
  backCta: 'Zurück zum Mandat',
  moreInfo: 'Mehr Informationen',
  successFee: {
    title: 'Success Fee',
    footer: 'Total success fee',
    successFeeFromSalary: {
      title: '{{ successFeePercentageOfCompensationPackage }}% des Gehalts',
      description:
        'Packet {{ annualSalaryValue, currency }} mal den % des Fees im Vertrag {{ successFeePercentageOfCompensationPackage }}%. <br/>Berechnung: {{ successFeePercentageOfCompensationPackage }}% * {{ annualSalaryValue, currency }}',
    },
    bonusBenefitOffer: {
      title: '{{ bonusBenefit.description }}',
      description:
        'Variabler Bonus berechnet als Prozent {{ bonusBenefit.percentage }}% des maximalen vom Bewerber erbrachten Wertes auf Jahresbasis. <br/>Berechnung:  {{ successFeePercentageOfCompensationPackage }}% * {{ bonusBenefit.percentage }}% * {{ bonusBenefit.amount, currency }}',
    },
    bonusBenefitSalary: {
      title: '{{ bonusBenefit.description }}',
      description:
        'Variabler Bonus, berechnet wie der Pozentanteil {{ bonusBenefit.percentage }}% des Bruttojahreseinkommens. <br/>Berechnung: {{ successFeePercentageOfCompensationPackage }}% * {{ bonusBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    carBenefit: {
      title: 'Firmenwagen',
      description:
        'Wert des {{ companyCarBenefit.value, currency }} im Vertrag festgehalten für die Zuteilung des Firmenwagens in Höhe von {{ successFeePercentageOfCompensationPackage }}%. <br/>Berechnung: {{ successFeePercentageOfCompensationPackage }}% * {{ companyCarBenefit.value, currency }}',
    },
    healthInsuranceBenefit: {
      title: 'Kranversicherung',
      description:
        'Anteil aus Krankenversicherung von {{ healthInsuranceBenefit.percentage }}%. <br/>Berechnung: {{ successFeePercentageOfCompensationPackage }}% * {{ healthInsuranceBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    accommodationBenefit: {
      title: 'Anzahl der bezahlten Monatsmieten',
      description:
        'Anteil aus einem Unterkunftsbonus von {{ accommodationBenefit.percentage }}%. <br/>Berechnung: {{ successFeePercentageOfCompensationPackage }}% * {{ accommodationBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    stockOptionsBenefit: {
      title: 'Stock Options',
      description:
        'Anteil aus Stock Options / Unternehmensshareholdings von {{ stocksBenefit.percentage }}%. <br/>Berechnung: {{ successFeePercentageOfCompensationPackage }}% * {{ stocksBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    overallFee: {
      title: 'Overall fee',
    },
    minimumSuccessFeeAmount: {
      title: 'Mindestbetrag',
      description:
        'Der Wert des gesamten Fee beträgt {{ minimumAmount, currency }} (Mindestbetrag), in Übereinstimmung mit dem unterzeichneten Vertrag',
    },
  },
  prePayments: {
    title: 'Schon abgerechnete Anzahlungen',
    footer: 'Summe der Anzahlungen',
    retainerFee: {
      title: 'Retainer fee',
    },
    confidenceFee: {
      title: 'Confidence fee',
    },
  },
  total: {
    title: 'Total',
    successFee: {
      title: 'Success fee',
    },
    prePayments: {
      title: 'Bezahlte Vorauszahlung',
    },
    discount: {
      title: 'Vereinbarter Rabatt ({{ discountPercentage }}%)',
    },
    minimumSuccessFeeAmount: {
      title: '$t(successFee.successFee.minimumSuccessFeeAmount.title)',
      description:
        '$t(successFee.successFee.minimumSuccessFeeAmount.description)',
    },
  },
};
