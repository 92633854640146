import { selectorDeal } from '../../model/Deal';
import { useStoreState } from 'easy-peasy';
import { SEARCHING_TASKS_VIEW_TYPES } from '../../constants/searching';
import { AppTasksStack } from './AppTasksStack';
import { AppTasksMasonry } from './AppTasksMasonry';

export const AppTasks = ({ currentView }) => {
  const { activities } = useStoreState(selectorDeal);

  const sortedActivities = [...activities].sort(sortTasksByDate);

  if (currentView === SEARCHING_TASKS_VIEW_TYPES.LIST) {
    return <AppTasksStack activities={sortedActivities} />;
  }

  if (currentView === SEARCHING_TASKS_VIEW_TYPES.GRID) {
    return <AppTasksMasonry activities={sortedActivities} />;
  }

  return null;
};

const sortTasksByDate = (a, b) => (a.dateTime < b.dateTime ? 1 : -1);
