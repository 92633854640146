export const HOMEPAGE_DE = {
  header: {
    navigation: {
      jobs: 'Alle Aufträge',
      candidates: 'Ihre Bewerber',
    },
  },
  jobList: {
    title_open: 'Aufträge in Bearbeitung',
    title_closed: 'Abgeschlossene Aufträge',
    cardState_searching: '$t(hero.steps.searching.title)',
    cardState_interviewing: '$t(hero.steps.interviewing.title)',
    cardState_closing: '$t(hero.steps.closing.title)',
    cardState_closed: 'Abgeschlossen am $t(common.formatting.date.dateFormat)',
  },
  candidates: {
    filterByJob: 'Filtern nach Mandat',
    allJobs: 'Alle Mandate',
    allStates: 'Alle Kandidaten in Wartestellung',
    title_active: 'Aktive Kandidaten',
    intro_active:
      "Hier finden Sie alle aktiven Kandidaten in Ihrem offenen Auswahlverfahren.<br/>Die Spalte '$t(homepage.candidates.table.header.state)' gibt an, was noch aus Ihrer Sicht aussteht, um den Auswahlprozess fortzusetzen, während '$t(homepage.candidates.table.header.lastUpdate_active)' angibt, wie lange der Kandidat darauf wartet. Je länger die Wartezeit zunimmt, desto wahrscheinlicher ist es, den Kandidaten zu verlieren.<br/><br/>Wenn Sie weitere Informationen wünschen, können Sie das Profil eines Kandidaten über seinen Namen abrufen oder den Fortschritt einer Einzelauswahl über eine Position anzeigen lassen.",
    title_hired: 'EIngestellte Bewerber',
    intro_hired: 'Hier finden Sie alle Bewerber, die eingestellt wurden.',
    title_stopped: 'Ausgeschlossene Kandidaten',
    intro_stopped:
      "Hier findest du alle abgelehnten oder zurückgezogenen Kandidaten. Du kannst in der Spalte '$t(homepage.candidates.table.header.state_stopped)' nachsehen, in welcher Phase des Prozesses sie gestoppt haben, zusammen mit dem Datum der letzten Aktualisierung und der Anzahl der geführten Interviews mit jedem.",
    table: {
      header: {
        candidateFirstName: 'Kandidat',
        jobTitle: 'Mandat',
        state: 'Status',
        state_active: 'Warten auf',
        state_stopped: 'Auswahlphase',
        lastUpdate: 'Letztes Aktualisierung',
        lastUpdate_active: 'Wartezeit in Tagen',
        latestInterviewAt: 'Zuletzt geführtes Vorstellungsgespräch',
        numberOfClientInterviews: 'Durchgeführte Vorstellungsgespräche',
        hireDate: 'Einstellungsdatum',
        missedReason: 'Grund für die Unterbrechung',
      },
      states: {
        presented: 'Kandidatenvorstellung',
        positive_curriculum_feedback:
          'Positives Feedback nach der Präsentation',
        interview_scheduled: 'Vereinbartes Einführungsgespräch',
        interview_done: 'Durchgeführtes Kennenlerngespräch',
        interview_interested: 'Positives Feedback nach dem Kennenlerngespräch',
        interview_meet_again: 'Vertiefungsgespräche',
        offer_incoming: 'Angebot in Erwartung',
        offer_made: 'Angebot vorgelegt',
        presented_active: 'Eine Rückmeldung zum Lebenslauf zu erhalten',
        positive_curriculum_feedback_active:
          'Das erste Vorstellungsgespräch zu vereinbaren',
        interview_scheduled_active: 'Das Vorstellungsgespräch zu führen',
        interview_done_active: 'Rückmeldung nach Vorstellungsgespräch erhalten',
        interview_interested_active: 'Voranschreiten nach positivem Feedback',
        interview_meet_again_active: 'Die Interviewphase abschließen',
        offer_incoming_active: 'Ein Angebot von Ihnen zu erhalten',
        offer_made_active: 'Die Verhandlung abschließen',
      },
      missedReason_rejected: 'Abgelehnt',
      missedReason_withdrawn: 'Zurückgezogen',
      missedReason_offer_rejected: 'Angebot abgelehnt',
      noApplications: 'Keine Kandidaten gefunden.',
      noApplications_error:
        'Etwas ist schiefgegangen, versuchen Sie, die Seite neu zu laden.',
    },
  },
};
