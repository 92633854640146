import { AppSummary } from '../AppSummary';
import { useTranslation } from 'react-i18next';
import { AppCount } from '../AppCount';
import { utilityGetPrePayments } from '../../utils/success-fee';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const SuccessFeePrepayments = ({ prospect }) => {
  const { t } = useTranslation();

  const { currency } = prospect;
  const { counts, total } = utilityGetPrePayments(prospect);

  return (
    !!counts.length && (
      <AppSummary
        className="c-app-summary--stacked-centered c-app-summary--billed u-mt-space-32 c-app-summary--primary"
        title={t('successFee.prePayments.title')}
        footerLabel={t('successFee.prePayments.footer')}
        total={total}
        currency={currency}
      >
        {counts.map((count, countIdx) => (
          <AppCount
            key={count.key}
            className={getCountClassNames(countIdx)}
            title={t(`successFee.prePayments.${count.key}.title`)}
            price={count.value}
            currency={currency}
          />
        ))}
      </AppSummary>
    )
  );
};

SuccessFeePrepayments.propTypes = {
  prospect: PropTypes.object.isRequired,
};

const getCountClassNames = countIdx =>
  classNames('c-app-count--billed', {
    'c-app-count--neutral-even': !(countIdx % 2),
    'c-app-count--billed-colored c-app-count--neutral-odd': countIdx % 2,
  });
