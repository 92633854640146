const SHOWCASE_ES = {
  title: 'Showcase',
  subtitle:
    'Esta página te permite comparar los candidatos en base a los requerimientos más importantes',
  mobileView:
    'Hemos optimizado esta experiencia en <1>Desktop</1> para que los datos y las evaluaciones de los candidatos sean más útiles',
  lastViewUpdate:
    'Datos guardados el {{ date, datetime(format: dd/MM/yyyy) }} a las {{ date, datetime(format: HH:mm) }}',
  yes: 'Sí',
  no: 'No',
  bubbleText:
    'Esta página compara las características de los perfiles que se le presentan. Una herramienta para ayudarte a elegir a las personas adecuadas.',
  emptyCandidateList: 'Aquí encontrarás la lista de candidatos.',
  tableRows: {
    photo: 'Foto',
    age: 'Edad',
    seniority: 'Seniority',
    location: 'Domicilio',
    must_have: 'Must have',
    nice_to_have: 'Nice to have',
    current_salary: 'Salario actual',
    salary: 'Aspiración salarial',
    company: 'Empresa',
    other_selections: 'Otros<1></1>procesos<1></1>de selección',
    motivation: 'Motivación',
    summary: 'Resumen',
    benefits: 'Benefits',
  },
  filters: {
    restoreView: 'Restaurar',
    interestedBut: 'Muestra <1>Interesados pero</1>',
    out: 'Muestra <1>Candidatos descartados</1>',
  },
  benefitsCategories: {
    car: 'Coche',
    canteen: 'Cantina',
    training: 'Formación',
    welfare: 'Welfare',
    meal_vouchers: 'Ticket restaurante',
    insurance: 'Seguro',
    smartphone: 'Teléfono',
    remote_working: 'Teletrabajo',
    personal_computer: 'PC',
  },
  concernsCategories: {
    brand: 'Marca',
    role: 'Rol',
    compensation: 'Salario',
    location: 'Localización',
  },
  location: {
    locationAndDistance: '<strong>{{ location }}</strong>',
    locationAndDistance_withDistance:
      '<strong>{{ location }}</strong> ({{ distance }}km)',
  },
  summary: {
    readmore: 'Leer más',
    hide: 'Esconder',
  },
  chip: {
    interestedBut: 'Interesados pero',
    out: 'Candidatos descartados',
  },
  motivationToChangeValues: {
    low: 'Bajo',
    high: 'Alto',
    medium: 'Medio',
  },
  drivingTime: {
    hours: '<b>{{ hours }}</b> h',
    minutes: '<b>{{ minutes }}</b> min',
  },
  otherSelections: {
    currentSelectionsCount_one: '{{ count }} proceso de selección',
    currentSelectionsCount_other: '{{ count }} procesos de selección',
  },
};

export default SHOWCASE_ES;
