import React from 'react';
import ContentLoader from 'react-content-loader';
import { HomepageLoader } from './HomepageLoader';
import { useHomepageRouteMatch } from '../utils/hooks/use-homepage-route-match';

export const LoaderFullPage = () => {
  const { isHomepage } = useHomepageRouteMatch();

  return isHomepage ? (
    <HomepageLoader />
  ) : (
    <>
      <div className="container">
        <div className="row">
          <div className="col col--x-padded u-mb-space-4">
            <ContentLoader
              width="100%"
              height="84"
              data-testid="content-loader-header"
            >
              <rect
                width="100%"
                height="84"
                data-testid="content-loader-rect-header"
              />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col col--x-padded small-12 large-9">
            <ContentLoader
              width="100%"
              height="167"
              className="c-app-loader-full-page__hero"
              data-testid="content-loader-hero"
            >
              <rect
                width="100%"
                height="167"
                className="c-app-loader-full-page__hero"
                data-testid="content-loader-rect-hero"
              />
            </ContentLoader>
            <ul className="c-app-loader-full-page__stepper-list u-py-space-4">
              <li className="u-mr-space-4">
                <ContentLoader
                  width="116"
                  height="63"
                  data-testid="content-loader-stepper-profiling"
                >
                  <rect
                    width="116"
                    height="63"
                    data-testid="content-loader-rect-stepper-profiling"
                  />
                </ContentLoader>
              </li>
              <li className="u-mr-space-4">
                <ContentLoader
                  width="124"
                  height="63"
                  data-testid="content-loader-stepper-searching"
                >
                  <rect
                    width="124"
                    height="63"
                    data-testid="content-loader-rect-stepper-searching"
                  />
                </ContentLoader>
              </li>
              <li className="u-mr-space-4">
                <ContentLoader
                  width="145"
                  height="63"
                  data-testid="content-loader-stepper-showcase"
                >
                  <rect
                    width="145"
                    height="63"
                    data-testid="content-loader-rect-stepper-showcase"
                  />
                </ContentLoader>
              </li>
              <li className="u-mr-space-4">
                <ContentLoader
                  width="129"
                  height="63"
                  data-testid="content-loader-stepper-interviewing"
                >
                  <rect
                    width="129"
                    height="63"
                    data-testid="content-loader-rect-stepper-interviewing"
                  />
                </ContentLoader>
              </li>
              <li className="u-mr-space-4">
                <ContentLoader
                  width="135"
                  height="63"
                  data-testid="content-loader-stepper-closing"
                >
                  <rect
                    width="135"
                    height="63"
                    data-testid="content-loader-rect-stepper-closing"
                  />
                </ContentLoader>
              </li>
            </ul>
          </div>
          <div className="col col--x-padded small-12 large-3">
            <ContentLoader
              width="100%"
              height="234"
              className="c-app-loader-full-page__helper"
              data-testid="content-loader-helper"
            >
              <rect
                width="100%"
                height="234"
                className="c-app-loader-full-page__helper"
                data-testid="content-loader-rect-helper"
              />
            </ContentLoader>
          </div>
        </div>
      </div>
    </>
  );
};
