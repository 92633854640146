import { Parser } from 'html-to-react';
import RowDescriptionCell from '../fragments/row-description-cell';
import Photo from '../fragments/photo';
import { TableRowsNames } from '../constants/table-rows';
import SimpleText from '../fragments/simple-text';
import MotivationToChange from '../fragments/motivation';
import Summary from '../fragments/summary';
import Salary from '../fragments/salary';
import Location from '../fragments/location';
import Skills from '../fragments/skills';
import Benefits from '../fragments/benefits';
import { SkillTypes } from '../../../../utils/hooks/application/use-skills';
import { ShowcaseOtherSelections } from '../ShowcaseOtherSelections';
import { formatLinebreak } from '../../../../utils/functions';

/**
 * Compose the Showcase table's rows.
 *
 * @param tableRow
 * @param applications
 * @returns Object
 */
const tableDataFactory = (tableRow, applications) => {
  const htmlParser = new Parser();

  return applications.reduce(
    (result, application) => {
      switch (tableRow.id) {
        case TableRowsNames.PHOTO:
          return { ...result, [application.id]: <Photo {...application} /> };

        case TableRowsNames.CURRENT_SALARY:
          return {
            ...result,
            [application.id]: (
              <Salary salary={application.candidateCurrentAnnualSalary} />
            ),
          };

        case TableRowsNames.SALARY:
          return {
            ...result,
            [application.id]: (
              <Salary salary={application.candidateDesiredAnnualSalary} />
            ),
          };

        case TableRowsNames.AGE:
          return {
            ...result,
            [application.id]: <SimpleText text={application.candidateAge} />,
          };

        case TableRowsNames.SENIORITY:
          return {
            ...result,
            [application.id]: (
              <SimpleText text={application.candidateSeniority} />
            ),
          };

        case TableRowsNames.COMPANY:
          return {
            ...result,
            [application.id]: (
              <SimpleText text={application.candidateCurrentCompanyName} />
            ),
          };

        case TableRowsNames.OTHER_SELECTIONS:
          return {
            ...result,
            [application.id]: (
              <ShowcaseOtherSelections
                isCandidateActivelySearching={
                  application.isCandidateActivelySearching
                }
                candidateCurrentSelectionProcessesCount={
                  application.candidateCurrentSelectionProcessesCount
                }
                candidateCurrentSelectionProcessesNotes={
                  application.candidateCurrentSelectionProcessesNotes
                }
              />
            ),
          };

        case TableRowsNames.MOTIVATION:
          return {
            ...result,
            [application.id]: (
              <MotivationToChange
                candidateMotivationToChange={
                  application.candidateMotivationToChange
                }
              />
            ),
          };

        case TableRowsNames.SUMMARY:
          return {
            ...result,
            [application.id]: (
              <Summary
                summary={htmlParser.parse(formatLinebreak(application.summary))}
                isReadMoreEnabled={
                  application.summary && application.summary.length > 200
                }
              />
            ),
          };

        case TableRowsNames.LOCATION:
          return { ...result, [application.id]: <Location {...application} /> };

        case TableRowsNames.MUST_HAVE:
          return {
            ...result,
            [application.id]: (
              <Skills {...application} filterType={SkillTypes.MUST_HAVE} />
            ),
          };

        case TableRowsNames.NICE_TO_HAVE:
          return {
            ...result,
            [application.id]: (
              <Skills {...application} filterType={SkillTypes.NICE_TO_HAVE} />
            ),
          };

        case TableRowsNames.BENEFITS:
          return { ...result, [application.id]: <Benefits {...application} /> };

        default:
          return {
            ...result,
            [application.id]: (
              <td className="c-app-showcase__cell">
                <p>in progress</p>
              </td>
            ),
          };
      }
    },
    {
      'initial-column': (
        <RowDescriptionCell
          name={tableRow.id}
          hasVisibilityAction={tableRow.hasVisibilityAction}
        />
      ),
    },
  );
};

export default tableDataFactory;
