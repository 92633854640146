import type { TJwt } from '../models/Session';

export const setJwt = ({ token, refreshToken }: TJwt): void => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

export const removeJwt = (): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};
