import { action, thunk } from 'easy-peasy';
import jwtDecode from 'jwt-decode';
import { API_ENDPOINT, ENDPOINTS } from '../utils/endpoints';
import { I18N_DEFAULT_LOCALE } from '../constants/locales';
import { SESSION_ERROR_TYPES } from '../constants/session';
import { utilityGetErrorInstance } from '../utils/errors';
import { removeJwt, setJwt } from '../utils/session';

const session = {
  loading: false,
  error: false,

  authenticate: thunk(
    async (actions, _, { getStoreState, getStoreActions }) => {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      const { user } = getStoreState();
      const { setAuthenticationError } = getStoreActions();

      if (checkIsTokenValid({ token, refreshToken })) {
        if (!!user.type) {
          return;
        }

        return await actions.authenticateWithTokens();
      }

      actions.setLoading(true);

      try {
        if (!!refreshToken) {
          return await actions.fetchRefreshToken(refreshToken);
        }

        return await actions.fetchJwt();
      } catch (error) {
        setAuthenticationError(SESSION_ERROR_TYPES.UNAUTHORIZED);
        setInitialized(true);
        return Promise.reject();
      } finally {
        actions.setLoading(false);
      }
    },
  ),

  authenticateWithTokens: thunk(
    async (actions, _, { getState, getStoreState, getStoreActions }) => {
      const {
        user: { fetchUser },
      } = getStoreActions();

      await fetchUser();
    },
  ),

  fetchRefreshToken: thunk(async (actions, refreshToken) => {
    try {
      const response = await fetch(
        `${API_ENDPOINT}${ENDPOINTS.token_refresh}`,
        {
          method: 'POST',
          body: JSON.stringify({ refreshToken }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      await actions.handleJwtResponse(response);
    } catch (err) {
      removeJwt();
      await actions.fetchJwt();
    }
  }),

  fetchJwt: thunk(async actions => {
    try {
      const response = await fetch(`${API_ENDPOINT}${ENDPOINTS.jwt}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      await actions.handleJwtResponse(response);
    } catch (error) {
      await Promise.reject(
        utilityGetErrorInstance(
          error,
          'Session model failed while fetching JWT.',
        ),
      );
    }
  }),

  authFetch: thunk(
    async (actions, { endpoint, requestOptions, skipAuthChecks = false }) => {
      try {
        await actions.authenticate();
      } catch (_error) {
        return Promise.reject();
      }

      const token = localStorage.getItem('token');

      const response = await fetch(`${API_ENDPOINT}${endpoint}`, {
        ...requestOptions,
        headers: {
          ...requestOptions?.headers,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      return await actions.handleResponse({ response, skipAuthChecks });
    },
  ),

  authFetchPdf: thunk(async (actions, payload) => {
    const { endpoint, requestOptions } = payload || {};

    try {
      await actions.authenticate();
      const token = localStorage.getItem('token');

      const response = await fetch(`${API_ENDPOINT}${endpoint}`, {
        ...requestOptions,
        headers: {
          ...requestOptions?.headers,
          'Content-Type': 'application/pdf',
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await response.blob();

      const filename = response.headers.get('reverse-asset-filename');

      return { filename, blob };
    } catch (error) {
      console.warn(error);
      actions.setError(true);
    }
  }),

  handleJwtResponse: thunk(async (actions, response) => {
    const responseData = await actions.handleResponse({ response });

    if (responseData?.token && responseData?.refreshToken) {
      setJwt({
        token: responseData?.token,
        refreshToken: responseData?.refreshToken,
      });
    }
  }),

  handleResponse: thunk(
    async (
      actions,
      { response, skipAuthChecks = false },
      { getStoreActions },
    ) => {
      const AUTH_ERROR_STATES = [401, 403];
      const resolvedResponse = await response.json();
      const storeActions = getStoreActions();

      if (response.ok) {
        return Promise.resolve(resolvedResponse);
      }

      const isAuthError =
        !skipAuthChecks && AUTH_ERROR_STATES.includes(response.status);

      if (!isAuthError) {
        return Promise.reject(
          utilityGetErrorInstance(
            { ...resolvedResponse, status: response.status },
            `Request to ${response.url} returned a ${response.status} status code.`,
          ),
        );
      }

      const error = {
        referents: resolvedResponse?.referents,
        type: SESSION_ERROR_TYPES.UNAUTHORIZED,
        locale: resolvedResponse?.locale || I18N_DEFAULT_LOCALE,
      };

      removeJwt();
      saveCurrentPathInStorage();

      storeActions.setAuthenticationError(SESSION_ERROR_TYPES.UNAUTHORIZED);
      storeActions.setInitialized(true);
      actions.setError(error);
      actions.setLoading(false);

      return Promise.reject(
        utilityGetErrorInstance(
          error,
          `Request to ${response.url} returned a "${SESSION_ERROR_TYPES.UNAUTHORIZED}" error.`,
        ),
      );
    },
  ),

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  setError: action((state, payload) => {
    state.error = payload;
  }),
};

export default session;

export const selectorSession = state => state.session;

const checkIsTokenValid = ({ token, refreshToken }) => {
  if (!token || !refreshToken) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTimestamp = Math.round(new Date().getTime() / 1000);

    return currentTimestamp < decodedToken.exp;
  } catch (err) {
    return false;
  }
};

const saveCurrentPathInStorage = () => {
  const currentPath = window.location.pathname;

  if (currentPath.match(/login/)) {
    return;
  }

  localStorage.setItem('EmployerView::authRedirectPath', currentPath);
};
