import { useStoreState } from 'easy-peasy';
import {
  selectorApplications,
  selectorSortedApplications,
} from '../../model/Applications';
import Horsejog from '../Horsejog';
import React from 'react';

export const InterviewingHorsejogs = () => {
  const applications = useStoreState(selectorSortedApplications);

  return (
    <>
      {applications.map((application, applicationIdx) => (
        <Horsejog
          key={`${application.candidateLastName}-${applicationIdx}`}
          id={`horsejog-${application.id}-${applicationIdx}`}
          horse={application}
        />
      ))}
    </>
  );
};
