import React from 'react';
import { Parser } from 'html-to-react';
import Manager from './Manager';
import { Bubble } from '@reverse-hr/pattern-library';

const Helper = ({ modifier, text, manager }) => {
  const htmlParser = new Parser();

  return manager ? (
    <div className={`c-app-helper ${modifier}`}>
      <div className="c-app-helper__user">
        <Manager manager={manager} />
      </div>
      <div className="c-app-helper__message">
        <Bubble children={htmlParser.parse(text)} angleBorder="" />
      </div>
    </div>
  ) : null;
};

Helper.defaultProps = {
  modifier: '',
};

export default Helper;
