import i18next from 'i18next';
import { differenceInCalendarDays } from 'date-fns';

const baseUrlIt = 'https://reverse.hr/it/';
const baseUrlEn = 'https://reverse.hr/en/';
const baseUrlFr = 'https://reverse.fr/fr/';
const baseUrlDe = 'https://reverse.de/de/';
const baseUrlEs = 'https://reverse.hr/es/';

export const header = () => ({
  welcome: i18next.t('header.welcome'),
  share: i18next.t('header.share'),
});

export const profile = () => ({
  header: {
    title: i18next.t('profile.header.title'),
    body: i18next.t('profile.header.subtitle'),
  },
  cards: {
    action: {
      label: i18next.t('profile.cards.action.label'),
    },
    items: [
      {
        title: i18next.t('profile.cards.1.title'),
        image: 'https://via.placeholder.com/64',
      },
      {
        title: i18next.t('profile.cards.2.title'),
        image: 'https://via.placeholder.com/64',
      },
    ],
  },
  contract: {
    title: i18next.t('profile.contract.title'),
    buttonProps: {
      label: i18next.t('profile.contract.download'),
    },
    image: 'https://via.placeholder.com/216x216',
  },
  referenceHeader: {
    title: i18next.t('profile.references.title'),
  },
  blankReferentCard: {
    title: i18next.t('profile.references.blank.title'),
    text: i18next.t('profile.references.blank.text'),
  },
  mirror: {
    body: i18next.t('profile.mirror.body'),
    linkLabel: i18next.t('profile.mirror.link'),
  },
});

export const hero = (status, payload = null) => ({
  steps: isMiroUrl => [
    {
      name: 'profiling',
      title: isMiroUrl
        ? i18next.t('hero.steps.profiling.title')
        : i18next.t('hero.steps.profiling.title_no_miro'),
      description: i18next.t('hero.steps.profiling.description'),
      icon: 'icn-user-24',
    },
    {
      name: 'searching',
      title: i18next.t('hero.steps.searching.title'),
      description: i18next.t('hero.steps.searching.description'),
      icon: 'icn-search-24',
    },
    {
      name: 'showcase',
      title: i18next.t('hero.steps.showcase.title'),
      description: i18next.t('hero.steps.showcase.description'),
      icon: 'icn-id-card-24',
    },
    {
      name: 'interviewing',
      title: i18next.t('hero.steps.interviewing.title'),
      description: i18next.t('hero.steps.interviewing.description'),
      icon: 'icn-chat-24',
    },
    {
      name: 'closing',
      title: i18next.t('hero.steps.closing.title'),
      description: i18next.t('hero.steps.closing.description'),
      icon: 'icn-flag-outline-24',
    },
  ],
  helperText: {
    profiling: i18next.t(`hero.helper.profiling.${status}`, payload),
    searching: i18next.t(`hero.helper.searching.${status}`, payload),
    showcase: i18next.t('showcase.bubbleText'),
    interviewing: i18next.t(`hero.helper.interviewing.${status}`, payload),
    closing: i18next.t(`hero.helper.closing.${status}`, payload),
  },
});

export const interviewing = () => ({
  header: status => ({
    title: i18next.t('interviewing.header.title'),
    body:
      status === 'to-do'
        ? i18next.t('interviewing.header.subtitle')
        : i18next.t('interviewing.header.subtitle_bis'),
  }),
  referenceHeader: {
    title: i18next.t('interviewing.referenceHeader.title'),
  },
  alert: {
    text: i18next.t('interviewing.alert.text'),
  },
  tabs: {
    all: i18next.t('interviewing.tabs.all'),
    active: i18next.t('interviewing.tabs.active'),
    discarded: i18next.t('interviewing.tabs.discarded'),
  },
  tabsTitle: i18next.t('interviewing.tabs.see'),
});

export const closing = () => ({
  header: {
    title: i18next.t('closing.header.title'),
    body: i18next.t('closing.header.subtitle'),
  },
  bingoHeader: {
    label: i18next.t('closing.bingoHeader.label'),
    sublabel: i18next.t('closing.bingoHeader.sublabel'),
  },
  links: {
    resignation_letter: i18next.t('closing.links.resignation_letter'),
    commitment_letter: i18next.t('closing.links.commitment_letter'),
    prospect: i18next.t('closing.links.prospect'),
  },
  tag: infos => ({
    text: i18next.t('closing.tag.text', { ...infos }),
  }),
});

export const candidateDetail = () => ({
  hero: {
    title: i18next.t('candidate.hero.title'),
    back_label: i18next.t('candidate.hero.back_label'),
  },
  sections: {
    horse: {
      title: i18next.t('candidate.sections.horse.title'),
    },
    bet: name => ({
      open_chat: i18next.t('candidate.sections.bet.open_chat', { name }),
    }),
    chat: (name, userFirstName) => ({
      hour: i18next.t('candidate.sections.chat.hour'),
      feedback_title: i18next.t('candidate.sections.chat.feedback_title'),
      name_title: i18next.t('candidate.sections.chat.name_title', { name }),
      open: i18next.t('candidate.sections.chat.open'),
      close: i18next.t('candidate.sections.chat.close'),
      fullscreen: i18next.t('candidate.sections.chat.fullscreen'),
      default_message: i18next.t('candidate.sections.chat.default_message', {
        name: userFirstName,
        context: userFirstName !== null && 'withName',
      }),
      default_message_positive: i18next.t(
        'candidate.sections.chat.default_message_positive',
        {
          name: userFirstName,
          context: userFirstName !== null && 'withName',
        },
      ),
      default_message_negative: i18next.t(
        'candidate.sections.chat.default_message_negative',
        {
          name: userFirstName,
          context: userFirstName !== null && 'withName',
        },
      ),
    }),
    curriculum: {
      title: i18next.t('candidate.sections.curriculum.title'),
      screening: i18next.t('candidate.sections.curriculum.screening'),
      button: i18next.t('candidate.sections.curriculum.button'),
    },
  },
});

export const error = () => ({
  title: i18next.t('general.error.title'),
  subtitle: i18next.t('general.error.subtitle'),
});

export const authenticationError = errorType => ({
  title: i18next.t(`general.error.${errorType}.title`),
  subtitle: i18next.t(`general.error.${errorType}.subtitle`),
  disclaimer: i18next.t(`general.error.${errorType}.disclaimer`),
});

export const footer = locale => {
  const leftBlocks = [
    `<p class="c-footer__paragraph">© ${
      locale !== 'de' ? new Date().getFullYear() : ''
    } ${i18next.t('footer.company')}</p>`,
    `<p class="c-footer__paragraph">${i18next.t('footer.headquarter')}</p>`,
  ];
  // Sorry, è brutto ma funziona
  const rightBlocks = i18next
    .t('footer.infos')
    .split(';')
    .map(str => str.replace('↵', '').trim());

  let baseUrl = baseUrlIt;
  switch (locale) {
    case 'en':
      baseUrl = baseUrlEn;
      break;
    case 'de':
      baseUrl = baseUrlDe;
      break;
    case 'fr':
      baseUrl = baseUrlFr;
      break;
    case 'es':
      baseUrl = baseUrlEs;
      break;
  }

  const defaultMenu = [
    {
      external: true,
      target: '_blank',
      label: i18next.t('footer.menu.contact.label'),
      url: `${baseUrl}${i18next.t('footer.menu.contact.slug')}`,
    },
    {
      external: true,
      target: '_blank',
      label: i18next.t('footer.menu.legal.label'),
      url: `${baseUrl}${i18next.t('footer.menu.legal.url')}`,
    },
    {
      external: true,
      target: '_blank',
      label: i18next.t('footer.menu.cookies.label'),
      url: i18next.t('footer.menu.cookies.slug'),
    },
  ];
  const primaryMenu =
    locale === 'de'
      ? [
          ...defaultMenu,
          {
            external: true,
            target: '_blank',
            label: i18next.t('footer.menu.impressum.label'),
            url: `${baseUrl}${i18next.t('footer.menu.impressum.slug')}`,
          },
        ]
      : defaultMenu;

  return {
    leftBlocks,
    rightBlocks,
    primaryMenu,
  };
};
export const shareLinkModal = () => ({
  title: i18next.t('modal.share.title'),
  button: i18next.t('modal.share.button'),
  shared_with: i18next.t('modal.share.shared_with'),
  alert: {
    positive: i18next.t('modal.share.alert.positive'),
    negative: i18next.t('modal.share.alert.negative'),
  },
  inputs: [
    {
      id: 'firstName',
      key: `firstName_0`,
      required: true,
      type: 'input',
      label: i18next.t('modal.share.inputs.first_name.label'),
    },
    {
      id: 'lastName',
      key: `lastName_0`,
      required: true,
      type: 'input',
      label: i18next.t('modal.share.inputs.last_name.label'),
    },
    {
      id: 'email',
      key: `email_0`,
      required: true,
      type: 'input',
      label: i18next.t('modal.share.inputs.email.label'),
    },
    {
      id: 'language',
      key: `language_0`,
      type: 'select',
      label: i18next.t('modal.share.inputs.lang.label'),
      options: [
        {
          label: i18next.t('modal.share.lang_options.it'),
          value: 'it',
          selected: true,
        },
        {
          label: i18next.t('modal.share.lang_options.en'),
          value: 'en',
        },
        {
          label: i18next.t('modal.share.lang_options.de'),
          value: 'de',
        },
        {
          label: i18next.t('modal.share.lang_options.fr'),
          value: 'fr',
        },
        {
          label: i18next.t('modal.share.lang_options.es'),
          value: 'es',
        },
      ],
    },
  ],
});
