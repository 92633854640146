import React, { useState, useRef, useEffect } from 'react';
import { Parser } from 'html-to-react';
import { formatLinebreak } from '../utils/functions';
import SummaryDetails from './candidate/SummaryDetails';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import useDebounce from '../utils/hooks/use-debounce';

const Curriculum = ({ modifier, summary, curriculum, details }) => {
  const [pdfWidth, setPdfWidth] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const debouncedWindowWidth = useDebounce(windowWidth, 300);
  const pdfContainerRef = useRef();
  const htmlParser = new Parser();
  const { t } = useTranslation();

  const updateWindowWidth = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    setPdfWidth(pdfContainerRef.current?.offsetWidth);
  }, [pdfContainerRef, debouncedWindowWidth]);

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <div className={`c-app-curriculum ${modifier}`}>
      <div className="c-app-curriculum__recap">
        <div className="c-app-curriculum__screening">
          <div className="c-app-curriculum__screening-title">
            {t('candidateDetails.dossier.subtitle')}
          </div>

          <div className="c-app-curriculum__screening-text">
            {htmlParser.parse(formatLinebreak(summary))}
          </div>

          <div className="c-app-curriculum_screening-card">
            <SummaryDetails {...details} />
          </div>
        </div>
      </div>
      <div className="c-app-curriculum__file" ref={pdfContainerRef}>
        <Document file={curriculum}>
          <Page width={pdfWidth} pageNumber={1} />
        </Document>
      </div>
    </div>
  );
};

Curriculum.defaultProps = {
  modifier: '',
};

export default Curriculum;
