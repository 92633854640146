export const CLOSING_ES = {
  header: {
    title: 'Cierre',
    subtitle:
      '¡Muy buen trabajo! Aquí encontrarás un resumen de costes y de la documentación necesaria.',
  },
  bingoHeader: {
    label: 'Has contratado a',
    sublabel: '',
  },
  links: {
    resignation_letter: 'La carta de renuncia',
    commitment_letter: 'La carta de compromiso de contratación',
    prospect: 'Ver cálculo',
  },
  tag: {
    text: 'Contratación {{hiring_date}}',
  },
};
