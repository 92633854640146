import { useEffect, useState } from 'react';
import {
  CANDIDATE_EVENT_STATES,
  CANDIDATE_EVENT_SUBSTATES,
} from '../../constants/candidate';

const useParsedCandidateDetails = candidate => {
  const [candidateFullName, setCandidateFullName] = useState('');
  const [candidateProfileInfo, setCandidateProfileInfo] = useState({});
  const [candidateHorse, setCandidateHorse] = useState({});
  const [candidateSummaryDetails, setCandidateSummaryDetails] = useState({});
  const [customerInterest, setCustomerInterest] = useState(null);
  const [isCandidateInterviewed, setIsCandidateInterviewed] = useState(false);

  useEffect(() => {
    setCandidateFullName(
      `${candidate.candidateFirstName} ${candidate.candidateLastName}`,
    );

    setCandidateProfileInfo({
      candidateId: candidate.candidateId,
      avatar: candidate.candidateAvatar,
      firstName: candidate.candidateFirstName,
      lastName: candidate.candidateLastName,
      email: candidate.candidateEmail,
      phone: candidate.candidatePhoneNumber,
      linkedin: candidate.candidateLinkedinProfileUrl,
      skype: candidate.candidateSkypeContact,
      concerns: candidate.concerns,
    });

    setCandidateHorse({
      candidateFirstName: candidate.candidateFirstName,
      candidateLastName: candidate.candidateLastName,
      isDiscarded: candidate.isDiscarded,
      applicationId: candidate.id,
      events: candidate.events,
    });

    setCandidateSummaryDetails({
      age: candidate.candidateAge,
      seniority: candidate.candidateSeniority,
      location: candidate.candidateAddress,
      distance: candidate.candidateDistance,
      drivingDistanceInSeconds: candidate.candidateDrivingDistanceInSeconds,
      motivationToChange: candidate.candidateMotivationToChange,
      isActivelySearching: candidate.isCandidateActivelySearching,
      currentSelectionProcessesCount:
        candidate.candidateCurrentSelectionProcessesCount,
      currentSelectionProcessesNotes:
        candidate.candidateCurrentSelectionProcessesNotes,
      currentCompanyName: candidate.candidateCurrentCompanyName,
      currentAnnualSalary: candidate.candidateCurrentAnnualSalary,
      desiredAnnualSalary: candidate.candidateDesiredAnnualSalary,
      currentBenefits: candidate.candidateCurrentBenefits,
    });

    setCustomerInterest(candidate.isCustomerInterested);

    if (candidate.events && candidate.events.length > 0) {
      setIsCandidateInterviewed(
        candidate.events.some(
          event =>
            event.status === CANDIDATE_EVENT_STATES.INTERVIEWING &&
            event.substatus === CANDIDATE_EVENT_SUBSTATES.DONE,
        ),
      );
    }
  }, [candidate]);

  return {
    candidateFullName,
    candidateProfileInfo,
    candidateHorse,
    candidateSummaryDetails,
    customerInterest,
    isCandidateInterviewed,
  };
};

export default useParsedCandidateDetails;
