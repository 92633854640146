import { AppIntroFlow } from '../AppIntroFlow';
import { Trans, useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { PageStateContext } from '../Providers/PageStateProvider';
import { DEAL_PAGE_STATES } from '../../constants/deal';
import { selectorApplications } from '../../model/Applications';
import { useStoreState } from 'easy-peasy';
import { Alert } from '@reverse-hr/pattern-library';
import classNames from 'classnames';

export const InterviewingHeader = () => {
  const { t } = useTranslation();
  const applications = useStoreState(selectorApplications);
  const pageState = useContext(PageStateContext);

  const isPageStateDoing = pageState === DEAL_PAGE_STATES.DOING;

  const isAlertMessageVisible = checkAlertMessageVisibility({
    applications,
    isPageStateDoing,
  });

  const isChatImgVisible = pageState === DEAL_PAGE_STATES.DONE;

  const wrapperClassNames = classNames('col col--x-padded small-12', {
    'large-6': !isPageStateDoing,
    'large-9': isPageStateDoing,
  });

  const introFlowAdditionalClassNames = classNames({
    'c-app-intro-flow--full': isPageStateDoing,
  });

  return (
    <div className={wrapperClassNames}>
      <AppIntroFlow
        title={t('interviewing.header.title')}
        body={t('interviewing.header.subtitle', { context: pageState })}
        className={introFlowAdditionalClassNames}
      />

      {isAlertMessageVisible && (
        <div className="u-mt-space-48">
          <Alert type="warning" icon="icn-warning-outline-24">
            <Trans
              i18nKey="interviewing.header.alert"
              components={{ strong: <strong /> }}
            />
          </Alert>
        </div>
      )}

      {isChatImgVisible && (
        <img
          data-testid="interviewing-page-img"
          className="u-mt-space-32"
          width="256"
          src="/employer_view/assets/images/chat.png"
          alt={t('interviewing.header.title')}
        />
      )}
    </div>
  );
};

const checkAlertMessageVisibility = ({ applications, isPageStateDoing }) => {
  if (!isPageStateDoing) {
    return false;
  }

  return !!applications.find(application => {
    if (application.isDiscarded) {
      return false;
    }

    return !!application.events.find(event => {
      return event.status === 'closing' && event.substatus === 'offered';
    });
  });
};
