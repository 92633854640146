export const ERRORS_EN = {
  employer_view: {
    eligible_application: {
      property: {
        customer_interest_notes: {
          too_long:
            'The motivation you entered is too long, please enter a shorter text.',
        },
      },
    },
  },
};
