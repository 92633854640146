import React from 'react';
import classNames from 'classnames';

const SummaryCard = ({ modifier, title, children }) => {
  const cardClassNames = classNames({
    'c-card c-card--ordinary': true,
    [`c-app-card-summary--${modifier}`]: modifier,
  });

  return (
    <div className="c-app-card-summary">
      <div className={cardClassNames}>
        <div className="c-card__section c-card__head c-card__section--order-1">
          <div className="titles-texts">
            <p className="c-card__title">{title}</p>
          </div>
        </div>

        <div className="c-card__section c-card__body c-card__section--order-2">
          {children}
        </div>
      </div>
    </div>
  );
};

SummaryCard.defaultProps = {
  modifier: '',
};

export default SummaryCard;
