export const CANDIDATE_DETAILS_EN = {
  feedback: {
    bubble:
      'What do you think of {{ candidateFullName }}? <strong>Are they interesting?</strong>',
    bubble_interested:
      '<strong>{{ candidateFullName }}</strong> is interesting',
    bubble_notInterested:
      '<strong>{{ candidateFullName }}</strong> is not interesting',
    bubble_discarded:
      '<strong>{{ candidateFullName }}</strong> does not proceed.',
    actionYes: 'Yes',
    actionNo: 'No',
    actionChangeFeedback: 'Have you changed your mind?',
    interesting: 'Interesting',
  },
  intro: {
    editLink: 'Edit application',
  },
  dossier: {
    title: 'Curriculum vitae',
    subtitle: 'Job interview',
    button: 'Download the dossier',
  },
  scout: {
    accordionOpen: 'Open',
    accordionClose: 'Close',
    title: 'Report scout',
    accordionTitle_skillsAssessment: 'Skills evaluation',
    accordionTitle_overallAssessment: 'Role summary',
    button: 'Download the report',
  },
  negativeFeedback: {
    title: 'Why are they not interesting?',
    subtitle:
      'Knowing why you do not consider this candidate suitable for the position (e.g., skills, seniority, etc.) helps us present only candidates perfectly in line with your expectations.',
    notesLabel: 'Write the motivation:',
    notesPlaceholder:
      'The motivation will be sent to the headhunter handling the selection',
    submit: 'Send',
    cancel: 'Cancel',
    successTitle: 'Message sent:',
    successSubtitle: 'We have sent your feedback to the headhunter',
    successClose: 'Ok, thank you',
  },
};
