import { Card } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { selectorCustomer } from '../../model/Customer';

export const AppCardTask = ({ task }) => {
  const { t } = useTranslation();
  const { companyName } = useStoreState(selectorCustomer);

  const taskContext = task.description ?? task.type;

  return (
    <div className="c-app-card-task">
      <Card modifier="c-card--ordinary">
        {{
          head: {
            avatar: task.headHunter.avatar,
            title: t('common.formatting.fullName', task.headHunter),
            subtitle: t('searching.tasks.card.date', {
              date: new Date(task.dateTime),
            }),
          },
          body: (
            <p className="c-card__body-text typo-text-body typo-lh-title">
              {t('searching.tasks.card.taskDescription', {
                ...task.data,
                companyName,
                context: taskContext,
                count: task.count,
              })}
            </p>
          ),
        }}
      </Card>
    </div>
  );
};
