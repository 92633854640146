import { JobCard } from '../JobCard';
import { useTranslation } from 'react-i18next';
import { HomepageContext } from '../Providers/HomepageProvider';
import { useContext } from 'react';
import { AppIntroFlow } from '../AppIntroFlow';

export const JobCardList = ({ isClosedJobList }) => {
  const { t } = useTranslation();
  const { customerJobs } = useContext(HomepageContext);

  const i18nContext = isClosedJobList ? 'closed' : 'open';
  const jobs = getFilteredJobs({ customerJobs, isClosedJobList });

  return jobs.length > 0 ? (
    <div className="c-job-card-list">
      <AppIntroFlow
        className="c-app-intro-flow--full"
        title={t('homepage.jobList.title', { context: i18nContext })}
      />

      <div className="c-job-card-list__list">
        {jobs.map(job => (
          <JobCard key={job.id} job={job} />
        ))}
      </div>
    </div>
  ) : null;
};

const getFilteredJobs = ({ customerJobs, isClosedJobList }) =>
  customerJobs.filter(
    job =>
      (job.closedOn && isClosedJobList) || (!job.closedOn && !isClosedJobList),
  );
