import { ProgressBar } from '@reverse-hr/pattern-library';
import useSkills from '../../../../../utils/hooks/application/use-skills';

const Skills = ({ candidateSkills, filterType }) => {
  const result = useSkills(candidateSkills);

  return (
    <td className="c-app-showcase__cell">
      {result[filterType].map((skill, idx) => (
        <ProgressBar
          key={`${skill.jobRequirement.id}_${idx}`}
          label={skill.jobRequirement.description}
          value={skill.evaluation}
          className="u-mb-space-16"
        />
      ))}
    </td>
  );
};

export default Skills;
