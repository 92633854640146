const SHOWCASE_DE = {
  title: 'Showcase',
  subtitle:
    'Auf dieser Seite finden Sie einen Vergleich der Kandidaten nach den wichtigsten Anforderungen',
  mobileView:
    'Wir haben diese Erfahrung auf dem Desktop optimiert, um die Daten und Kandidatenbewertungen besser nutzbar zu machen.',
  lastViewUpdate:
    'Gesehen und gespeichert am {{ date, datetime(format: dd/MM/yyyy) }} um {{ date, datetime(format: HH:mm) }} Uhr',
  yes: 'Ja',
  no: 'Nein',
  bubbleText:
    'Auf dieser Seite können Sie die Eigenschaften der vorgestellten Profile vergleichen. Ein weiteres Mittel, um Ihnen die Wahl des richtigen Profils zu erleichtern.',
  emptyCandidateList: 'Hier werden Sie die Liste mit den Bewerbern finden.',
  tableRows: {
    photo: 'Foto',
    age: 'Alter',
    seniority: 'Seniorität',
    location: 'Standort',
    must_have: 'Must have',
    nice_to_have: 'Nice to have',
    current_salary: 'Aktuelles Gehalt',
    salary: 'Gewünschtes Gehalt',
    company: 'Unternehmen',
    other_selections: 'Andere<1></1>Auswahlverfahre',
    motivation: 'Motivation',
    summary: 'Zusammenfassung',
    benefits: 'Benefits',
  },
  filters: {
    restoreView: 'Ansicht wiederherstellen',
    interestedBut: '<1>Interessiert, aber</1> anzeigen',
    out: '<1>Eliminierte Kandidaten</1> anzeigen',
  },
  benefitsCategories: {
    car: 'Dienstwagen',
    canteen: 'Kantine',
    training: 'Weiterbildung',
    welfare: 'Welfare',
    meal_vouchers: 'Essenscoupons',
    insurance: 'Sozialversicherung',
    smartphone: 'Smartphone',
    remote_working: 'Smartworking',
    personal_computer: 'PC',
  },
  chip: {
    interestedBut: 'Interested but',
    out: 'Out',
  },
  location: {
    locationAndDistance: '<strong>{{ location }}</strong>',
    locationAndDistance_withDistance:
      '<strong>{{ location }}</strong> ({{ distance }}km)',
  },
  summary: {
    readmore: 'Mehr erfahren',
    hide: 'Verbergen',
  },
  concernsCategories: {
    brand: 'Brand',
    role: 'Rolle',
    compensation: 'Gehalt',
    location: 'Standort',
  },
  motivationToChangeValues: {
    low: 'Low',
    high: 'High',
    medium: 'Medium',
  },
  drivingTime: {
    hours: '<b>{{ hours }}</b> St.',
    minutes: '<b>{{ minutes }}</b> Min.',
  },
  otherSelections: {
    currentSelectionsCount_one: '{{ count }} Auswahlverfahren',
    currentSelectionsCount_other: '{{ count }} Auswahlverfahren',
  },
};

export default SHOWCASE_DE;
