import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Action, Icon, LinkWrapper } from '@reverse-hr/pattern-library';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { showcaseTableSelector } from '../../../../../../store/selectors/showcase-table';
import { selectorUser } from '../../../../../../model/User';
import { USER_TYPES_MAP } from '../../../../../../constants/session';
import { Link } from 'react-router-dom';
import { selectorDeal } from '../../../../../../model/Deal';
import { REACT_APP_API_HOST_PROD } from '../../../../../../utils/endpoints';

const TableHeader = ({ headerGroups, columns }) => {
  const { columns: storeColumns } = useStoreState(showcaseTableSelector);
  const { type: userType } = useStoreState(selectorUser);
  const deal = useStoreState(selectorDeal);

  const { setColumnsOrder, hideColumn } = useStoreActions(
    showcaseTableSelector,
  );

  const isUserManager = userType === USER_TYPES_MAP.MANAGER;

  const handleDragEnd = event => {
    const startPosition = event.source.index;
    const landingPosition = event?.destination?.index;
    if (!startPosition || !landingPosition) return;

    setColumnsOrder({
      startPositionId: columns[startPosition]?.id,
      landingPositionId: columns[landingPosition]?.id,
    });
  };

  return (
    <thead>
      {headerGroups.map((headerGroup, headerGroupIndex) => (
        <DragDropContext onDragEnd={handleDragEnd} key={headerGroupIndex}>
          <Droppable droppableId="droppable" direction="horizontal">
            {droppableProvided => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{}}
                ref={droppableProvided.innerRef}
                className="c-app-showcase__row c-app-showcase__row--first"
              >
                {headerGroup.headers.map((column, index) =>
                  column.id === 'initial-column' ? (
                    <th
                      {...column.getHeaderProps()}
                      style={{}}
                      className="c-app-showcase__label c-app-showcase__label--first"
                      key={column.id}
                    ></th>
                  ) : (
                    <Draggable
                      key={column.id}
                      draggableId={column.id}
                      index={index}
                      isDragDisabled={
                        !column.accessor || column.id === 'initial-column'
                      }
                    >
                      {provided => {
                        let className = 'c-app-showcase__cell';

                        const storeColumn = storeColumns?.find(
                          storeColumn => storeColumn.id === column.id,
                        );

                        const editLinkURL = `${REACT_APP_API_HOST_PROD}/dashboard/recruitment/jobs/${deal.jobId}/candidates/${storeColumn.candidateId}/edit`;

                        if (storeColumn?.isInterestedBut) {
                          className += ' c-app-showcase__cell--interested-but';
                        }

                        if (storeColumn?.isDiscarded) {
                          className += ' c-app-showcase__cell--out';
                        }

                        return (
                          <th
                            {...column.getHeaderProps()}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={className}
                            ref={provided.innerRef}
                            style={{ ...provided.draggableProps.style }}
                            data-testid="showcase-page-application"
                          >
                            <div className="o-antipodes">
                              <div className="o-antipodes__item c-app-showcase__drag">
                                <Icon name="icn-grid-vertical-24" size={20} />
                              </div>

                              {isUserManager && (
                                <div className="o-antipodes__item">
                                  <Link
                                    to={editLinkURL}
                                    url={editLinkURL}
                                    component={LinkWrapper}
                                    type="raw"
                                    shadow={false}
                                    icon="icn-edit-24"
                                    iconOnly
                                    external
                                    modifier="c-app-showcase__edit"
                                    target="_blank"
                                  />
                                </div>
                              )}

                              <div className="o-antipodes__item">
                                <div className="c-app-showcase__toggles">
                                  <Action
                                    onClick={() => {
                                      hideColumn(column.id);
                                    }}
                                    type="raw"
                                    modifier="c-action--slender c-action--transparent u-color-grey-60"
                                    label={
                                      <Icon name="icn-hide-24" size={20} />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </th>
                        );
                      }}
                    </Draggable>
                  ),
                )}
                {droppableProvided.placeholder}
              </tr>
            )}
          </Droppable>
        </DragDropContext>
      ))}
    </thead>
  );
};

export default TableHeader;
