import React from 'react';
import { Readmore } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';

const Summary = props => {
  const { summary, isReadMoreEnabled } = props;
  const { t } = useTranslation();

  const labels = {
    close: t('showcase.summary.hide'),
    open: t('showcase.summary.readmore'),
  };

  const renderSummary = () =>
    isReadMoreEnabled ? (
      <Readmore text={summary} labels={labels} />
    ) : (
      <div className="c-readmore__abstract">{summary}</div>
    );

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--top-aligned">
      {renderSummary()}
    </td>
  );
};

export default Summary;
