import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import MainLayout from '../../layouts/MainLayout';
import Closing from '../../pages/Closing';
import Interviewing from '../../pages/Interviewing';
import Profiling from '../../pages/Profiling';
import Searching from '../../pages/Searching';

import ProfileLoader from '../../loaders/Profile';
import SearchLoader from '../../loaders/Search';
import InterviewLoader from '../../loaders/Interview';
import ClosingLoader from '../../loaders/Closing';
import Showcase from '../../pages/stepper/showcase';
import { selectorDeal } from '../../model/Deal';
import { DEAL_PROGRESS_STATES } from '../../constants/deal';
import { utilityGetPageState } from '../../utils/get-page-state';
import { selectorRootState } from '../../model';

const StepperSwitch = () => {
  const [currentPage, setCurrentPage] = useState(null);

  // STATE
  const initialized = useStoreState(state => state.initialized);
  const deal = useStoreState(selectorDeal);

  // ACTIONS
  const { setCurrentRoute, setCurrentStatus } = useStoreActions(
    actions => actions,
  );

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (initialized) {
      const cleanedPathname = location.pathname.split('/').filter(p => !!p);

      const pageName = [
        'profiling',
        'searching',
        'interviewing',
        'closing',
        'success_fee',
        'candidate_detail',
        'showcase',
      ].includes(cleanedPathname[1])
        ? cleanedPathname[1]
        : cleanedPathname[2];

      const current = currentPage || pageName;

      if (current) {
        setCurrentStatus(
          utilityGetPageState({
            dealProgress: deal.progress,
            currentRoute: pageName,
          }),
        );

        setCurrentPage(pageName);
        setCurrentRoute(pageName);
      }
    }
  }, [initialized, location, history]);

  return (
    <MainLayout>
      <Switch>
        <Route path="/:id/profiling">
          {initialized ? (
            <Profiling />
          ) : (
            <div className="container">
              <div className="row">
                <div className="col col--x-padded">
                  <ProfileLoader />
                </div>
              </div>
            </div>
          )}
        </Route>
        <Route path="/:id/searching">
          {initialized ? (
            <Searching />
          ) : (
            <div className="container">
              <div className="row">
                <div className="col col--x-padded">
                  <SearchLoader
                    status={
                      ['interviewing', 'closing'].includes(deal.progress)
                        ? 'done'
                        : 'doing'
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Route>
        <Route path="/:id/interviewing">
          {initialized ? (
            <Interviewing />
          ) : (
            <div className="container">
              <div className="row">
                <div className="col col--x-padded">
                  <InterviewLoader
                    status={
                      ['closing'].includes(deal.progress) ? 'done' : 'doing'
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Route>

        {deal.isShowcaseEnabled && (
          <Route path="/:id/showcase">
            <Showcase />
          </Route>
        )}

        <Route path="/:id/closing">
          {initialized ? (
            <Closing />
          ) : (
            <div className="container">
              <div className="row">
                <div className="col col--x-padded">
                  <ClosingLoader />
                </div>
              </div>
            </div>
          )}
        </Route>

        <Route path="/:id/">
          <StepperSwitchNotFound />
        </Route>

        <Route path="/:id/*">
          <StepperSwitchNotFound />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default StepperSwitch;

const StepperSwitchNotFound = () => {
  const { id: dealId } = useParams();
  const history = useHistory();
  const { initialized: isInitialized } = useStoreState(selectorRootState);
  const { progress } = useStoreState(selectorDeal);

  const redirectPageSegment = progress ?? DEAL_PROGRESS_STATES.PROFILING;

  useEffect(() => {
    history.push(`/${dealId}/${redirectPageSegment}`);
  }, [isInitialized]);

  return null;
};
