import Sentry from '../../sentry';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { I18N_LOCALE_LIST } from '../../constants/locales';
import { useHomepageRouteMatch } from './use-homepage-route-match';
import { selectorRootActions } from '../../model';
import { ROUTES } from '../../constants/navigation';

const useInitializeApp = () => {
  const history = useHistory();
  const location = useLocation();
  const initialized = useStoreState(state => state.initialized);
  const initialize = useStoreActions(state => state.initialize);
  const { changeLanguage } = useStoreActions(selectorRootActions);
  const { isHomepage } = useHomepageRouteMatch();

  const isLoginPage = useRouteMatch({
    path: [ROUTES.LOGIN, ROUTES.LOGIN_VERIFICATION_CODE],
    exact: true,
  });

  const queryParams = new URL(window.location).searchParams;

  const getDealInfoFromUrl = () => {
    const [dealId] = location.pathname
      .split('/')
      .filter(urlSegment => !!urlSegment);

    const isDealIdValid = !isHomepage && !isLoginPage;
    const language = queryParams.get('language') || null;
    const isShareModalOpen = queryParams.get('share') || false;

    return {
      dealId: isDealIdValid ? dealId : null,
      language,
      isShareModalOpen,
    };
  };

  const setSentryContext = () => {
    const cookies = document.cookie;
    const isEnvDevelopment = process.env.NODE_ENV === 'development';
    const hasSessionCookie = cookies.includes('PHPSESSID');

    if (isEnvDevelopment || hasSessionCookie) return;

    Sentry.setContext('user', {
      info: 'User is not logged, it could crash on some authenticated routes',
    });
  };

  const setLanguageFromSearch = language => {
    if (!language) {
      return;
    }

    queryParams.delete('language');
    history.replace({ ...location, search: queryParams.toString() });

    if (I18N_LOCALE_LIST.includes(language)) {
      changeLanguage(language);
    }
  };

  const resetShareParam = isShareModalOpen => {
    if (!isShareModalOpen) {
      return;
    }

    queryParams.delete('share');
    history.replace({ ...location, search: queryParams.toString() });
  };

  const executeInitializationSteps = async () => {
    const { dealId, language, isShareModalOpen } = getDealInfoFromUrl();

    setLanguageFromSearch(language);
    resetShareParam(isShareModalOpen);
    setSentryContext();

    initialize({ dealId, isShareModalOpen });
  };

  useEffect(() => {
    if (!initialized) {
      executeInitializationSteps();
    }
  }, [initialize, initialized, location]);
};

export default useInitializeApp;
