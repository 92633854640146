export const HOMEPAGE_ES = {
  header: {
    navigation: {
      jobs: 'Tus mandatos',
      candidates: 'Tus candidatos',
    },
  },
  jobList: {
    title_open: 'Mandatos en curso',
    title_closed: 'Mandatos concluidos',
    cardState_searching: '$t(hero.steps.searching.title)',
    cardState_interviewing: '$t(hero.steps.interviewing.title)',
    cardState_closing: '$t(hero.steps.closing.title)',
    cardState_closed: 'Cerrado el $t(common.formatting.date.dateFormat)',
  },
  candidates: {
    filterByJob: 'Filtrar por mandato',
    allJobs: 'Todos los mandatos',
    allStates: 'Todos los candidatos en espera',
    title_active: 'Candidatos activos',
    intro_active:
      "Aquí puedes encontrar a todos los candidatos activos en tus selecciones abiertas.<br/>La columna '$t(homepage.candidates.table.header.state)' indica lo que falta de su parte para avanzar en el proceso de selección, mientras que '$t(homepage.candidates.table.header.lastUpdate_active)' indica cuánto tiempo ha estado esperando el candidato. Cuanto más aumenten los días de espera, mayor será la probabilidad de perder al candidato.<br/><br/>Si deseas más información, puedes verificar el perfil de un candidato haciendo clic en su nombre o el progreso de una selección individual haciendo clic en una posición.",
    title_hired: 'Candidatos contratados',
    intro_hired:
      'Aquí puedes ver todos los candidatos que han sido contratados',
    title_stopped: 'Candidatos excluidos',
    intro_stopped:
      "Aquí puedes encontrar a todos los candidatos rechazados o retirados. Puedes verificar en qué fase del proceso se detuvieron en la columna '$t(homepage.candidates.table.header.state_stopped)', junto con la fecha de la última actualización y el número de entrevistas realizadas con cada uno.",
    table: {
      header: {
        candidateFirstName: 'Candidato',
        jobTitle: 'Mandato',
        state: 'Estado',
        state_active: 'En espera de',
        state_stopped: 'Fase de selección',
        lastUpdate: 'Última actualización',
        lastUpdate_active: 'Días de espera',
        latestInterviewAt: 'Entrevista más reciente',
        numberOfClientInterviews: 'Entrevistas realizadas',
        hireDate: 'Fecha de contratación',
        missedReason: 'Motivo',
      },
      states: {
        presented: 'Presentación del candidato',
        positive_curriculum_feedback:
          'Feedback positivo después de la presentación',
        interview_scheduled: 'Entrevista de conocimiento programada',
        interview_done: 'Entrevista de conocimiento realizada',
        interview_interested:
          'Feedback positivo después de la entrevista de conocimiento',
        interview_meet_again: 'Entrevistas en profundidad',
        offer_incoming: 'A la espera de una oferta',
        offer_made: 'Oferta presentada',
        presented_active: 'Recibir feedback sobre el currículum',
        positive_curriculum_feedback_active: 'Programar la primera entrevista',
        interview_scheduled_active: 'Realizar la entrevista',
        interview_done_active: 'Recibir feedback después de la entrevista',
        interview_interested_active: 'Avanzar después de feedback positivo',
        interview_meet_again_active: 'Concluir la fase de entrevistas',
        offer_incoming_active: 'Recibir una oferta de su parte',
        offer_made_active: 'Concluir la negociación',
      },
      missedReason_rejected: 'Rechazado',
      missedReason_withdrawn: 'Retirado',
      missedReason_offer_rejected: 'Oferta rechazada',
      noApplications: 'No se encontraron candidatos.',
      noApplications_error:
        'Algo salió mal, intenta volver a cargar la página.',
    },
  },
};
