import { selectorDeal } from '../../model/Deal';
import { useStoreState } from 'easy-peasy';
import { AppIntroFlow } from '../AppIntroFlow';
import { useTranslation } from 'react-i18next';
import ReferentCardsWrapper from '../ReferentCardsWrapper';

export const Referents = () => {
  const { t } = useTranslation();
  const { contactPersons } = useStoreState(selectorDeal);

  return contactPersons ? (
    <>
      <AppIntroFlow title={t('profile.references.title')} />

      <div className="o-cards-scrollable">
        <ReferentCardsWrapper referents={contactPersons} />
      </div>
    </>
  ) : null;
};
