import { AppCardTask } from '../AppCardTask';

export const AppTasksMasonry = ({ activities }) => {
  const columns = getGroupedByColumnsTasks(activities);

  return (
    <div className="c-app-tasks c-app-tasks--masonry">
      <div className="o-masonry">
        {columns.map((column, columnIdx) => (
          <div
            className={`c-app-tasks__col o-masonry__col o-masonry__col--${
              columnIdx + 1
            }`}
            key={columnIdx}
          >
            {column.map((task, taskIdx) => (
              <div className="c-app-tasks__item o-masonry__item" key={taskIdx}>
                <AppCardTask task={task} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const getGroupedByColumnsTasks = tasks =>
  tasks.reduce(
    (columns, task, taskIdx) => {
      const columnIdx = taskIdx % 4;

      columns[columnIdx].push(task);

      return columns;
    },
    [[], [], [], []],
  );
