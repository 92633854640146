import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export const AppNumbersCard = ({ titleKey, contents }) => {
  const { t } = useTranslation();

  const hasOpaqueStyle = ![
    'profilesCount',
    'profilesWithJobInterviewReportCount',
  ].includes(titleKey);

  const valueClassNames = classNames('c-app-numbers__value', {
    'c-app-numbers__value--opaque': hasOpaqueStyle,
  });

  return (
    <div className="c-app-numbers">
      <p className="c-app-numbers__title">
        {t('searching.numbers.title', { context: titleKey })}
      </p>

      <div className="c-app-numbers__items">
        {contents.map(content => (
          <div key={content.descriptionKey} className="c-app-numbers__item">
            <p className={valueClassNames}>{content.value}</p>

            <p className="c-app-numbers__description">
              {t('searching.numbers.description', {
                context: content.descriptionKey,
              })}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
