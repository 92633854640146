import { useEffect, useState } from 'react';
import {
  CUSTOMER_APPLICATION_STATES_LIST,
  CUSTOMER_APPLICATIONS_ORDER,
  CUSTOMER_APPLICATIONS_TABLE_COLUMN_TO_SORT_BY_KEYS,
} from '../../constants/applications';
import { useStoreActions } from 'easy-peasy';
import { selectorCustomerActions } from '../../model/Customer';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../utils/hooks/use-debounce';
import { useParams } from 'react-router-dom';

export const useApplications = ({ stateCategory, jobId }) => {
  const { t } = useTranslation();
  const [applications, setApplications] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [sortDirection, setSortDirection] = useState(
    CUSTOMER_APPLICATIONS_ORDER.ASC,
  );

  const [sortBy, setSortBy] = useState(undefined);
  const [state, setState] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { customerId } = useParams();

  const debouncedState = useDebounce(
    JSON.stringify({
      currentPage,
      sortBy,
      sortDirection,
      state,
      jobId,
    }),
    500,
  );

  const { thunkFetchCustomerApplications } = useStoreActions(
    selectorCustomerActions,
  );

  const stateSelectOptions = [
    {
      value: undefined,
      label: t('homepage.candidates.allStates'),
    },
    ...CUSTOMER_APPLICATION_STATES_LIST.map(applicationState => ({
      value: applicationState,
      label: t(`homepage.candidates.table.states.${applicationState}`, {
        context: 'active',
      }),
    })),
  ];

  const onPageChange = page => setCurrentPage(page);

  const onColumnHeaderClick = columnHeader => {
    if (columnHeader === sortBy) {
      setSortDirection(
        sortDirection === CUSTOMER_APPLICATIONS_ORDER.ASC
          ? CUSTOMER_APPLICATIONS_ORDER.DESC
          : CUSTOMER_APPLICATIONS_ORDER.ASC,
      );
    } else {
      setSortDirection(CUSTOMER_APPLICATIONS_ORDER.ASC);
    }

    setSortBy(columnHeader);
  };

  const onStateChange = ({ value }) => setState(value);

  const fetchApplications = async () => {
    setHasError(false);
    setIsLoading(true);

    try {
      const { pagination, results } = await thunkFetchCustomerApplications({
        customerId,
        sortBy: CUSTOMER_APPLICATIONS_TABLE_COLUMN_TO_SORT_BY_KEYS[sortBy],
        stateCategory,
        jobId,
        currentPage,
        sortDirection,
        state,
      });

      setPagination({
        current_page: pagination.currentPage,
        total_pages: pagination.totalPages,
      });

      setApplications(results);
    } catch (error) {
      setHasError(true);
      setApplications([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, [debouncedState]);

  useEffect(() => {
    setCurrentPage(1);
  }, [state, jobId]);

  return {
    applications,
    pagination,
    stateSelectOptions,
    sortBy,
    sortDirection,
    isLoading,
    hasError,
    onPageChange,
    onColumnHeaderClick,
    onStateChange,
  };
};
