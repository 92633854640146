import React from 'react';
import ContentLoader from 'react-content-loader';

const Interview = ({ status }) => {
  return status === 'done' ? (
    <ContentLoader viewBox="0 0 500 600">
      <rect x="0" y="10" width="80" height="12" />
      <rect x="0" y="30" width="220" height="8" />
      <rect x="0" y="42" width="200" height="8" />
      <rect x="0" y="54" width="160" height="8" />

      <rect x="10" y="80" rx="40" ry="80" width="80" height="90" />

      <rect x="250" y="10" width="110" height="80" />
      <rect x="250" y="100" width="50" height="12" />
      <rect x="305" y="100" width="40" height="12" />
      <rect x="250" y="115" width="20" height="6" />

      <rect x="250" y="135" width="100" height="8" />
      <rect x="250" y="150" width="100" height="8" />

      <rect x="380" y="10" width="110" height="80" />
      <rect x="380" y="100" width="50" height="12" />
      <rect x="435" y="100" width="40" height="12" />
      <rect x="380" y="115" width="20" height="6" />

      <rect x="380" y="135" width="100" height="8" />
      <rect x="380" y="150" width="100" height="8" />

      <rect x="0" y="180" width="100" height="40" />
      <rect x="110" y="190" rx="22" ry="22" width="22" height="22" />
      <rect x="450" y="190" rx="22" ry="22" width="22" height="22" />
      <rect x="140" y="193" rx="8" width="60" height="15" />
      <rect x="220" y="193" rx="8" width="60" height="15" />
      <rect x="300" y="193" rx="8" width="60" height="15" />
      <rect x="380" y="193" rx="8" width="60" height="15" />

      <rect x="0" y="222" width="100" height="40" />
      <rect x="110" y="234" rx="22" ry="22" width="22" height="22" />
      <rect x="450" y="234" rx="22" ry="22" width="22" height="22" />
      <rect x="140" y="237" rx="8" width="60" height="15" />
      <rect x="220" y="237" rx="8" width="60" height="15" />
      <rect x="300" y="237" rx="8" width="60" height="15" />
      <rect x="380" y="237" rx="8" width="60" height="15" />

      <rect x="0" y="264" width="100" height="40" />
      <rect x="110" y="276" rx="22" ry="22" width="22" height="22" />
      <rect x="450" y="276" rx="22" ry="22" width="22" height="22" />
      <rect x="140" y="279" rx="8" width="60" height="15" />
      <rect x="220" y="279" rx="8" width="60" height="15" />
      <rect x="300" y="279" rx="8" width="60" height="15" />
      <rect x="380" y="279" rx="8" width="60" height="15" />
    </ContentLoader>
  ) : status === 'doing' ? (
    <ContentLoader viewBox="0 0 500 600">
      <rect x="0" y="10" width="80" height="12" />
      <rect x="0" y="30" width="220" height="8" />
      <rect x="0" y="42" width="200" height="8" />
      <rect x="0" y="54" width="160" height="8" />

      <rect x="340" y="10" rx="40" ry="80" width="80" height="90" />

      <rect x="0" y="120" width="100" height="40" />
      <rect x="110" y="130" rx="22" ry="22" width="22" height="22" />
      <rect x="450" y="130" rx="22" ry="22" width="22" height="22" />
      <rect x="140" y="133" rx="8" width="60" height="15" />
      <rect x="220" y="133" rx="8" width="60" height="15" />
      <rect x="300" y="133" rx="8" width="60" height="15" />
      <rect x="380" y="133" rx="8" width="60" height="15" />

      <rect x="0" y="162" width="100" height="40" />
      <rect x="110" y="174" rx="22" ry="22" width="22" height="22" />
      <rect x="450" y="174" rx="22" ry="22" width="22" height="22" />
      <rect x="140" y="177" rx="8" width="60" height="15" />
      <rect x="220" y="177" rx="8" width="60" height="15" />
      <rect x="300" y="177" rx="8" width="60" height="15" />
      <rect x="380" y="177" rx="8" width="60" height="15" />

      <rect x="0" y="204" width="100" height="40" />
      <rect x="110" y="216" rx="22" ry="22" width="22" height="22" />
      <rect x="450" y="216" rx="22" ry="22" width="22" height="22" />
      <rect x="140" y="219" rx="8" width="60" height="15" />
      <rect x="220" y="219" rx="8" width="60" height="15" />
      <rect x="300" y="219" rx="8" width="60" height="15" />
      <rect x="380" y="219" rx="8" width="60" height="15" />
    </ContentLoader>
  ) : (
    <ContentLoader viewBox="0 0 500 600">
      <rect x="0" y="10" width="80" height="12" />
      <rect x="0" y="30" width="220" height="8" />
      <rect x="0" y="42" width="200" height="8" />
      <rect x="0" y="54" width="160" height="8" />

      <rect x="10" y="80" rx="40" ry="80" width="80" height="90" />
    </ContentLoader>
  );
};

export default Interview;
