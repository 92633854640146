const SHOWCASE_IT = {
  title: 'Showcase',
  subtitle:
    'In questa pagina trovi la comparazione dei candidati in base ai requisiti più importanti',
  mobileView:
    'Abbiamo ottimizzato questa esperienza su <1>Desktop</1>, per rendere più fruibile i dati e le valutazioni sui candidati',
  lastViewUpdate:
    'Vista salvata il {{ date, datetime(format: dd/MM/yyyy) }} ore {{ date, datetime(format: HH:mm) }}',
  yes: 'Sì',
  no: 'No',
  bubbleText:
    'Questa pagina mette a confronto le caratteristiche dei profili che ti sono stati presentati. Uno strumento per aiutarti a scegliere le persone giuste.',
  emptyCandidateList: 'Qui ci sarà la lista dei candidati.',
  tableRows: {
    photo: 'Foto',
    age: 'Età',
    seniority: 'Seniority',
    location: 'Domicilio',
    must_have: 'Must have',
    nice_to_have: 'Nice to have',
    current_salary: 'RAL attuale',
    salary: 'RAL desiderata',
    company: 'Azienda',
    other_selections: 'Altri<1></1>processi<1></1>di selezione',
    motivation: 'Motivazione',
    summary: 'Riassunto',
    benefits: 'Benefits',
  },
  filters: {
    restoreView: 'Ripristina vista',
    interestedBut: 'Visualizza <1>Interested but</1>',
    out: 'Visualizza <1>Candidati out</1>',
  },
  benefitsCategories: {
    car: 'Auto',
    canteen: 'Mensa',
    training: 'Formazione',
    welfare: 'Welfare',
    meal_vouchers: 'Buoni pasto',
    insurance: 'Assicurazione',
    smartphone: 'Telefono',
    remote_working: 'Lavoro da remoto',
    personal_computer: 'Computer',
  },
  concernsCategories: {
    brand: 'Brand',
    role: 'Ruolo',
    compensation: 'Stipendio',
    location: 'Location',
  },
  location: {
    locationAndDistance: '<strong>{{ location }}</strong>',
    locationAndDistance_withDistance:
      '<strong>{{ location }}</strong> ({{ distance }}km)',
  },
  summary: {
    readmore: 'Leggi tutto',
    hide: 'Nascondi',
  },
  chip: {
    interestedBut: 'Interested but',
    out: 'Out',
  },
  motivationToChangeValues: {
    low: 'Low',
    high: 'High',
    medium: 'Medium',
  },
  drivingTime: {
    hours: '<b>{{ hours }}</b> h',
    minutes: '<b>{{ minutes }}</b> min',
  },
  otherSelections: {
    currentSelectionsCount_one: '{{ count }} processo di selezione',
    currentSelectionsCount_other: '{{ count }} processi di selezione',
  },
};

export default SHOWCASE_IT;
