export const CLOSING_IT = {
  header: {
    title: 'Chiusura',
    subtitle:
      'Ottimo lavoro! Qui trovi un riepilogo dei costi e dei documenti necessari.',
  },
  bingoHeader: {
    label: 'Avete assunto',
    sublabel: '',
  },
  links: {
    resignation_letter: 'Lettera di dimissioni',
    commitment_letter: 'Impegnativa assunzione',
    prospect: 'Vedi prospetto',
  },
  tag: {
    text: 'Assunzione il {{hiring_date}}',
  },
};
