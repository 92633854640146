import React from 'react';

const Video = ({ modifier, video, sources, autoPlay, loop, muted }) => {
  return (
    <div className={`c-app-video ${modifier}`}>
      <video
        className="c-app-video__item"
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        title={video}
      >
        {sources
          ? sources.map((src, index) => {
              return (
                <source
                  key={`video-source-${index}`}
                  src={`/employer_view/videos/${video}.${src}`}
                  type={`video/${src}`}
                />
              );
            })
          : null}
      </video>
    </div>
  );
};

Video.defaultProps = {
  modifier: '',
  autoPlay: true,
  loop: true,
  muted: true,
};

export default Video;
