import { I18N_DEFAULT_LOCALE, I18N_LOCALE_LIST } from '../constants/locales';
import i18next from 'i18next';

export const utilityChangeLocale = async (locale?: string): Promise<void> => {
  const browserLocale = navigator.language.split('-')[0];

  const defaultLocale =
    browserLocale && I18N_LOCALE_LIST.includes(browserLocale)
      ? browserLocale
      : I18N_DEFAULT_LOCALE;

  const selectedLocale = locale ?? defaultLocale;

  window.employer_locale = selectedLocale;

  if (selectedLocale === I18N_DEFAULT_LOCALE) {
    return;
  }

  await i18next.changeLanguage(selectedLocale, error => {
    if (!error) {
      return;
    }

    console.warn('Something went wrong loading the locale', error);
  });
};
