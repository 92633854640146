import React from 'react';
import CandidateProfileInfos from './CandidateProfileInfos';
import Feedbacks from './Feedbacks';
import ContentLoader from 'react-content-loader';

const CandidateDetail = () => (
  <div className="o-wrapper o-wrapper--base-width o-wrapper--side-padded u-mt-large">
    <ContentLoader viewBox="0 0 600 150">
      <rect x="0" y="0" width="60" height="8" />
      <rect x="0" y="15" width="240" height="20" />
      <rect x="0" y="80" width="100" height="10" />

      <rect x="520" y="10" rx="15" width="80" height="30" />
      <rect x="544" y="42" width="40" height="6" />
      <rect x="564" y="50" width="20" height="6" />
      <rect x="520" y="70" width="39" height="25" />
      <rect x="560" y="70" width="39" height="25" />

      <rect x="585" y="42" rx="15" width="15" height="15" />

      <rect x="0" y="120" width="100" height="25" />
      <rect x="105" y="120" width="120" height="25" />
    </ContentLoader>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <CandidateProfileInfos />
      <div style={{ marginTop: '-3em', maxWidth: '30vw', minWidth: '30vw' }}>
        <ContentLoader viewBox="0 0 300 50">
          <rect x="0" y="0" width="140" height="18" />
          <rect x="220" y="0" width="80" height="18" />
        </ContentLoader>
        <Feedbacks color="#f5f6f7" />
        <ContentLoader viewBox="0 0 300 40">
          <rect x="0" y="10" width="268" height="30" />
          <rect x="270" y="10" width="30" height="30" />
        </ContentLoader>
      </div>
    </div>
  </div>
);

export default CandidateDetail;
