import { action, thunk } from 'easy-peasy';

export const ModelApplications = {
  thunkFetchApplications: thunk(
    async (actions, dealId, { getStoreActions }) => {
      const {
        session: { authFetch },
      } = getStoreActions();

      try {
        const response = await authFetch({
          endpoint: `/v1/employer-view/deals/${dealId}/eligible-applications`,
        });

        actions.actionSetApplications(response?.eligibleApplications || []);
      } catch (error) {
        if (error?.cause?.status === 404) {
          actions.actionSetApplications([]);
        }
      }
    },
  ),

  actionSetApplications: action((_, applications) => applications),
};

export const selectorApplications = state => state.applications;

export const selectorSortedApplications = state => {
  const applications = [...state.applications];

  applications.sort((a, b) => {
    if (a.isDiscarded === b.isDiscarded) {
      return a.candidateFirstName.localeCompare(b.candidateFirstName);
    }

    return a.isDiscarded ? 1 : -1;
  });

  return applications;
};
