import Video from '../Video';
import { useStoreState } from 'easy-peasy';
import { selectorDeal } from '../../model/Deal';
import PropTypes from 'prop-types';

export const SuccessFeeSidebar = ({ candidateName }) => {
  const deal = useStoreState(selectorDeal);

  return (
    <div className="col col--x-padded small-12 large-4">
      <div className="o-sidebar o-sidebar--y-antipodes">
        <div>
          <p className="typo-text-body u-color-grey-60">{deal.jobTitle}</p>
          <p className="typo-secondary-heading-05 u-mt-space-8 u-mb-space-32">
            {candidateName}
          </p>
        </div>

        <Video
          video="pyramids"
          sources={['webm']}
          modifier="c-video--success-fee u-hidden-medium-down"
        />
      </div>
    </div>
  );
};

SuccessFeeSidebar.propTypes = {
  candidateName: PropTypes.string.isRequired,
};
