import React from 'react';
import { Icon } from '@reverse-hr/pattern-library';

const Scroller = ({ modifier, icon, onScroll }) => {
  return (
    <div className={`c-app-scroller ${modifier}`}>
      <button className="c-app-scroller__button" onClick={onScroll}>
        <span className="c-app-scroller__icon">
          <Icon name={icon} />
        </span>
      </button>
    </div>
  );
};

Scroller.defaultProps = {
  modifier: '',
  icon: 'icn-short-right-24',
  onScroll: () => {},
};

export default Scroller;
