import classNames from 'classnames';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import React from 'react';
import PropTypes from 'prop-types';

export const AppBanner = ({
  isLayoutSkyscraper,
  title,
  body,
  ctaLabel,
  ctaUrl,
  onClick,
}) => {
  const wrapperClassNames = classNames('c-app-banner', {
    'c-app-banner--skyscreaper': isLayoutSkyscraper,
  });

  const mainElementStyles = isLayoutSkyscraper
    ? null
    : {
        backgroundImage:
          'url(/employer_view/assets/images/banner-job-contract.jpg)',
      };

  return (
    <div className={wrapperClassNames}>
      <div className="c-app-banner__decoration">
        <img
          src="/employer_view/assets/images/sign.png"
          className="c-app-banner__image"
          alt=""
        />
      </div>

      <div className="c-app-banner__main" style={mainElementStyles}>
        <p className="c-app-banner__title">{title}</p>

        {!!body && <p className="c-app-banner__text">{body}</p>}

        <div className="c-app-banner__cta">
          <LinkWrapper
            download
            external
            type="simple-text"
            icon="icn-download-24"
            target="_blank"
            rel="noreferrer"
            url={ctaUrl}
            label={ctaLabel}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};

AppBanner.propTypes = {
  title: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  body: PropTypes.string,
  isLayoutSkyscraper: PropTypes.bool,
};
