import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Horserace from '../utils/horserace';
import { Chip, Icon } from '@reverse-hr/pattern-library';

import { formatDate } from '../utils/functions';

const Horse = ({
  application_id,
  deal_id,
  status,
  substatus,
  name,
  dateTime,
  modifier,
  showLinks,
  showAdditionalInfo,
}) => {
  const { t } = useTranslation();
  const horserace = new Horserace();
  const { type, tag, additional_info } = horserace.getHorsecard({
    status: status.includes('interviewing') ? 'interviewing' : status,
    substatus,
    name,
  });
  const candidateLink = `/${deal_id}/candidate_detail/${application_id}`;
  const candidateDetailLink =
    additional_info && additional_info.type === 'action' ? candidateLink : '/';

  const getFormattedDate = () => {
    if (
      status.includes('interviewing') &&
      ['done', 'income', 'outcome'].includes(substatus)
    ) {
      return `${t('general.date_on')} ${formatDate(dateTime, 'dd/MM/yyyy')} ${t(
        'general.date_hour',
      )} ${formatDate(dateTime, 'HH:mm')}`;
    }
    return formatDate(dateTime, 'dd/MM/yyyy');
  };

  return (
    <div
      data-testid="horse-container"
      className={`c-app-horse c-app-horse--${type} ${modifier || ''}`}
    >
      {type !== 'finish' ? (
        <>
          <p data-testid="horse-date" className="c-app-horse__top-text">
            {getFormattedDate()}
          </p>
          {tag && (
            <div data-testid="horse-tag" className="c-horse__chip">
              <Chip {...tag} />
            </div>
          )}
          {additional_info &&
          showAdditionalInfo &&
          !['done', 'interesting'].includes(substatus) ? (
            additional_info.type === 'text' ? (
              <p
                data-testid="horse-additional-text"
                className="c-app-horse__anchor"
              >
                {additional_info.text}
              </p>
            ) : showLinks && additional_info.type === 'link' ? (
              <a
                data-testid="horse-additional-link"
                href={additional_info.url}
                className="c-app-horse__anchor"
              >
                {additional_info.text}
              </a>
            ) : showLinks && additional_info.type === 'action' ? (
              <Link
                data-testid="horse-additional-action-link"
                to={candidateDetailLink}
              >
                <p
                  className="c-app-horse__anchor"
                  data-testid="horse-additional-action-label"
                >
                  {additional_info.text}
                </p>
              </Link>
            ) : null
          ) : null}
        </>
      ) : (
        <div className="c-app-horse__finish-item">
          <Icon size={56} name="icn-trophy-24" />
        </div>
      )}
    </div>
  );
};

Horse.defaultProps = {
  type: null, // 'default', 'winner', 'warning'
  name: '',
  dateTime: new Date(),
  status: '',
  showLinks: true,
};

export default Horse;
