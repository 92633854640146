import { Route, Switch } from 'react-router-dom';
import { SuccessFee } from '../../pages/SuccessFee';
import CandidateSwitch from './CandidateSwitch';
import StepperSwitch from './StepperSwitch';
import { useStoreState } from 'easy-peasy';
import { selectorDeal } from '../../model/Deal';
import { ErrorScreenExpiredLink } from '../ErrorScreen';

export const DealSwitch = () => {
  const deal = useStoreState(selectorDeal);

  if (deal.dealError) {
    return <ErrorScreenExpiredLink />;
  }

  return (
    <Switch>
      <Route path="/:id/success_fee/:applicationId">
        <SuccessFee />
      </Route>
      <Route path="/:id/candidate_detail">
        <CandidateSwitch />
      </Route>
      <Route path="/:id/:segment?">
        <StepperSwitch />
      </Route>
    </Switch>
  );
};
