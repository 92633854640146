import { useStoreState } from 'easy-peasy';
import { selectorDeal } from '../../model/Deal';
import { AppNumbersRow } from './AppNumbersRow';

export const AppNumbers = () => {
  const { jobStatistics, statistics } = useStoreState(selectorDeal);
  const { atLeastContactedProfiles, notAGoodMatchProfiles } = statistics ?? {};
  const profilesCount = atLeastContactedProfiles - notAGoodMatchProfiles;

  const {
    profilesWithJobInterviewReportCount,
    interestedProfilesCount,
    interestedWithConcerns,
  } = jobStatistics;

  const jobStatsRows = [
    {
      profilesCount,
      profilesWithJobInterviewReportCount,
      interestedProfilesCount,
    },
    { interestedWithConcerns },
  ];

  return (
    <>
      {jobStatsRows.map((row, rowIdx) => (
        <AppNumbersRow row={row} key={rowIdx} isFirstRow={!rowIdx} />
      ))}
    </>
  );
};
