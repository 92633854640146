import { useRouteMatch } from 'react-router-dom';

export const useHomepageRouteMatch = () => {
  const isHomepageCandidatesPage = useRouteMatch({
    path: ['/customers/:customerId?/candidates', '/candidates'],
    exact: true,
  });

  const isHomepageJobsPage = useRouteMatch({
    path: ['/', '/customers/:customerId?'],
    exact: true,
  });

  const isHomepage = isHomepageJobsPage || isHomepageCandidatesPage;

  return { isHomepageJobsPage, isHomepageCandidatesPage, isHomepage };
};
