import i18next from 'i18next';

class Horserace {
  constructor() {
    this.horsemap = this.getHorseraceMap();
  }

  getHorseraceMap = () => ({
    eligibles: () => ({
      curriculum_seen: {
        type: 'warning',
        tag: {
          leftIcon: 'icn-show-24',
          children: i18next.t('interviewing.eligibles.curriculum_seen'),
        },
        additional_info: {
          type: 'action',
          text: i18next.t('interviewing.eligibles.curriculum_seen_text'),
        },
      },
      curriculum_not_seen: {
        type: 'warning',
        tag: {
          leftIcon: 'icn-hide-24',
          children: i18next.t('interviewing.eligibles.curriculum_not_seen'),
        },
        additional_info: {
          type: 'action',
          text: i18next.t('interviewing.eligibles.curriculum_not_seen_text'),
        },
      },
      interesting: {
        type: 'positive',
        tag: {
          leftIcon: 'icn-bulb-24',
          children: i18next.t('interviewing.eligibles.interesting'),
        },
      },
      not_interesting: {
        type: 'negative',
        tag: {
          leftIcon: 'icn-minus-circle-24',
          children: i18next.t('interviewing.eligibles.not_interesting'),
        },
      },
      withdrawn: {
        type: 'negative',
        tag: {
          leftIcon: 'icn-user-close-24',
          children: i18next.t('interviewing.eligibles.withdrawn'),
        },
      },
      out: {
        type: 'negative',
        tag: {
          leftIcon: 'icn-sad-24',
          children: i18next.t('interviewing.eligibles.out'),
        },
      },
    }),
    scout: () => ({
      to_be_created: {
        type: 'default',
        tag: {
          leftIcon: 'icn-folder-open-24',
          children: i18next.t('interviewing.scout.to_be_created'),
          type: 'default',
        },
      },
      available: {
        type: 'warning',
        tag: {
          leftIcon: 'icn-file-blank-outline-24',
          children: i18next.t('interviewing.scout.available'),
        },
        additional_info: {
          type: 'action',
          text: i18next.t('interviewing.scout.available_text'),
        },
      },
      done: {
        type: 'default',
        tag: {
          leftIcon: 'icn-file-blank-outline-24',
          children: i18next.t('interviewing.scout.done'),
        },
      },
      withdrawn: {
        type: 'negative',
        tag: {
          leftIcon: 'icn-user-close-24',
          children: i18next.t('interviewing.scout.withdrawn'),
        },
      },
      out: {
        type: 'negative',
        tag: {
          leftIcon: 'icn-sad-24',
          children: i18next.t('interviewing.scout.out'),
        },
      },
    }),
    interviewing: () => ({
      to_be_created: {
        type: 'default',
        tag: {
          leftIcon: 'icn-group-alt-24',
          children: i18next.t('interviewing.interviewing.to_be_created'),
        },
        additional_info: {
          type: 'text',
          text: i18next.t('interviewing.interviewing.to_be_created_text'),
        },
      },
      incoming: {
        type: 'warning',
        tag: {
          leftIcon: 'icn-group-alt-24',
          children: i18next.t('interviewing.interviewing.incoming'),
        },
        additional_info: {
          type: 'action',
          text: i18next.t('interviewing.interviewing.incoming_text'),
        },
      },
      outcome: {
        type: 'warning',
        tag: {
          leftIcon: 'icn-group-alt-24',
          children: i18next.t('interviewing.interviewing.outcome'),
        },
        additional_info: {
          type: 'action',
          text: i18next.t('interviewing.interviewing.outcome_text'),
        },
      },
      done: {
        type: 'default',
        tag: {
          leftIcon: 'icn-group-alt-24',
          children: i18next.t('interviewing.interviewing.done'),
        },
      },
      withdrawn: {
        type: 'warning',
        tag: {
          leftIcon: 'icn-user-close-24',
          children: i18next.t('interviewing.interviewing.withdrawn'),
        },
      },
      out: {
        type: 'warning',
        tag: {
          leftIcon: 'icn-sad-24',
          children: i18next.t('interviewing.interviewing.out'),
        },
      },
    }),
    closing: name => ({
      to_be_created: {
        type: 'default',
        tag: {
          leftIcon: 'icn-money-24',
          children: i18next.t('interviewing.closing.to_be_created'),
        },
        additional_info: {
          type: 'text',
          text: i18next.t('interviewing.closing.to_be_created_text'),
        },
      },
      offered: {
        type: 'default',
        tag: {
          leftIcon: 'icn-handshake-24',
          children: i18next.t('interviewing.closing.offered'),
        },
        additional_info: {
          type: 'text',
          text: i18next.t('interviewing.closing.offered_text', { name }),
        },
      },
      winner: {
        type: 'finish',
      },
      withdrawn: {
        type: 'negative',
        tag: {
          leftIcon: 'icn-user-close-24',
          children: i18next.t('interviewing.closing.withdrawn'),
        },
      },
      out: {
        type: 'negative',
        tag: {
          leftIcon: 'icn-sad-24',
          children: i18next.t('interviewing.closing.out'),
        },
      },
      offer_declined: {
        type: 'negative',
        tag: {
          leftIcon: 'icn-sad-24',
          children: i18next.t('interviewing.closing.offer_declined'),
        },
      },
    }),
  });

  /**
   * state: 'eligibles',
   * substate: 'curriculum_seen',
   */

  /**
   *{
     type: 'default'
   * tag: {
   *   leftIcon: 'icn-eye-24px',
   *   children: 'Curriculum visto',
   *   type: 'warning'
   *  },
   * additional_info: {}
   * }
   */
  getHorsecard = ({ status, substatus, name }) => {
    if (status && substatus) {
      return this.horsemap[status](name)[substatus];
    } else {
      return {
        type: 'default',
        tag: null,
        additional_info: null,
      };
    }
  };
}

export default Horserace;
