import { format, parseISO, isValid } from 'date-fns';
import { it, enGB, de, fr } from 'date-fns/locale';
import Sentry from '../sentry';

export const formatPrice = (integer, currency) => {
  const locale = window.employer_locale || 'it';
  const locales = {
    it: 'it-IT',
    en: 'en-GB',
    de: 'de-DE',
    fr: 'fr-FR',
  };
  return `${integer.toLocaleString(locales[locale], {
    style: 'currency',
    currency,
  })}`;
};

export const formatDate = (date, formatto) => {
  const locales = {
    it: it,
    en: enGB,
    de: de,
    fr: fr,
  };
  let _date = new Date(date);
  if (!isValid(_date)) {
    _date = parseISO(date);
  }
  if (!isValid(_date)) {
    Sentry.captureException(
      new Error(`Date cannot be converted: ${date}, ${window.employer_locale}`),
    );
    return '';
  }
  const locale = locales[window.employer_locale];
  return format(_date, formatto, { locale });
};

export const getInitials = fullName => {
  if (fullName && typeof fullName === 'string') {
    const initials = `${fullName
      .split(' ')
      .map(name => name.charAt(0))
      .join('')
      .toUpperCase()}`;
    if (initials.length > 2) {
      return `${initials.charAt(0)}${initials.charAt(initials.length - 1)}`;
    }
    return initials;
  }
  return '';
};

export const formatLinebreak = text => {
  if (text && typeof text === 'string') {
    return text.replace(/(?:\r\n|\r|\n|\u21b5)/g, '<br>');
  }
  return '';
};

export const swipeDetect = (el, callback) => {
  var touchsurface = document.querySelector(el),
    swipedir,
    startX,
    startY,
    dist,
    distX,
    distY,
    threshold = 150, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 300, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function (swipedir) {};

  touchsurface.addEventListener(
    'touchstart',
    function (e) {
      var touchobj = e.changedTouches[0];
      swipedir = 'none';
      dist = 0;
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      startTime = new Date().getTime(); // record time when finger first makes contact with surface
      e.preventDefault();
    },
    false,
  );

  touchsurface.addEventListener(
    'touchmove',
    function (e) {
      e.preventDefault(); // prevent scrolling when inside DIV
    },
    false,
  );

  touchsurface.addEventListener(
    'touchend',
    function (e) {
      var touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
      elapsedTime = new Date().getTime() - startTime; // get time elapsed
      if (elapsedTime <= allowedTime) {
        // first condition for awipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
          // 2nd condition for horizontal swipe met
          swipedir = distX < 0 ? 'left' : 'right'; // if dist traveled is negative, it indicates left swipe
        } else if (
          Math.abs(distY) >= threshold &&
          Math.abs(distX) <= restraint
        ) {
          // 2nd condition for vertical swipe met
          swipedir = distY < 0 ? 'up' : 'down'; // if dist traveled is negative, it indicates up swipe
        }
      }
      handleswipe(swipedir);
      e.preventDefault();
    },
    false,
  );
};
