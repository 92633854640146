import i18n from 'i18next';
import { format as dateFnsFormat } from 'date-fns';
import { initReactI18next } from 'react-i18next';
import { de, en, es, fr, it } from './lang';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: { it, de, en, fr, es },
    lng: 'it',
    fallbackLng: 'it',
    supportedLngs: ['en', 'it', 'de', 'fr', 'es'],

    interpolation: {
      escapeValue: false,
    },
  });

i18n.services.formatter.add('lowercase', value => value.toLowerCase());

i18n.services.formatter.add('datetime', (value, _, options) =>
  dateFnsFormat(value, options.format),
);

export default i18n;
