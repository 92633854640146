import { action, thunk } from 'easy-peasy';

export const ModelDeal = {
  dealError: null,
  id: null,
  activities: [],
  closedAt: null,
  closings: [],
  contactPersons: [],
  contractUrl: null,
  isShowcaseEnabled: null,
  jobId: null,
  jobStatistics: null,
  jobTitle: null,
  jraDate: null,
  jraUrl: null,
  miroUrl: null,
  progress: null,
  salaryRange: null,
  showcaseConfiguration: null,
  statistics: null,

  fetchDeal: thunk(async (actions, dealId, { getStoreActions }) => {
    const {
      customer: { setCustomer },
      session: { authFetch },
    } = getStoreActions();

    try {
      const response = await authFetch({
        endpoint: `/v1/employer-view/deals/${dealId}`,
      });

      actions.setDeal(response);
      setCustomer(response.customer);
    } catch (error) {
      actions.setDealError({
        status: error.cause.status,
        message: error.message,
      });
      actions.setDeal({});
      actions.setCustomer({});
    }
  }),

  setDeal: action((state, deal) => {
    state.activities = deal.activities;
    state.closedAt = deal.closedAt;
    state.closings = deal.closings;
    state.contractUrl = deal.contractUrl;
    state.isShowcaseEnabled = deal.isShowcaseEnabled;
    state.jobId = deal.jobId;
    state.jobStatistics = deal.jobStatistics;
    state.jobTitle = deal.jobTitle;
    state.jraDate = deal.jraDate;
    state.jraUrl = deal.jraUrl;
    state.miroUrl = deal.miroUrl;
    state.progress = deal.progress;
    state.contactPersons = deal.contactPersons;
    state.salaryRange = deal.salaryRange;
    state.showcaseConfiguration = deal.showcaseConfiguration;
    state.statistics = deal.statistics;
  }),

  setDealError: action((state, error) => {
    state.dealError = error;
  }),

  setDealId: action((state, dealId) => {
    state.id = dealId;
  }),
};

export const selectorDeal = state => state.deal;
