import {
  utilityGetTotal,
  utilityIsMinimalActive,
} from '../../utils/success-fee';
import { AppCount } from '../AppCount';
import { AppSummary } from '../AppSummary';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SUCCESS_FEE_ESTIMATE_COUNTS_MAP } from '../../constants/success-fee';

export const SuccessFeeTotal = ({ prospect }) => {
  const { t, i18n } = useTranslation();

  const { currency, discountPercentage } = prospect;
  const { counts, total } = utilityGetTotal(prospect);

  const isMinimalActive = utilityIsMinimalActive(counts);

  const wrapperClassNames = classNames(
    'c-app-summary--stacked-centered c-app-summary--total u-mt-space-32 c-app-summary--secondary',
    {
      'c-app-summary--with-minimal': isMinimalActive,
    },
  );

  return (
    <AppSummary
      className={wrapperClassNames}
      title={t('successFee.total.title')}
      total={total}
      currency={currency}
    >
      {counts.map((count, countIdx) => (
        <AppCount
          key={count.key}
          className={getCountClassNames(countIdx, count.key)}
          title={t(`successFee.total.${count.key}.title`, {
            discountPercentage,
          })}
          description={
            i18n.exists(`successFee.total.${count.key}.description`) ? (
              <Trans
                i18nKey={`successFee.total.${count.key}.description`}
                components={{ br: <br /> }}
                values={prospect}
              />
            ) : null
          }
          price={count.value}
          currency={currency}
        />
      ))}
    </AppSummary>
  );
};

const getCountClassNames = (countIdx, countKey) =>
  classNames('c-app-count--billed', {
    'c-app-count--neutral-even': !(countIdx % 2),
    'c-app-count--total-colored c-app-count--neutral-odd': countIdx % 2,
    'c-app-count--minimal':
      countKey === SUCCESS_FEE_ESTIMATE_COUNTS_MAP.MINIMUM_SUCCESS_FEE_AMOUNT,
  });

SuccessFeeTotal.propTypes = {
  prospect: PropTypes.object.isRequired,
};
