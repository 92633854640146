import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CandidateDetail from '../../pages/CandidateDetail';

const CandidateSwitch = () => {
  return (
    <Switch>
      <Route path="/:id/candidate_detail/:application_id">
        <CandidateDetail />
      </Route>
      <Route path="/:id/candidate_detail">
        <div>Candidate detail without id</div>
      </Route>
    </Switch>
  );
};

export default CandidateSwitch;
