import React from 'react';
import { Parser } from 'html-to-react';
import { Footer } from '@reverse-hr/pattern-library';
import { footer } from '../utils/default-values';

const TheFooter = () => {
  const htmlParser = new Parser();
  const { leftBlocks, rightBlocks, primaryMenu } = footer(
    window.employer_locale,
  );
  return (
    <div className="c-app-footer">
      <div className="container">
        <div className="row">
          <div className="col col--x-padded">
            <Footer
              modifier="c-footer--top-spaced"
              blocks={{
                left: leftBlocks.map(b => htmlParser.parse(b)),
                right: rightBlocks.map(b => htmlParser.parse(b)),
              }}
              navs={{
                primary: null,
                secondary: primaryMenu,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TheFooter.defaultProps = {};

export default TheFooter;
