const getConcernsCategoriesString = (concerns, t) => {
  if (!concerns) return '';

  const unparsedConcernsCategories = concerns?.map(concern => {
    const categoryKey = concern.split('_')[0];
    return t(`showcase.concernsCategories.${categoryKey}`);
  });

  return [...new Set(unparsedConcernsCategories)].join(', ');
};

export { getConcernsCategoriesString };
