import { Card, LinkWrapper, Pictogram } from '@reverse-hr/pattern-library';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ClosingHiredCard = ({ closing, dealId }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Card modifier="u-card-fixed">
        {{
          head: {
            left: (
              <div className="c-card__titles">
                <div className="titles-texts">
                  <p className="c-card__title">
                    {t('common.formatting.fullName', {
                      firstName: closing.candidateFirstName,
                      lastName: closing.candidateLastName,
                    })}
                  </p>
                </div>
              </div>
            ),
            right: (
              <Link
                to={`/${dealId}/candidate_detail/${closing.id}`}
                component={LinkWrapper}
                type="raw"
                shadow={false}
                icon="icn-id-card-24"
                iconOnly
                external={false}
              />
            ),
          },
          body: (
            <>
              {!!closing.offer.commitmentLetter && (
                <LetterLink
                  url={closing.offer.commitmentLetter.downloadUrl}
                  label={t('closing.links.commitment_letter')}
                />
              )}

              {!!closing.offer.resignationLetter && (
                <LetterLink
                  url={closing.offer.resignationLetter.downloadUrl}
                  label={t('closing.links.resignation_letter')}
                />
              )}
            </>
          ),
          actions: (
            <>
              {!!closing.offer.successFeeRecap && (
                <Link
                  to={`/${dealId}/success_fee/${closing.id}`}
                  component={LinkWrapper}
                  type="outline"
                  label={t('closing.links.prospect')}
                  icon="icn-chevron-big-right-24"
                  iconPosition="right"
                />
              )}
            </>
          ),
        }}
      </Card>
    </div>
  );
};

const LetterLink = ({ url, label }) => (
  <div>
    <LinkWrapper type="unstyled" external url={url} target="_blank">
      <Pictogram icon="icn-download-24" size="small" children={label} />
    </LinkWrapper>
  </div>
);
