import React, { useContext, useState } from 'react';
import { HomepageContext } from '../Providers/HomepageProvider';
import { CustomerApplications } from '../CustomerApplications';
import { CUSTOMER_APPLICATIONS_STATE_CATEGORIES } from '../../constants/applications';
import { Select } from '../Select';
import { useTranslation } from 'react-i18next';

export const HomepageCandidates = () => {
  const { t } = useTranslation();
  const [jobId, setJobId] = useState();
  const { customerJobs } = useContext(HomepageContext);

  const jobsSelectOptions = [
    {
      value: undefined,
      label: t('homepage.candidates.allJobs'),
    },
    ...getJobsSelectOptions(customerJobs),
  ];

  const onJobSelectChange = ({ value }) => setJobId(value);

  return (
    <div className="c-homepage-candidates container">
      <div className="row u-py-space-40">
        <div className="col col--x-padded">
          <div className="container">
            <div className="row">
              <div className="c-homepage-candidates__jobs ">
                <span className="c-homepage-candidates__jobs-label">
                  {t('homepage.candidates.filterByJob')}
                </span>

                <Select
                  className="c-homepage-candidates__jobs-select"
                  icon="icn-brief-bag-24"
                  options={jobsSelectOptions}
                  defaultValue={jobsSelectOptions[0]}
                  onChange={onJobSelectChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <CustomerApplications
                  isStateFilterAvailable
                  jobId={jobId}
                  stateCategory={CUSTOMER_APPLICATIONS_STATE_CATEGORIES.ACTIVE}
                />

                <CustomerApplications
                  jobId={jobId}
                  stateCategory={CUSTOMER_APPLICATIONS_STATE_CATEGORIES.HIRED}
                />

                <CustomerApplications
                  jobId={jobId}
                  stateCategory={CUSTOMER_APPLICATIONS_STATE_CATEGORIES.STOPPED}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const getJobsSelectOptions = customerJobs => {
  customerJobs.sort((a, b) => (a.title > b.title ? 1 : -1));

  return customerJobs.map(job => ({
    value: job.id,
    label: job.title,
  }));
};
