import React from 'react';
import ContentLoader from 'react-content-loader';

const ProfileLoader = () => {
  return (
    <div>
      <ContentLoader viewBox="0 0 500 600">
        <rect x="0" y="10" width="80" height="12" />
        <rect x="0" y="30" width="220" height="8" />
        <rect x="0" y="42" width="200" height="8" />

        <circle cx="20" cy="70" r="5" />
        <rect x="35" y="65" width="90" height="10" />

        <circle cx="20" cy="100" r="5" />
        <rect x="35" y="95" width="110" height="10" />

        <circle cx="20" cy="130" r="5" />
        <rect x="35" y="125" width="100" height="10" />

        <rect x="15" y="160" width="80" height="20" />

        <rect x="250" y="10" width="140" height="12" />

        <rect x="250" y="30" width="120" height="80" />
        <rect x="250" y="120" width="50" height="12" />
        <rect x="305" y="120" width="40" height="12" />
        <rect x="250" y="135" width="20" height="6" />

        <rect x="250" y="155" width="100" height="8" />
        <rect x="250" y="170" width="100" height="8" />

        <rect x="380" y="30" width="120" height="80" />
        <rect x="380" y="120" width="50" height="12" />
        <rect x="435" y="120" width="40" height="12" />
        <rect x="380" y="135" width="20" height="6" />

        <rect x="380" y="155" width="100" height="8" />
        <rect x="380" y="170" width="100" height="8" />

        <rect x="50" y="200" width="400" height="100" />
        <rect x="20" y="220" width="100" height="60" />
      </ContentLoader>
    </div>
  );
};

export default ProfileLoader;
