export const CLOSING_EN = {
  header: {
    title: 'Closing',
    subtitle:
      'Great work! Here you will find a summary of the costs and the necessary documents.',
  },
  bingoHeader: {
    label: 'You hired',
    sublabel: '',
  },
  links: {
    resignation_letter: 'Resignation letter',
    commitment_letter: 'Letter of employment',
    prospect: 'See calculation',
  },
  tag: {
    text: 'Hired on {{hiring_date}}',
  },
};
