import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const AppSummary = ({
  title,
  footerLabel,
  total,
  currency,
  children,
  className,
}) => {
  const { t } = useTranslation();

  const wrapperClassNames = classNames('c-app-summary', className);

  return (
    <div className={wrapperClassNames}>
      <div className="c-app-summary__header">
        <p className="c-app-summary__header-text">{title}</p>
      </div>

      <div className="c-app-summary__body">{children}</div>

      <div className="c-app-summary__footer">
        {!!footerLabel && (
          <p className="c-app-summary__footer-label">{footerLabel}</p>
        )}

        <p className="c-app-summary__footer-value">
          <strong>
            {t('common.formatting.price', { value: total, currency })}
          </strong>
        </p>
      </div>
    </div>
  );
};

AppSummary.propTypes = {
  children: PropTypes.node.isRequired,
  currency: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  total: PropTypes.number.isRequired,
  className: PropTypes.string,
  footerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
