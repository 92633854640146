export const COMMON_FR = {
  formatting: {
    date: {
      daysAgo: 'il y a {{ count }} jours',
      daysAgo_zero: "Aujourd'hui",
      daysAgo_one: 'Hier',

      month_1: 'janvier',
      month_2: 'février',
      month_3: 'mars',
      month_4: 'avril',
      month_5: 'mai',
      month_6: 'juin',
      month_7: 'juillet',
      month_8: 'août',
      month_9: 'septembre',
      month_10: 'octobre',
      month_11: 'novembre',
      month_12: 'décembre',

      dateFormat_pretty:
        '{{- date, datetime(format: dd)}} $t(common.formatting.date.month_{{- date, datetime(format: M) }}) {{- date, datetime(format: yyyy)}}',
    },
  },
};
