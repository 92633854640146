export const SUCCESS_FEE_ES = {
  backCta: 'Volver al mandato',
  moreInfo: 'Más información',
  successFee: {
    title: 'Tarifa a éxito',
    footer: 'Total de la tarifa a éxito',
    successFeeFromSalary: {
      title: '{{ successFeePercentageOfCompensationPackage }}% from Salary',
      description:
        'Package {{ annualSalaryValue, currency }} multiplied the % fee indicated in the contract {{ successFeePercentageOfCompensationPackage }}%. <br/>Calculation: {{ successFeePercentageOfCompensationPackage }}% * {{ annualSalaryValue, currency }}',
    },
    bonusBenefitOffer: {
      title: '{{ bonusBenefit.description }}',
      description:
        'Variable bonus calculated as the percentage {{ bonusBenefit.percentage }}% of the maximum value earned by the candidate on an annual basis. <br/>Calculation: {{ successFeePercentageOfCompensationPackage }}% * {{ bonusBenefit.percentage }}% * {{ bonusBenefit.amount, currency }}',
    },
    bonusBenefitSalary: {
      title: '{{ bonusBenefit.description }}',
      description:
        "Generally indicated variable bonus calculated as the percentage {{ bonusBenefit.percentage }}% of the candidate's annual gross salary. <br/>Calculation: {{ successFeePercentageOfCompensationPackage }}% * {{ bonusBenefit.percentage }}% * {{ annualSalaryValue, currency }}",
    },
    carBenefit: {
      title: 'Company car',
      description:
        'Value of {{ companyCarBenefit.value, currency }} established in the contract for the assignment of the company car measured as {{ successFeePercentageOfCompensationPackage }}%. <br/>Calculation: {{ successFeePercentageOfCompensationPackage }}% * {{ companyCarBenefit.value, currency }}',
    },
    healthInsuranceBenefit: {
      title: 'Health insurance',
      description:
        'Share deriving from health insurance to the extent of {{ healthInsuranceBenefit.percentage }}%. <br/>Calculation: {{ successFeePercentageOfCompensationPackage }}% * {{ healthInsuranceBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    accommodationBenefit: {
      title: 'Months of accommodation',
      description:
        'Share deriving from accommodation benefit to the extent of {{ accommodationBenefit.percentage }}%. <br/>Calculation: {{ successFeePercentageOfCompensationPackage }}% * {{ accommodationBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    stockOptionsBenefit: {
      title: 'Stock options / equity investments',
      description:
        'Share deriving from stock options / company shareholdings to the extent of {{ stocksBenefit.percentage }}%. <br/>Calculation: {{ successFeePercentageOfCompensationPackage }}% * {{ stocksBenefit.percentage }}% * {{ annualSalaryValue, currency }}',
    },
    overallFee: {
      title: 'Overall fee',
    },
    minimumSuccessFeeAmount: {
      title: 'Minimum amount',
      description:
        'In compliance with the signed contract the value of the total fee is {{ minimumAmount, currency }}  (minimum value)',
    },
  },
  prePayments: {
    title: 'Anticipos ya facturados',
    footer: 'Total anticipos',
    retainerFee: {
      title: 'Retainer fee',
    },
    confidenceFee: {
      title: 'Confidence fee',
    },
  },
  total: {
    title: 'Total',
    successFee: {
      title: 'Success fee',
    },
    prePayments: {
      title: 'Paid deposit',
    },
    discount: {
      title: 'Agreed discount ({{ discountPercentage }}%)',
    },
    minimumSuccessFeeAmount: {
      title: '$t(successFee.successFee.minimumSuccessFeeAmount.title)',
      description:
        '$t(successFee.successFee.minimumSuccessFeeAmount.description)',
    },
  },
};
