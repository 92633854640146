import { useMemo } from 'react';
import Table from './fragments/table';
import Header from './fragments/header';
import useShowcase from './hooks/showcase';

const Showcase = () => {
  const { filteredColumns, filteredRows, isRestoreViewButtonVisible } =
    useShowcase();

  const isValidCandidateList =
    filteredColumns?.length > 0 && filteredRows?.length > 0;

  const isLayoutFull = useMemo(
    () => filteredColumns?.length > 5,
    [filteredColumns?.length],
  );

  const renderTableShadow = () =>
    isLayoutFull && <div className="c-app-showcase__shadow" />;

  return (
    <div data-testid="showcase-page">
      <div className="container">
        <div className="row">
          <Header
            isRestoreViewButtonVisible={isRestoreViewButtonVisible}
            isValidCandidateList={isValidCandidateList}
          />
        </div>
      </div>
      {isValidCandidateList && (
        <div
          className={`container${isLayoutFull ? ' container--full' : ''}`}
          data-testid="showcase-table-container"
        >
          <div className="row">
            <div className="col small-12 u-hidden-large-down">
              <div className="c-app-showcase">
                {renderTableShadow()}
                <Table
                  filteredColumns={filteredColumns}
                  filteredRows={filteredRows}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Showcase;
