export const INTERVIEWING_ES = {
  header: {
    title: 'Entrevistas',
    subtitle:
      'Aquí encontrarás la lista de candidatos. Siempre podrás visualizar en qué fase se encuentran y compartir tus comentarios para que avancen en el proceso de selección.',
    subtitle_bis:
      'Aquí puedes ver la lista de candidatos. Puedes comprobar en qué fase se encuentran y compartir tus comentarios para que avancen a la siguiente fase.',
    alert:
      'Hay personas en la fase final. Para que no abandonen el proceso, <strong>resuelve las dudas con premura.</strong>',
  },
  referenceHeader: {
    title: 'Tus personas de contacto',
  },
  tabs: {
    see: '',
    all: '',
    active: '',
    discarded: '',
  },
  eligibles: {
    curriculum_seen: 'Curriculum visto',
    curriculum_seen_text: 'Dejar un comentario',
    curriculum_not_seen: 'Currículum no visto',
    curriculum_not_seen_text: 'Leer y dejar un comentario',
    interesting: 'Interesante',
    not_interesting: 'No interesante',
    withdrawn: 'Se retira',
    out: 'No sigue adelante',
  },
  scout: {
    to_be_created: 'A la espera del informe',
    available: 'Informe publicado',
    available_text: 'Leer informe',
    done: 'Informe publicado',
    withdrawn: 'Se retira',
    out: 'No sigue adelante',
  },
  interviewing: {
    to_be_created: 'Por entrevistar',
    to_be_created_text: 'En fase de organización...',
    incoming: 'Por entrevistar',
    incoming_text: 'Descargar dossier de la entrevista',
    outcome: 'Entrevista realizada',
    outcome_text: 'Dejar un comentario',
    done: 'Entrevista realizada',
    withdrawn: 'Se retira',
    out: 'No sigue adelante',
  },
  closing: {
    to_be_created: 'Hacer una oferta',
    to_be_created_text: '¡No permitas que abandone el proceso!',
    offered: 'Oferta realizada',
    offered_text: 'A la espera de {{ name }}',
    winner: '¡Winner!',
    withdrawn: 'Se retira',
    out: 'No sigue adelante',
    offer_declined: 'Oferta rechazada',
  },
};
