export const HOMEPAGE_IT = {
  header: {
    navigation: {
      jobs: 'I tuoi mandati',
      candidates: 'I tuoi candidati',
    },
  },
  jobList: {
    title_open: 'Mandati in corso',
    title_closed: 'Mandati conclusi',
    cardState_searching: '$t(hero.steps.searching.title)',
    cardState_interviewing: '$t(hero.steps.interviewing.title)',
    cardState_closing: '$t(hero.steps.closing.title)',
    cardState_closed: 'Concluso il $t(common.formatting.date.dateFormat)',
  },
  candidates: {
    filterByJob: 'Filtra per mandato',
    allJobs: 'Tutti i mandati',
    allStates: 'Tutti i candidati in attesa',
    title_active: 'Candidati in corsa',
    intro_active:
      'Qui puoi trovare tutti i candidati attivi nelle tue selezioni aperte.<br/>La colonna "$t(homepage.candidates.table.header.state)" indica cosa manca al candidato da parte vostra per procedere nel processo di selezione, mentre "$t(homepage.candidates.table.header.lastUpdate_active)" indica da quanto tempo il candidato attende.<br/>Più i giorni di attesa aumentano, più aumenta la probabilità di perdere il candidato.<br/><br/>Se desideri maggiori informazioni, puoi controllare il profilo di un candidato cliccando sul suo nome o l\'avanzamento di una singola selezione cliccando su una posizione.',
    title_hired: 'Candidati assunti',
    intro_hired: 'Qui puoi trovare tutti i candidati che sono stati assunti.',
    title_stopped: 'Candidati esclusi',
    intro_stopped:
      'Qui trovi tutti i candidati respinti o ritirati.<br/>Puoi verificare in quale fase del processo si sono fermati nella colonna "$t(homepage.candidates.table.header.state_stopped)", insieme alla data dell\'ultimo aggiornamento e il numero di colloqui svolti con ciascuno.',
    table: {
      header: {
        candidateFirstName: 'Candidato',
        jobTitle: 'Mandato',
        state: 'Stato',
        state_active: 'In attesa di',
        state_stopped: 'Fase di selezione',
        lastUpdate: 'Ultimo aggiornamento',
        lastUpdate_active: 'Giorni di attesa',
        latestInterviewAt: 'Colloquio più recente',
        numberOfClientInterviews: 'Colloqui sostenuti',
        hireDate: 'Data di assunzione',
        missedReason: 'Motivo',
      },
      states: {
        presented: 'Presentazione del candidato',
        positive_curriculum_feedback: 'Feedback positivo dopo la presentazione',
        interview_scheduled: 'Colloquio conoscitivo fissato',
        interview_done: 'Colloquio conoscitivo svolto',
        interview_interested: 'Feedback positivo dopo il colloquio conoscitivo',
        interview_meet_again: 'Colloqui di approfondimento',
        offer_incoming: 'In attesa di offerta',
        offer_made: 'Offerta presentata',
        presented_active: 'Ricevere feedback sul curriculum',
        positive_curriculum_feedback_active: 'Fissare il primo colloquio',
        interview_scheduled_active: 'Svolgere il colloquio',
        interview_done_active: 'Ricevere feedback dopo il colloquio',
        interview_interested_active: 'Avanzare dopo feedback positivo',
        interview_meet_again_active: 'Concludere la fase di colloqui',
        offer_incoming_active: 'Ricevere una vostra offerta',
        offer_made_active: 'Concludere la trattativa',
      },
      missedReason_rejected: 'Respinto',
      missedReason_withdrawn: 'Ritirato',
      missedReason_offer_rejected: 'Offerta rifiutata',
      noApplications: 'Nessun candidato trovato.',
      noApplications_error:
        'Qualcosa è andato storto, prova a ricaricare la pagina.',
    },
  },
};
