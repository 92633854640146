import React from 'react';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { selectorCustomer } from '../../model/Customer';
import { selectorUser } from '../../model/User';
import { USER_TYPES_MAP } from '../../constants/session';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const Hero = ({ className, title, isBackToHomepageAvailable }) => {
  const { t } = useTranslation();
  const { type: userType } = useStoreState(selectorUser);
  const { id: customerId } = useStoreState(selectorCustomer);

  const wrapperClassNames = classNames('c-app-hero', className);

  const isUserManager = userType === USER_TYPES_MAP.MANAGER;
  const linkPath = isUserManager ? `/customers/${customerId}` : '/';

  return (
    <div className={wrapperClassNames}>
      {isBackToHomepageAvailable && (
        <div className="c-app-masthead__back">
          <div className="c-back">
            <Link
              to={linkPath}
              modifier="c-back__anchor"
              component={LinkWrapper}
              external={false}
              type="simple-text"
              label={t('hero.backToHomepage', {
                context: isUserManager ? 'headHunter' : null,
              })}
              icon="icn-chevron-big-left-24"
              iconPosition="left"
            />
          </div>
        </div>
      )}

      {!!title && <h1 className="c-app-hero__title">{title}</h1>}
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  isBackToHomepageAvailable: PropTypes.bool,
};
