export const SEARCHING_IT = {
  header: {
    title: 'Ricerca',
    subtitle:
      'In questa pagina troverai tutti i dati della nostra ricerca del candidato ideale. Quante persone abbiamo cacciato, con quante abbiamo parlato e quante sono interessate.',
    subtitle_done: '',
  },
  numbers: {
    title_profilesCount: 'Cacciati',
    title_profilesWithJobInterviewReportCount: 'Intervistati',
    title_interestedProfilesCount: 'Interessati',
    title_interestedWithConcerns:
      'I candidati idonei hanno declinato per i seguenti motivi:',
    description_profilesCount:
      'Le persone in linea con il candidato ideale che abbiamo trovato e contattato.',
    description_profilesWithJobInterviewReportCount:
      'Le persone che abbiamo coinvolto in una prima intervista.',
    description_interestedProfilesCount:
      "Le persone che hanno espresso interesse per l'offerta. Con la maggior parte di loro abbiamo già svolto un primo colloquio, con alcuni siamo in attesa che il primo colloquio si svolga.",
    description_brand: 'Brand',
    description_role: 'Ruolo',
    description_compensation: 'Retribuzione',
    description_location: 'Luogo',
  },
  daysCard: {
    'intro_to-do': 'dopo',
    intro_doing: 'tra',
    daysNumber: '10',
    days: 'giorni',
    days_one: 'giorno',
    'body_to-do': 'dalla firma del contratto, potrai visualizzare tutti i dati',
    body_doing: 'potrai visualizzare tutti i dati',
  },
  jraCard: {
    title: 'La tua Job Reputation Analisys',
    subtitle:
      'Il documento che illustra le attività di ricerca e le analisi di mercato.',
    download: 'Scarica',
  },
  tasks: {
    title:
      'Vuoi sapere come procede la ricerca del candidato ideale? <strong>Ecco le ultime mie attività</strong>',
    viewLabel_grid: 'Vista a griglia',
    viewLabel_list: 'Vista a lista',
    viewIcon_grid: 'icn-dashboard-24',
    viewIcon_list: 'icn-list-minus-24',
    card: {
      date: '$t(common.formatting.date.dateFormat_time) - $t(common.formatting.date.dateFormat)',
      task: '',
      taskDescription: '',
      taskDescription_job_interview_reports_filled_in_one:
        'ha intervistato {{ count }} persona',
      taskDescription_job_interview_reports_filled_in:
        'ha intervistato {{ count }} persone',
      taskDescription_profiles_added: 'ha fatto {{ count }} attività di caccia',
      taskDescription_application_set_first_day_on_the_job:
        'ha inserito la data di inizio lavoro di {{ candidateFullName }}',
      taskDescription_application_handle_client_interview_feedback:
        'ha facilitato la decisione su {{ candidateFullName }}',
      taskDescription_application_handle_additional_client_interviews:
        'ha preparato il colloquio con {{ candidateFullName }}',
      taskDescription_application_help_negotiate_offer:
        'ha facilitato la trattativa con {{ candidateFullName }}',
      taskDescription_application_present_offer_to_candidate:
        'ha facilitato l\u0027assunzione di {{ candidateFullName }}',
      taskDescription_application_schedule_client_interview:
        'ha fissato il colloquio tra {{ companyName }} e {{ candidateFullName }}',
      taskDescription_application_ask_for_client_feedback_on_curriculum:
        'ha acquisito il feedback su {{ candidateFullName }}',
      taskDescription_application_prepare_for_additional_client_interview:
        'ha fissato il colloquio tra {{ companyName }} e {{ candidateFullName }}',
      taskDescription_application_handle_additional_client_interview_outcome:
        'ha contattato {{ candidateFullName }} per informarsi sul colloquio in azienda',
      taskDescription_application_prepare_for_client_interview:
        'ha fissato il colloquio tra {{ companyName }} e {{ candidateFullName }}',
      taskDescription_application_handle_client_interview_outcome:
        'ha contattato {{ candidateFullName }} per informarsi sul colloquio in azienda',
      taskDescription_application_schedule_client_interview_after_scout_report:
        'ha fissato il colloquio tra {{ companyName }} e {{ candidateFullName }}',
      taskDescription_application_notify_client_of_offer_refusal:
        'ha comunicato a {{ companyName }} che {{ candidateFullName }} si \u00e8 ritirato',
      taskDescription_application_check_in_day_before_new_job:
        'ha fatto un in bocca al lupo a {{ candidateFullName }}',
      taskDescription_application_check_in_after_fifteen_days_on_new_job:
        'ha chiesto feedback a {{ candidateFullName }} sulla sua nuova esperienza',
      taskDescription_application_check_in_after_fifty_days_on_new_job:
        'ha chiesto un ulteriore feedback a {{ candidateFullName }} sulla sua nuova esperienza',
      taskDescription_application_prepare_for_scout_interview:
        'ha fissato l\u0027intervista tra scout e {{ candidateFullName }}',
      taskDescription_application_handle_client_dislike:
        'ha richiesto la motivazione della bocciatura di {{ candidateFullName }}',
      taskDescription_application_ask_for_scout_report:
        'ha ricevuto e analizzato il report scout',
      taskDescription_application_approve_scout_report:
        'ha promosso {{ candidateFullName }} dopo l\u0027intervista scout',
      taskDescription_job_send_profiling_meeting_recap_email:
        'ha inviato email di recap della call di profiling',
      taskDescription_job_schedule_presentation_meeting:
        'ha fissato la data della JRA',
      taskDescription_job_contact_client_after_profiling_meeting:
        'ha inviato email con link per accedere alla piattaforma',
      taskDescription_job_prepare_for_presentation_meeting:
        'ha inviato candidati al cliente',
      taskDescription_job_hunt_candidates:
        'ha pubblicato l\u0027annuncio sui vari canali',
      taskDescription_job_find_scout: 'ha gestito lo scout sulla Job',
      taskDescription_job_schedule_profiling_meeting:
        'ha fissato la videocall di profiling',
      taskDescription_job_prepare_job_post:
        'ha pubblicato la Job nella piattaforma Reverse',
      taskDescription_job_prepare_for_profiling_meeting:
        'ha preparato la videocall di profiling',
    },
  },
};
