import { JobCardList } from '../JobCardList';
import React from 'react';

export const HomepageJobs = () => {
  return (
    <div className="container">
      <div className="row u-py-space-40 u-mt-space-40">
        <div className="col col--x-padded">
          <JobCardList />
        </div>
      </div>

      <div className="row u-py-space-40">
        <div className="col col--x-padded">
          <JobCardList isClosedJobList />
        </div>
      </div>
    </div>
  );
};
