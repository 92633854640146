const getApplicationsSortedAlphabetically = (applications = []) => {
  applications.sort((a, b) =>
    a.candidateFirstName.localeCompare(b.candidateFirstName),
  );
  return applications;
};

const getApplicationsSortedByState = (applications = []) => {
  const discardedApplications = getApplicationsSortedAlphabetically(
    applications?.filter(application => application.isDiscarded),
  );
  const interestedButApplications = getApplicationsSortedAlphabetically(
    applications?.filter(application => application.concerns !== null),
  );
  const interestedApplications = getApplicationsSortedAlphabetically(
    applications?.filter(
      application => !application.isDiscarded && application.concerns === null,
    ),
  );

  return [
    ...interestedApplications,
    ...interestedButApplications,
    ...discardedApplications,
  ];
};

export { getApplicationsSortedByState, getApplicationsSortedAlphabetically };
