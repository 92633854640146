import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorScreen } from './ErrorScreen';
import React from 'react';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants/navigation';

export const ErrorScreenExpiredLink: FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'login.expiredLink',
  });

  const history = useHistory();

  const title = t('title');
  const ctaLabel = t('cta');

  const onLinkClick = (): void => history.push(ROUTES.LOGIN);

  return (
    <ErrorScreen className="c-error-screen--expired-link" title={title}>
      <p className="c-error-screen__subtitle">
        <Trans
          i18nKey="login.expiredLink.description"
          components={{ strong: <strong /> }}
        />
      </p>

      <div className="c-error-screen__cta">
        <LinkWrapper label={ctaLabel} onClick={onLinkClick} type="primary" />
      </div>
    </ErrorScreen>
  );
};
