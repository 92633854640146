import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { selectorDeal } from '../model/Deal';
import { selectorCustomer } from '../model/Customer';
import { DEAL_PAGE_STATES, DEAL_PROGRESS_STATES } from '../constants/deal';
import {
  SearchingHeader,
  SearchingSidebar,
  SearchingTasks,
} from '../components/Searching';
import { PageStateProvider } from '../components/Providers/PageStateProvider';
import { utilityGetPageState } from '../utils/get-page-state';
import { GTM } from '../gtm';

const Searching = () => {
  const deal = useStoreState(selectorDeal);
  const customer = useStoreState(selectorCustomer);

  const pageState = utilityGetPageState({
    dealProgress: deal.progress,
    currentRoute: DEAL_PROGRESS_STATES.SEARCHING,
  });

  const isSearchingTasksVisible =
    pageState === DEAL_PAGE_STATES.DOING && !!deal.activities.length;

  useEffect(() => {
    if (pageState === DEAL_PAGE_STATES.DONE) {
      GTM.trackGTMEvent({
        action: EVENT_ACTION_SEARCH_DONE,
        label: `${customer.companyName} - ${deal.id} - ${deal.jobTitle}`,
      });

      GTM.trackGA4Event(
        EVENT_ACTION_SEARCH_DONE,
        customer.companyName,
        `${deal.id} - ${deal.jobTitle}`,
      );
    }
  }, [customer.companyName, deal.id, deal.jobTitle, pageState]);

  return (
    <PageStateProvider value={pageState}>
      <section data-testid="searching-page">
        <div className="container">
          <div className="row">
            <SearchingHeader />
            <SearchingSidebar />
          </div>
        </div>

        {isSearchingTasksVisible ? <SearchingTasks /> : null}
      </section>
    </PageStateProvider>
  );
};

export default Searching;

const EVENT_ACTION_SEARCH_DONE = 'Ricerca completata';
