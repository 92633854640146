import React from 'react';
import ReactSelect, { components } from 'react-select';
import { Icon } from '@reverse-hr/pattern-library';
import classNames from 'classnames';

export const Select = ({
  className,
  icon,
  options,
  defaultValue,
  onChange,
  value,
}) => {
  const wrapperClassNames = classNames('c-select', className);

  return (
    <ReactSelect
      icon={icon}
      options={options}
      onChange={onChange}
      onMenuOpen={() => {}}
      defaultValue={defaultValue}
      value={value}
      className={wrapperClassNames}
      styles={{
        control: () => null,
        menuList: () => null,
        option: () => null,
        valueContainer: () => null,
      }}
      classNames={{
        control: getControlClassNames,
        menuList: () => 'c-select__menu-list',
        option: getOptionClassNames,
        indicatorsContainer: () => 'c-select__indicators',
        valueContainer: () => 'c-select__value-container',
      }}
      components={{
        Control,
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
    />
  );
};

const DropdownIndicator = () => {
  return <Icon modifier="c-select__icon" name="icn-chevron-down-24" />;
};

const Control = ({ children, selectProps, ...props }) => {
  return (
    <components.Control {...props}>
      {!!selectProps.icon && (
        <Icon name={selectProps.icon} modifier="c-select__control-icon" />
      )}

      {children}
    </components.Control>
  );
};

const getControlClassNames = state =>
  classNames('c-select__control', {
    'c-select__control--focused': state.isFocused,
  });

const getOptionClassNames = state =>
  classNames('c-select__option', {
    'c-select__option--selected': state.isSelected,
    'c-select__option--focused': state.isFocused,
  });
