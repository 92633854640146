import TagManager from 'react-gtm-module';
import { sha256 } from 'js-sha256';

export const GTM = {
  userEmail: null,
  dataLayer: [],

  initialize({ locale, userEmail }) {
    this.userEmail = userEmail;

    const userHash = sha256.hmac(INTERCOM_SECRET_KEY, userEmail);

    TagManager.initialize({
      gtmId: GTM_ID,
      dataLayer: {
        language_override: locale,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        'User - Account - Email': userEmail,
        'User - Account - Hash': userHash,
      },
    });

    this.dataLayer = window.dataLayer ?? [];
  },

  trackGTMEvent({
    event = DEFAULT_GTM_EVENT_NAME,
    category = DEFAULT_GTM_CATEGORY,
    action,
    label,
  }) {
    if (!this.dataLayer) {
      return;
    }

    const dataLayerEvent = {
      event,
      event_category: category,
      event_action: action,
      event_label: `${label} - ${this.userEmail}`,
    };

    TagManager.dataLayer({ dataLayer: dataLayerEvent });
  },

  trackGA4Event(event, ...additionalArgs) {
    if (!this.dataLayer) {
      return;
    }

    const [category, action] = additionalArgs;

    const dataLayerEvent = {
      event: GA4_EVENT_NAME,
      name_event: `EV - ${event}`,
      label: this.userEmail,
      category,
      action,
    };

    TagManager.dataLayer({ dataLayer: dataLayerEvent });
  },
};

const GTM_ID = 'GTM-WQDRZG2';
const GA4_EVENT_NAME = 'ga4_event';
const INTERCOM_SECRET_KEY = '1PI0-nj5u1Jd3ORi-EbwxqjZ1mkWl3bGnd-l0n7u';
const DEFAULT_GTM_EVENT_NAME = 'employer_view_interaction';
const DEFAULT_GTM_CATEGORY = 'employer_view';
