import Helper from '../Helper';
import { ApplicationFeedbackActions } from './ApplicationFeedbackActions';
import { useTranslation } from 'react-i18next';

export const ApplicationFeedback = ({
  candidateFullName,
  isCustomerInterested,
  isCandidateDiscarded,
  isCandidateInterviewed,
  onFeedbackUpdate,
  manager,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'candidateDetails.feedback',
  });

  return (
    <div className="c-application-feedback">
      <Helper
        manager={manager}
        modifier="c-app-helper--masthead c-app-helper--masthead-candidate"
        text={t('bubble', {
          context: getHelperTextContext({
            isCustomerInterested,
            isCandidateDiscarded,
            isCandidateInterviewed,
          }),
          candidateFullName,
        })}
      />

      {isCandidateDiscarded ? null : (
        <div className="c-application-feedback__actions c-app-masthead__bet">
          {isCandidateInterviewed ? (
            <div className=" c-app-fat-button c-app-fat-button--positive">
              {t('interesting')}
            </div>
          ) : (
            <ApplicationFeedbackActions
              isCustomerInterested={isCustomerInterested}
              onFeedbackUpdate={onFeedbackUpdate}
            />
          )}
        </div>
      )}
    </div>
  );
};

const getHelperTextContext = ({
  isCustomerInterested,
  isCandidateDiscarded,
  isCandidateInterviewed,
}) => {
  if (isCandidateDiscarded) {
    return 'discarded';
  }

  if (isCustomerInterested !== null && !isCandidateInterviewed) {
    return isCustomerInterested === true ? 'interested' : 'notInterested';
  }

  return undefined;
};
