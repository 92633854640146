export const HOMEPAGE_EN = {
  header: {
    navigation: {
      jobs: 'Your jobs',
      candidates: 'Your candidates',
    },
  },
  jobList: {
    title_open: 'Your open jobs',
    title_closed: 'Closed jobs',
    cardState_searching: '$t(hero.steps.searching.title)',
    cardState_interviewing: '$t(hero.steps.interviewing.title)',
    cardState_closing: '$t(hero.steps.closing.title)',
    cardState_closed: 'Closed on $t(common.formatting.date.dateFormat)',
  },
  candidates: {
    filterByJob: 'Filter by job',
    allJobs: 'All jobs',
    allStates: 'All candidates on hold',
    title_active: 'Active Candidates',
    intro_active:
      "Here you can find all the active candidates in your open selections.<br/>The column '$t(homepage.candidates.table.header.state)' indicates what is still pending from your end to proceed in the selection process, while '$t(homepage.candidates.table.header.lastUpdate_active)' indicates how long the candidate has been waiting. As the waiting days increase, the likelihood of losing the candidate also rises.<br/><br/>For more information, you can click on a candidate's name to view their profile or select a position to track the progress of a single selection.",
    title_hired: 'Hired Candidates',
    intro_hired: 'Here you can find all the candidates that have been hired',
    title_stopped: 'Stopped Candidates',
    intro_stopped:
      "Here you can find all the rejected or withdrawn candidates. You can check in which phase of the process they stopped in the '$t(homepage.candidates.table.header.state_stopped)' column, along with the date of the last update and the number of interviews conducted with each.",
    table: {
      header: {
        candidateFirstName: 'Candidate',
        jobTitle: 'Job',
        state: 'Status',
        state_active: 'Waiting to',
        state_stopped: 'Selection phase',
        lastUpdate: 'Last update',
        lastUpdate_active: 'Waiting days',
        latestInterviewAt: 'Most recent interview',
        numberOfClientInterviews: 'Interviews conducted',
        hireDate: 'Hire date',
        missedReason: 'Missed reason',
      },
      states: {
        presented: 'Candidate presentation',
        positive_curriculum_feedback:
          'Positive feedback after the presentation',
        interview_scheduled: 'Introductory interview scheduled',
        interview_done: 'Introductory interview conducted',
        interview_interested:
          'Positive feedback after the introductory interview',
        interview_meet_again: 'In-depth interviews',
        offer_incoming: 'Awaiting offer',
        offer_made: 'Offer presented',
        presented_active: 'Receive feedback on the CV',
        positive_curriculum_feedback_active: 'Schedule the first interview',
        interview_scheduled_active: 'Conduct the interview',
        interview_done_active: 'Receive feedback after the interview',
        interview_interested_active: 'Advance after positive feedback',
        interview_meet_again_active: 'Conclude the interview phase',
        offer_incoming_active: 'Receive an offer from you',
        offer_made_active: 'Conclude the negotiation',
      },
      missedReason_rejected: 'Rejected',
      missedReason_withdrawn: 'Withdrawn',
      missedReason_offer_rejected: 'Offer rejected',
      noApplications: 'No candidates found.',
      noApplications_error: 'Something went wrong, try reloading the page.',
    },
  },
};
