export const HOMEPAGE_TABS_ROUTES = {
  JOBS: '/',
  CANDIDATES: '/candidates',
};

export const HOMEPAGE_TABS_KEYS = {
  JOBS: 'jobs',
  CANDIDATES: 'candidates',
};

export const HOMEPAGE_TAB_KEY_LIST = Object.values(HOMEPAGE_TABS_KEYS);
