import { AppSummary } from '../AppSummary';
import { Trans, useTranslation } from 'react-i18next';
import {
  utilityGetSuccessFeePayments,
  utilityIsMinimalActive,
} from '../../utils/success-fee';
import { AppCount } from '../AppCount';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SUCCESS_FEE_ESTIMATE_COUNTS_MAP } from '../../constants/success-fee';

export const SuccessFeeSummary = ({ prospect }) => {
  const { t, i18n } = useTranslation();

  const { currency } = prospect;
  const { counts, total } = utilityGetSuccessFeePayments(prospect);

  const isMinimalActive = utilityIsMinimalActive(counts);

  const wrapperClassNames = classNames(
    'c-app-summary--stacked-centered c-app-summary--success-fee c-app-summary--primary',
    {
      'c-app-summary--with-minimal': isMinimalActive,
    },
  );

  return (
    <AppSummary
      className={wrapperClassNames}
      title={<strong>{t('successFee.successFee.title')}</strong>}
      footerLabel={<strong>{t('successFee.successFee.footer')}</strong>}
      total={total}
      currency={currency}
    >
      {counts.map((count, countIdx) => (
        <AppCount
          key={count.key}
          className={getCountClassNames(countIdx, count.key)}
          price={count.value}
          currency={currency}
          isStruckOut={
            count.key === SUCCESS_FEE_ESTIMATE_COUNTS_MAP.OVERALL_FEE
          }
          title={t(`successFee.successFee.${count.key}.title`, prospect)}
          description={
            i18n.exists(`successFee.successFee.${count.key}.description`) ? (
              <Trans
                i18nKey={`successFee.successFee.${count.key}.description`}
                components={{ br: <br /> }}
                values={prospect}
              />
            ) : null
          }
        />
      ))}
    </AppSummary>
  );
};

SuccessFeeSummary.propTypes = {
  prospect: PropTypes.object.isRequired,
};

const getCountClassNames = (countIdx, countKey) =>
  classNames('c-app-count--success-fee', {
    'c-app-count--neutral-even': !(countIdx % 2),
    'c-app-count--neutral-odd': countIdx % 2,
    'c-app-count--overall-fee':
      countKey === SUCCESS_FEE_ESTIMATE_COUNTS_MAP.OVERALL_FEE,
    'c-app-count--minimal':
      countKey === SUCCESS_FEE_ESTIMATE_COUNTS_MAP.MINIMUM_SUCCESS_FEE_AMOUNT,
  });
