import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { selectorUser } from '../../model/User';

export const WelcomeMessage = () => {
  const { t } = useTranslation();
  const user = useStoreState(selectorUser);

  return (
    <div className="c-welcome-message u-txt-right">
      <p className="typo-text-label">{t('header.welcome')}</p>

      {!!user.firstName && !!user.lastName && (
        <p className="typo-text-body typo-bold">
          {t('common.formatting.fullName', user)}
        </p>
      )}
    </div>
  );
};
