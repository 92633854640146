export const ERRORS_IT = {
  employer_view: {
    eligible_application: {
      property: {
        customer_interest_notes: {
          too_long:
            'La motivazione che hai inserito è troppo lunga, per favore inserisci un testo più breve.',
        },
      },
    },
  },
};
