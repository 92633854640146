import { NavLink } from 'react-router-dom';
import { Icon } from '@reverse-hr/pattern-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { selectorCustomer } from '../../model/Customer';
import { useStoreState } from 'easy-peasy';
import { GTM } from '../../gtm';

export const JobCard = ({ job }) => {
  const { t } = useTranslation();
  const { companyName } = useStoreState(selectorCustomer);

  const wrapperClassNames = classNames('c-job-card', {
    'c-job-card--closed': job.closedOn,
  });

  const linkHref = `/${job.dealId}`;
  const jobStatusIcon = JOB_STATES_ICONS[job.status];

  const trackNavigatingToJob = () => {
    GTM.trackGTMEvent({
      action: EVENT_ACTION_NAVIGATION_TO_JOB,
      label: `${companyName} - ${job.dealId} - ${job.title}`,
    });

    GTM.trackGA4Event(
      EVENT_ACTION_NAVIGATION_TO_JOB,
      companyName,
      `${job.dealId} - ${job.title}`,
    );
  };

  return (
    <div className={wrapperClassNames}>
      <NavLink
        className="c-job-card__link"
        to={linkHref}
        target="_blank"
        onClick={trackNavigatingToJob}
      >
        <div className="c-job-card__header">
          <p className="c-job-card__title">{job.title}</p>

          {!!job.city && (
            <p className="c-job-card__location">
              <Icon name="icn-location-outline-24" size={16} />
              {job.city}
            </p>
          )}
        </div>

        <div className="c-job-card__footer">
          {!!jobStatusIcon && !job.closedOn && (
            <Icon
              modifier="c-job-card__state-icon"
              name={jobStatusIcon}
              size={20}
            />
          )}

          {t('homepage.jobList.cardState', {
            context: job.closedOn ? 'closed' : job.status,
            date: job.closedOn ? new Date(job.closedOn) : null,
          })}
        </div>
      </NavLink>
    </div>
  );
};

const JOB_STATES = {
  SEARCHING: 'searching',
  INTERVIEWING: 'interviewing',
  CLOSING: 'closing',
};

const JOB_STATES_ICONS = {
  [JOB_STATES.SEARCHING]: 'icn-search-small-24',
  [JOB_STATES.INTERVIEWING]: 'icn-chat-24',
  [JOB_STATES.CLOSING]: 'icn-flag-outline-24',
};

const EVENT_ACTION_NAVIGATION_TO_JOB = `Navigazione verso il dettaglio di una job`;
