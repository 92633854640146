export const ERRORS_DE = {
  employer_view: {
    eligible_application: {
      property: {
        customer_interest_notes: {
          too_long:
            'Die eingegebene Begründung ist zu lang, bitte gib einen kürzeren Text ein.',
        },
      },
    },
  },
};
