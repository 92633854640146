import {
  DEAL_PAGE_STATES,
  DEAL_PROGRESS_STATES,
  DEAL_PROGRESS_STATES_LIST,
} from '../constants/deal';

export const utilityGetPageState = ({
  dealProgress,
  currentRoute,
  hasClosedAtDate = false,
  hasApplications = false,
}) => {
  const dealProgressIndex = DEAL_PROGRESS_STATES_LIST.indexOf(dealProgress);
  const currentPageIndex = DEAL_PROGRESS_STATES_LIST.indexOf(currentRoute);

  if (currentRoute === DEAL_PROGRESS_STATES.INTERVIEWING && hasApplications) {
    return currentPageIndex >= dealProgressIndex
      ? DEAL_PAGE_STATES.DOING
      : DEAL_PAGE_STATES.DONE;
  }

  if (!dealProgress) {
    return DEAL_PAGE_STATES.TO_DO;
  }

  if (hasClosedAtDate) {
    return DEAL_PAGE_STATES.DONE;
  }

  if (dealProgress === currentRoute) {
    return DEAL_PAGE_STATES.DOING;
  }

  return currentPageIndex > dealProgressIndex
    ? DEAL_PAGE_STATES.TO_DO
    : DEAL_PAGE_STATES.DONE;
};
