import { Action, SwitchToggle } from '@reverse-hr/pattern-library';
import { useTranslation, Trans } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { showcaseTableSelector } from '../../../../../store/selectors/showcase-table';

const Header = ({ isRestoreViewButtonVisible, isValidCandidateList }) => {
  const { areDiscardedVisible, areInterestedButVisible, lastSaveDate } =
    useStoreState(showcaseTableSelector);
  const { setDiscardedVisibility, setInterestedButVisibility, resetTable } =
    useStoreActions(showcaseTableSelector);
  const { t } = useTranslation();

  const toggleInterestedButVisibility = () => {
    setInterestedButVisibility(!areInterestedButVisible);
  };

  const toggleDiscardedVisibility = () => {
    setDiscardedVisibility(!areDiscardedVisible);
  };

  const renderResetTable = () =>
    isRestoreViewButtonVisible && (
      <Action
        label={t('showcase.filters.restoreView')}
        size="small"
        type="outline"
        icon="icn-refresh-24"
        iconPosition="left"
        onClick={() => {
          resetTable();
        }}
      />
    );

  return (
    <>
      <div className="col col--x-padded small-12 large-5">
        <div className="u-hidden-large-down">
          <div className="c-app-intro-flow">
            <p className="c-app-intro-flow__title">{t('showcase.title')}</p>
            {!!lastSaveDate ? (
              <p
                className="c-app-intro-flow__label"
                data-testid="showcase-last-save-date"
              >
                {t('showcase.lastViewUpdate', { date: lastSaveDate })}
              </p>
            ) : null}
            {!isValidCandidateList && (
              <p
                data-testid="showcase-empty-candidate-list-text"
                className="c-app-intro-flow__body"
              >
                {t('showcase.emptyCandidateList')}
              </p>
            )}
          </div>
        </div>
        <div className="u-hidden-large-up">
          <div className="c-app-intro-flow">
            <p className="c-app-intro-flow__title">{t('showcase.title')}</p>
            <p className="c-app-intro-flow__body">
              <Trans i18nKey="showcase.mobileView">
                0 <strong>1</strong>
              </Trans>
            </p>
          </div>
        </div>
      </div>
      {isValidCandidateList && (
        <div className="col col--x-padded small-12 large-7 u-hidden-large-down">
          <div
            className="u-flex u-flex-item-center u-flex-gap-space-24 u-float-right u-py-space-40"
            data-testid="showcase-filters"
          >
            {renderResetTable()}
            <div
              className="u-flex"
              data-testid="showcase-interested-but-candidates-filter"
            >
              <p className="typo-text-hint u-color-grey-60 u-px-space-8 u-py-space-4">
                <Trans i18nKey="showcase.filters.interestedBut">
                  0 <strong>1</strong>
                </Trans>
              </p>
              <SwitchToggle
                id="show-interested-but"
                checked={areInterestedButVisible}
                name="show-interested-but"
                onText={t('showcase.yes')}
                offText={t('showcase.no')}
                onChange={() => {
                  toggleInterestedButVisibility();
                }}
              />
            </div>
            <div
              className="u-flex"
              data-testid="showcase-out-candidates-filter"
            >
              <p className="typo-text-hint u-color-grey-60 u-px-space-8 u-py-space-4">
                <Trans i18nKey="showcase.filters.out">
                  0 <strong>1</strong>
                </Trans>
              </p>
              <SwitchToggle
                id="show-out-candidates"
                checked={areDiscardedVisible}
                name="show-out-candidates"
                onText={t('showcase.yes')}
                offText={t('showcase.no')}
                onChange={() => {
                  toggleDiscardedVisibility();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
