export const DEAL_PROGRESS_STATES = {
  PROFILING: 'profiling',
  SEARCHING: 'searching',
  INTERVIEWING: 'interviewing',
  CLOSING: 'closing',
};

export const DEAL_PROGRESS_STATES_LIST = Object.values(DEAL_PROGRESS_STATES);

export const DEAL_PAGE_STATES = {
  TO_DO: 'to-do',
  DOING: 'doing',
  DONE: 'done',
};
