export const CANDIDATE_DETAILS_ES = {
  feedback: {
    bubble:
      '¿Qué piensas de {{ candidateFullName }}? <strong>¿Es interesante?</strong>',
    bubble_interested: '<strong>{{ candidateFullName }}</strong> le interesa',
    bubble_notInterested:
      '<strong>{{ candidateFullName }}</strong> no le interesa',
    bubble_discarded: '<strong>{{ candidateFullName }}</strong> no continúa.',
    actionYes: 'Sí',
    actionNo: 'No',
    actionChangeFeedback: '¿Cambiaste de opinión?',
    interesting: 'Es interesante',
  },
  intro: {
    editLink: 'Modificar candidatura',
  },
  dossier: {
    title: 'Curriculum vitae',
    subtitle: 'Entrevista de trabajo',
    button: 'Descargar el dossier',
  },
  scout: {
    accordionOpen: 'Abrir',
    accordionClose: 'cerca',
    title: 'Informe del Scout',
    accordionTitle_skillsAssessment: 'Evaluación de habilidades',
    accordionTitle_overallAssessment: 'Resumen de roles',
    button: 'Descargar el informe',
  },
  negativeFeedback: {
    title: '¿Por qué no es interesante?',
    subtitle:
      'Saber por qué no consideras adecuado a este candidato para el puesto (por ejemplo, habilidades, antigüedad, etc.) nos ayuda a presentar solo candidatos perfectamente alineados con tus expectativas.',
    notesLabel: 'Escribe la motivación',
    notesPlaceholder:
      'La motivación se enviará al Head Hunter que sigue la selección.',
    submit: 'Enviar',
    cancel: 'Cancelar',
    successTitle: 'Mensaje enviado:',
    successSubtitle:
      'Hemos enviado tu evaluación al Head Hunter que sigue la búsqueda.',
    successClose: 'Vale, gracias.',
  },
};
