import { useTranslation } from 'react-i18next';
import { Tabs } from '@reverse-hr/pattern-library';
import {
  HOMEPAGE_TAB_KEY_LIST,
  HOMEPAGE_TABS_KEYS,
  HOMEPAGE_TABS_ROUTES,
} from '../../constants/homepage';
import { useHistory, useParams } from 'react-router-dom';
import { useHomepageRouteMatch } from '../../utils/hooks/use-homepage-route-match';

export const HomepageNavigation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { customerId } = useParams();

  const { isHomepageJobsPage, isHomepageCandidatesPage } =
    useHomepageRouteMatch();

  const tabs = HOMEPAGE_TAB_KEY_LIST.map(tabKey => ({
    label: t(`homepage.header.navigation.${tabKey}`),
    key: tabKey,
    active:
      tabKey === HOMEPAGE_TABS_KEYS.JOBS
        ? isHomepageJobsPage
        : isHomepageCandidatesPage,
  }));

  const handleTabChange = tab => {
    const isSelectedTabCandidates = tab.key === HOMEPAGE_TABS_KEYS.CANDIDATES;

    history.push(getTabTargetPath({ customerId, isSelectedTabCandidates }));
  };

  return (
    <div className="c-homepage-navigation">
      <Tabs
        modifier=" c-homepage-navigation__tabs"
        tabs={tabs}
        onChange={handleTabChange}
      ></Tabs>
    </div>
  );
};

const getTabTargetPath = ({ customerId, isSelectedTabCandidates }) => {
  if (!customerId) {
    return isSelectedTabCandidates
      ? HOMEPAGE_TABS_ROUTES.CANDIDATES
      : HOMEPAGE_TABS_ROUTES.JOBS;
  }

  const customersPathSegment = `customers/${customerId}`;

  return isSelectedTabCandidates
    ? `/${customersPathSegment}${HOMEPAGE_TABS_ROUTES.CANDIDATES}`
    : `${HOMEPAGE_TABS_ROUTES.JOBS}${customersPathSegment}`;
};
