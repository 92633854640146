import showcase from './showcase';
import { COMMON_IT } from './common.js';
import { INTERVIEWING_IT } from './interviewing.js';
import { SEARCHING_IT } from './searching.js';
import { CLOSING_IT } from './closing';
import { SUCCESS_FEE_IT } from './success-fee.js';
import { HOMEPAGE_IT } from './homepage';
import { CANDIDATE_DETAILS_IT } from './candidate-details';
import { ERRORS_IT } from './errors';
import * as LOGIN_IT from './login.json';

const IT = {
  translation: {
    header: {
      welcome: 'Bentornato',
      share: 'Condividi',
    },
    hero: {
      backToHomepage: 'Tutti i tuoi mandati',
      backToHomepage_headHunter: 'Tutti i mandati del cliente',
      prospect: {
        title: 'Prospetto di calcolo success fee',
      },
      steps: {
        profiling: {
          title: 'Profilo',
          title_no_miro: 'Referenti',
          description: 'Inizio',
        },
        searching: {
          title: 'Ricerca',
          description: '1-10°GG',
        },
        showcase: {
          title: 'Showcase',
          description: '11°GG',
        },
        interviewing: {
          title: 'Colloqui',
          description: '11-20°GG',
        },
        closing: {
          title: 'Chiusura',
          description: 'Fine',
        },
      },
      helper: {
        profiling: {
          'to-do':
            'Ciao. In queste pagine <strong>puoi vedere</strong> ogni step delle attività che svolgiamo per te, aggiornate in tempo reale.',
          'to-do_withName':
            'Ciao {{ name }}. In queste pagine <strong>puoi vedere</strong> ogni step delle attività che svolgiamo per te, aggiornate in tempo reale.',
          doing:
            'Ciao. In queste pagine <strong>puoi vedere</strong> ogni step delle attività che svolgiamo per te, aggiornate in tempo reale.',
          doing_withName:
            'Ciao {{ name }}. In queste pagine <strong>puoi vedere</strong> ogni step delle attività che svolgiamo per te, aggiornate in tempo reale.',
          done: 'Ciao. In queste pagine <strong>puoi vedere</strong> ogni step delle attività che svolgiamo per te, aggiornate in tempo reale.',
          done_withName:
            'Ciao {{ name }}. In queste pagine <strong>puoi vedere</strong> ogni step delle attività che svolgiamo per te, aggiornate in tempo reale.',
        },
        searching: {
          'to-do':
            'Durante la ricerca, fino a che non saranno disponibili i dati, <strong>vedrai le attività che svolgo per te.</strong>',
          doing:
            'Durante la ricerca, fino a che non saranno disponibili i dati, <strong>vedrai le attività che svolgo per te.</strong>',
          done: 'Ricerca conclusa! Qui sotto trovi i <strong>numeri</strong> conclusivi e la <strong>JRA</strong>, la nostra analisi finale.',
        },
        showcase: {
          'to-do':
            'Durante la ricerca, fino a che non saranno disponibili i dati, <strong>vedrai le attività che svolgo per te.</strong>',
          doing:
            'Durante la ricerca, fino a che non saranno disponibili i dati, <strong>vedrai le attività che svolgo per te.</strong>',
          done: 'Ricerca conclusa! Qui sotto trovi i <strong>numeri</strong> conclusivi e la <strong>JRA</strong>, la nostra analisi finale.',
        },
        interviewing: {
          'to-do':
            'In questa fase ti <strong>mostreremo i candidati</strong> e avremo bisogno di raccogliere <strong>feedback tempestivi</strong>',
          doing:
            'Siamo nel vivo della selezione. Ricorda che <strong>più siamo tempestivi, maggiori sono le probabilità</strong> di assumere.',
          done: 'Siamo nel vivo della selezione. Ricorda che <strong>più siamo tempestivi, maggiori sono le probabilità</strong> di assumere.',
        },
        closing: {
          'to-do':
            'È la fase finale: la più delicata. <strong>Negoziazione, dimissioni e onboarding</strong>.',
          doing:
            'È la fase finale: la più delicata. <strong>Negoziazione, dimissioni e onboarding</strong>.',
          done: 'È la fase finale: la più delicata. <strong>Negoziazione, dimissioni e onboarding</strong>.',
        },
      },
    },
    profile: {
      header: {
        title: 'Profilo',
        subtitle:
          'In questa pagina troverai un riepilogo sempre aggiornato del profilo candidato, della tua azienda e del nostro servizio.',
      },
      cards: {
        action: {
          label: 'Guarda i dettagli',
        },
        1: {
          title: 'Il profilo del candidato',
        },
        2: {
          title: 'Il racconto della tua azienda al candidato',
        },
      },
      contract: {
        title: 'Qui trovi il contratto di questo mandato',
        download: 'Scarica il contratto',
      },
      references: {
        title: 'I tuoi referenti',
        blank: {
          title: 'Stiamo cercando la persona giusta per questo mandato',
          text: 'Vogliamo il miglior head hunter che abbiamo a disposizione...',
        },
      },
      mirror: {
        body: '<strong>Un nuovo modo di fare recruiting</strong><br><br>FLESSIBILE, DIGITALE, SPECIALIZZATO.',
        link: 'Scopri il recruitment collaborativo',
      },
    },
    general: {
      error: {
        title: 'Si è verificato un problema',
        subtitle:
          'Prova ad aggiornare la pagina. Se non si risolve riprova più tardi',
        hash: {
          title: 'Questo link è scaduto.',
          subtitle:
            'Dovresti aver ricevuto il <strong>link corretto</strong> in una delle <strong>nostre ultime email</strong>',
          disclaimer:
            'Non riesci a trovarla? <strong>Contatta i tuoi referenti</strong>',
        },
        unauthorized: {
          title: 'Questo link sembra sbagliato.',
          subtitle:
            'Dovresti aver ricevuto il <strong>link corretto</strong> in una <strong>nostra email</strong>, poco tempo <strong>dopo la firma del contratto.</strong>',
          disclaimer:
            'Non riesci a trovarla? <strong>Contatta i tuoi referenti</strong>',
        },
        unauthorizedHomepage: {
          title: 'Non sei autorizzato a visualizzare questa pagina.',
          subtitle: '',
          disclaimer: '',
        },
      },
      document_title: {
        profiling: 'Profilo',
        searching: 'Ricerca',
        interviewing: 'Colloqui',
        closing: 'Chiusura',
        showcase: 'Showcase',
      },
      date_on: 'il',
      date_hour: 'alle',
    },
    candidate: {
      hero: {
        title: 'stai cercando:',
        back_label: 'Torna al mandato',
      },
      infos: {
        age: 'anni',
        benefits: 'Benefit attuali',
        bonuses: 'Bonus attuali',
      },
      sections: {
        horse: {
          title: 'Stato attuale',
        },
        bet: {
          open_chat: 'Scrivi in chat a <strong>{{ name }}</strong>',
        },
        curriculum: {
          title: 'Curriculum Vitae',
          screening: 'Screening ranking',
          button: 'Scarica il curriculum',
        },
        summary: {
          otherSelectionsTitle: 'Altri processi di selezione',
        },
        chat: {
          hour: ' ore ',
          feedback_title: 'Lasciaci i tuoi feedback',
          name_title: 'Scrivi a <strong>{{ name }}</strong>',
          open: 'leggi tutto',
          close: 'chiudi',
          fullscreen: 'Tutto schermo',
          default_message:
            'Ciao, se hai qualcosa da comunicarci, usa pure questa chat.',
          default_message_withName:
            'Ciao {{ name }}, se hai qualcosa da comunicarci, usa pure questa chat.',
          default_message_positive:
            'Ciao, perché <strong>ti interessa</strong>? In fase di colloquio vuoi approfondire alcuni aspetti?',
          default_message_positive_withName:
            'Ciao {{ name }}, perché <strong>ti interessa</strong>? In fase di colloquio vuoi approfondire alcuni aspetti?',
          default_message_negative:
            'Ciao, scrivimi le tue motivazioni così potremo perfezionare la ricerca dei candidati.',
          default_message_negative_withName:
            'Ciao {{ name }}, scrivimi le tue motivazioni così potremo perfezionare la ricerca dei candidati.',
        },
        concerns: {
          intro:
            '{{ name }} è un profilo molto interessante ma non completamente in linea con i requisiti.',
        },
      },
    },
    footer: {
      company: 'Reverse SpA',
      headquarter: 'Sede legale: Piazza Sigmund Freud 1, 20154 Milano',
      infos: `
        <p class='c-footer__paragraph'>Partita Iva: 09714690964</p>;
        <p class='c-footer__paragraph'>Pec: reversespa@pec.it</p>;
        <p class='c-footer__paragraph'>Registro delle Imprese di Milano</p>;
        <p class='c-footer__paragraph'>Capitale sociale interamente versato: 50.000€</p>;
        <p class='c-footer__paragraph'>Numero REA MI - 2109818</p>;
        <p class='c-footer__paragraph'>Aut. Min. 0000031.26-02-2024</p>
      `,
      menu: {
        contact: {
          label: 'Contattaci',
          slug: 'contattaci',
        },
        legal: {
          label: 'Legal',
          slug: 'legal',
        },
        cookies: {
          label: 'Cookies',
          url: 'https://www.iubenda.com/privacy-policy/60130224/cookie-policy',
        },
      },
    },
    modal: {
      share: {
        title:
          "Invia invito per rimanere aggiornati sull'avanzamento della selezione",
        inputs: {
          first_name: {
            label: 'Nome',
          },
          last_name: {
            label: 'Cognome',
          },
          email: {
            label: 'Email',
            hint: "L'email non corisponde al dominio con sui sei registrato sei sicuro di voler continuare?",
          },
          lang: {
            label: 'Lingua',
          },
        },
        lang_options: {
          it: 'Italiano',
          en: 'Inglese',
          de: 'Tedesco',
          fr: 'Francese',
          es: 'Spagnolo',
        },
        button: 'Invia invito',
        shared_with: 'Condiviso con',
        alert: {
          positive: "L'email di invito è stata inviata",
          negative:
            'Qualcosa è andato storto. Riprova più tardi o contatta i tuoi referenti',
        },
      },
    },
    validation: {
      scout_approved: 'Validato da Scout',
      scout_required: 'Validare con Scout',
      required: 'Questo campo è richiesto',
      email: 'Questo indirizzo email non è valido',
    },
    language_switcher: {
      it: 'IT',
      en: 'EN',
      de: 'DE',
      fr: 'FR',
      es: 'ES',
    },
    showcase,
    candidateDetails: CANDIDATE_DETAILS_IT,
    common: COMMON_IT,
    interviewing: INTERVIEWING_IT,
    searching: SEARCHING_IT,
    closing: CLOSING_IT,
    successFee: SUCCESS_FEE_IT,
    homepage: HOMEPAGE_IT,
    login: LOGIN_IT,
    errors: ERRORS_IT,
  },
};

export default IT;
