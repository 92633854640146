import React from 'react';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import bullet from '../assets/images/bullet.png';

const Brackets = ({ modifier, items, button }) => {
  return (
    <div className={`c-app-brackets ${modifier}`}>
      <div className="c-app-brackets__items">
        {items
          ? items.map((item, i) => {
              return (
                <div key={`bracket-${i}`} className="c-app-brackets__item">
                  <img src={bullet} alt={item.title} />
                  <p className="c-app-brackets__item-text">{item.title}</p>
                </div>
              );
            })
          : null}
      </div>
      <div className="c-app-brackets__action">
        <LinkWrapper type="outline" external target="_blank" {...button} />
      </div>
    </div>
  );
};

Brackets.defaultProps = {
  modifier: '',
  button: {},
};

export default Brackets;
