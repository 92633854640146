export const HOMEPAGE_FR = {
  header: {
    navigation: {
      jobs: 'Vos mandats',
      candidates: 'Vos candidats',
    },
  },
  jobList: {
    title_open: 'Mandats en cours',
    title_closed: 'Mandats conclus',
    cardState_searching: '$t(hero.steps.searching.title)',
    cardState_interviewing: '$t(hero.steps.interviewing.title)',
    cardState_closing: '$t(hero.steps.closing.title)',
    cardState_closed: 'Fermé le $t(common.formatting.date.dateFormat)',
  },
  candidates: {
    filterByJob: 'Filtrer par mandat',
    allJobs: 'Tous les mandats',
    allStates: 'Tous les candidats en attente',
    title_active: 'Candidats en cours',
    intro_active:
      "Ici, vous trouverez tous les candidats actifs dans vos sélections ouvertes.<br/>La colonne '$t(homepage.candidates.table.header.state)' indique ce qui manque au candidat de votre part pour avancer dans le processus de sélection, tandis que '$t(homepage.candidates.table.header.lastUpdate_active)' indique depuis combien de temps le candidat attend. Plus les jours d'attente augmentent, plus la probabilité de perdre le candidat augmente.<br/><br/>Si vous souhaitez plus d'informations, vous pouvez consulter le profil d'un candidat en cliquant sur son nom ou le progrès d'une sélection individuelle en cliquant sur un poste.",
    title_hired: 'Candidats recrutés',
    intro_hired:
      'Ici, vous pouvez retrouver tous les candidats qui ont été recrutés.',
    title_stopped: 'Candidats exclus',
    intro_stopped:
      "Ici, vous pouvez trouver tous les candidats rejetés ou retirés. Vous pouvez vérifier dans quelle phase du processus ils se sont arrêtés dans la colonne '$t(homepage.candidates.table.header.state_stopped)', ainsi que la date de la dernière mise à jour et le nombre d'entretiens menés avec chacun.",
    table: {
      header: {
        candidateFirstName: 'Candidat',
        jobTitle: 'Mandat',
        state: 'Statut',
        state_active: 'En attente de',
        state_stopped: 'Phase de sélection',
        lastUpdate: 'Dernière mise à jour',
        lastUpdate_active: "Jours d'attente",
        latestInterviewAt: 'Dernier entretien',
        numberOfClientInterviews: 'Entretiens réalisés',
        hireDate: "Date d'embauche",
        missedReason: 'Raison du rejet',
      },
      states: {
        presented: 'Présentation du candidat',
        positive_curriculum_feedback: 'Retour positif après la présentation',
        interview_scheduled: "Entretien d'introduction planifié",
        interview_done: "Entretien d'introduction réalisé",
        interview_interested:
          "Retour positif après le entretien d'introduction",
        interview_meet_again: 'Entretiens approfondis',
        offer_incoming: "En attente d'une offre",
        offer_made: 'Offre présentée',
        presented_active: 'Recevoir un retour sur le CV',
        positive_curriculum_feedback_active: 'Planifier le premier entretien',
        interview_scheduled_active: "Mener l'entretien",
        interview_done_active: "Recevoir un retour après l'entretien",
        interview_interested_active: 'Avancer après un retour positif',
        interview_meet_again_active: "Conclure la phase d'entretiens",
        offer_incoming_active: 'Recevoir une offre de votre part',
        offer_made_active: 'Conclure la négociation',
      },
      missedReason_rejected: 'Rejeté',
      missedReason_withdrawn: 'Retiré',
      missedReason_offer_rejected: 'Offre rejetée',
      noApplications: 'Aucun candidat trouvé.',
      noApplications_error:
        "Quelque chose s'est mal passé, essayez de recharger la page.",
    },
  },
};
