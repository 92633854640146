import { Action, Icon } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';
import { useStoreActions } from 'easy-peasy';
import { showcaseTableSelector } from '../../../../../store/selectors/showcase-table';

const RowDescriptionCell = props => {
  const { name, hasVisibilityAction } = props;
  const { hideRow } = useStoreActions(showcaseTableSelector);

  const { t } = useTranslation();

  const renderHideAction = () =>
    hasVisibilityAction && (
      <div className="c-app-showcase__toggles">
        <Action
          onClick={() => {
            hideRow(name);
          }}
          type="raw"
          modifier="c-action--slender c-action--transparent u-color-grey-60"
          label={<Icon name="icn-hide-24" size={20} />}
        />
      </div>
    );

  const renderDragIcon = () =>
    hasVisibilityAction && (
      <div className="c-app-showcase__toggles">
        <div className="c-app-showcase__drag">
          <Icon name="icn-grid-vertical-24" size={20} />
        </div>
      </div>
    );

  return (
    <td className="c-app-showcase__label">
      {renderDragIcon()}
      <strong>
        <Trans i18nKey={`showcase.tableRows.${name}`}>
          1<br />2<br />3
        </Trans>
      </strong>
      {renderHideAction()}
    </td>
  );
};

export default RowDescriptionCell;
