import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Action, FormField, FormFieldEvent } from '@reverse-hr/pattern-library';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { apiGenerateTokenPost } from '../../../api/session';

interface TPageLoginForm {
  onSubmit: () => void;
}

export const PageLoginForm: FC<TPageLoginForm> = ({ onSubmit }) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('', {
    keyPrefix: 'login.requireLink',
  });

  const isSubmitDisabled = isLoading || !!error;

  const isEmailValid = async (): Promise<boolean> => {
    try {
      await validationSchema.validate({ email });

      setError('');
      return true;
    } catch (_error) {
      setError(t('emailError'));
      return false;
    }
  };

  const onEmailChange = ({ value }: FormFieldEvent): void => {
    if (typeof value !== 'string') {
      return setEmail('');
    }

    setEmail(value);
  };

  const onSubmitClick = async (): Promise<void> => {
    setIsLoading(true);

    const isValid = await isEmailValid();

    if (!isValid) {
      return setIsLoading(false);
    }

    try {
      await apiGenerateTokenPost(email);
      onSubmit();
    } catch (response: unknown) {
      if (!isErrorResponse(response) || response.status !== 400) {
        return onSubmit();
      }

      setError(t('emailError'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (email) {
      isEmailValid();
    } else {
      setError('');
    }
  }, [email]);

  return (
    <>
      <FormField
        externalError={error}
        id="login-email"
        label={t('emailLabel')}
        onChange={onEmailChange}
        placeholder={t('emailPlaceholder') || ''}
        type="input"
        inputProps={{
          value: email,
        }}
      />

      <div className="c-page-login__submit">
        <Action
          disabled={isSubmitDisabled}
          label={t('submitCta') || ''}
          modifier="c-page-login__cta"
          onClick={onSubmitClick}
        />

        <p className="c-page-login__support">
          <Trans i18nKey="login.requireLink.support">
            0<a href="mailto:support@reverse.hr">1</a>
          </Trans>
        </p>
      </div>
    </>
  );
};

const validationSchema = yup.object({
  email: yup.string().email().required(),
});

const isErrorResponse = (error: unknown): error is Response => {
  if (!error || Array.isArray(error) || typeof error !== 'object') {
    return false;
  }

  return 'ok' in error && 'status' in error;
};
