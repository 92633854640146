import React from 'react';
import { Icon } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import { MotivationIcons } from '../../../../../constants/candidate';

const MotivationToChange = props => {
  const { candidateMotivationToChange } = props;
  const { t } = useTranslation();

  const renderMotivationToChange = () =>
    candidateMotivationToChange ? (
      <>
        <Icon name={MotivationIcons[candidateMotivationToChange]} />
        <p className="typo-text-caption u-color-primary-80 u-ml-space-8 u-txt-center capitalize">
          {t(
            `showcase.motivationToChangeValues.${candidateMotivationToChange}`,
          )}
        </p>
      </>
    ) : null;

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--center-aligned">
      <div className="c-app-showcase__motivation">
        {renderMotivationToChange()}
      </div>
    </td>
  );
};

export default MotivationToChange;
