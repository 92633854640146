export const SESSION_ERROR_TYPES = {
  UNAUTHORIZED: 'unauthorized',
  UNAUTHORIZED_HOMEPAGE: 'unauthorizedHomepage',
};

export const USER_TYPES_MAP = {
  BASE: 'base',
  CANDIDATE: 'candidate',
  PARTNER: 'partner',
  MANAGER: 'manager',
  CUSTOMER: 'customer',
  CUSTOMER_GUEST: 'customer_guest',
};

export const USER_TYPE_LIST = Object.values(USER_TYPES_MAP);
