import React from 'react';
import ContentLoader from 'react-content-loader';
import { Icon } from '@reverse-hr/pattern-library';

const Picto = ({ icon, width }) => {
  return (
    <div className="c-pictogram">
      <div className="c-pictogram__decorator">
        <Icon name={icon} />
      </div>
      <p className="c-pictogram__text">
        <ContentLoader viewBox={`0 0 ${width} 20`} width={width} height="20">
          <rect
            x="0"
            y="0"
            rx="2"
            ry="2"
            width={width.toString()}
            height="20"
          />
        </ContentLoader>
      </p>
    </div>
  );
};

export default Picto;
