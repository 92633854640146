const REACT_APP_API_HOST_DEV = 'http://localhost:3000';
export const REACT_APP_API_HOST_PROD = 'https://app.reverse.hr';

const BASE_HOST = (() => {
  if (process.env.REACT_APP_API_HOST) return process.env.REACT_APP_API_HOST;
  if (process.env.NODE_ENV === 'production') return REACT_APP_API_HOST_PROD;
  return REACT_APP_API_HOST_DEV;
})();

const API_ENDPOINT = `${BASE_HOST}/api`;

const ENDPOINTS = {
  closing: '/employer_view/closing',
  success_fee: '/employer_view/success_fee',
  share: '/employer_view/deals/{id}/shares',
  jwt: '/v1/users/jwt',
  token_refresh: '/v1/users/token/refresh',
  test: '/test',
};

export { ENDPOINTS, API_ENDPOINT, BASE_HOST };
