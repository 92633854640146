import { useTable, useAbsoluteLayout, useColumnOrder } from 'react-table';
import TableHeader from './header';
import TableBody from './body';

const Table = ({ filteredColumns, filteredRows }) => {
  const filteredRowsCells = filteredRows?.map(row => row.cells);
  const tableInstance = useTable(
    {
      columns: filteredColumns || [],
      data: filteredRowsCells || [],
    },
    useColumnOrder,
    useAbsoluteLayout,
  );

  const { getTableProps, rows } = tableInstance;

  return (
    <table {...getTableProps()}>
      <TableHeader {...tableInstance} columns={filteredColumns} />
      <TableBody {...tableInstance} rows={rows} filteredRows={filteredRows} />
    </table>
  );
};

export default Table;
