import ContentLoader from 'react-content-loader';
import { useHomepageRouteMatch } from '../utils/hooks/use-homepage-route-match';

export const HomepageLoader = () => {
  const { isHomepageJobsPage } = useHomepageRouteMatch();

  return (
    <div className="container" data-testid="homepage-loader">
      <HeaderLoader />
      {isHomepageJobsPage ? <JobsTabLoader /> : <CandidatesTabLoader />}
    </div>
  );
};

const HeaderLoader = () => (
  <div data-testid="homepage-loader-header">
    <div className="row">
      <div className="col col--x-padded u-py-space-16 u-flex u-flex-item-center o-antipodes">
        <ContentLoader width="125" height="24" className="o-antipodes__item">
          <rect width="125" height="24" />
        </ContentLoader>

        <ContentLoader width="196" height="54" className="o-antipodes__item">
          <rect width="90" height="54" x="0" />
          <rect width="90" height="54" x="106" />
        </ContentLoader>
      </div>
    </div>

    <div className="row">
      <div className="col col--x-padded u-py-space-24 u-mb-space-8">
        <ContentLoader width="370" height="38">
          <>
            <rect width="160" height="38" rx="19" ry="19" y="0" x="0" />
            <rect width="110" height="38" rx="19" ry="19" y="0" x="170" />
            <rect width="80" height="38" rx="19" ry="19" y="0" x="290" />
          </>
        </ContentLoader>
      </div>
    </div>

    <div className="row">
      <div className="col col--x-padded u-mt-space-24">
        <ContentLoader width="364" height="35">
          <>
            <rect width="180" height="35" x="0" />
            <rect width="180" height="35" x="184" />
          </>
        </ContentLoader>
      </div>
    </div>
  </div>
);

const JobsTabLoader = () => (
  <div data-testid="homepage-loader-jobs">
    <div className="row">
      <div className="col col--x-padded row u-mt-space-80 u-mb-space-24">
        <ContentLoader width="520" height="32">
          <>
            <rect width="60" height="32" rx="16" ry="16" y="0" x="0" />
            <rect width="30" height="32" rx="16" ry="16" y="0" x="70" />
            <rect width="180" height="32" rx="16" ry="16" y="0" x="110" />
            <rect width="70" height="32" rx="16" ry="16" y="0" x="300" />
            <rect width="140" height="32" rx="16" ry="16" y="0" x="380" />
          </>
        </ContentLoader>
      </div>
    </div>

    <div className="row">
      <div className="col col--x-padded">
        <ContentLoader width="100%" height="16">
          <rect x="0" y="0" rx="8" ry="8" width="120" height="16" />
          <rect x="130" y="0" rx="8" ry="8" width="80" height="16" />
          <rect x="220" y="0" rx="8" ry="8" width="40" height="16" />
          <rect x="270" y="0" rx="8" ry="8" width="100" height="16" />
          <rect x="380" y="0" rx="8" ry="8" width="80" height="16" />
        </ContentLoader>
      </div>
    </div>

    <div className="row">
      <div className="col col--x-padded c-job-card-list__list">
        {new Array(6).fill('').map((_item, cardKey) => (
          <div key={cardKey}>
            <ContentLoader width="100%" height="148">
              <rect x="0" y="0" height="148" width="100%" />
            </ContentLoader>

            <div className="u-px-space-16 u-py-space-16">
              <ContentLoader height="32" width="100%">
                <rect x="0" y="0" height="32" width="32" rx="16" ry="16" />
                <rect x="48" y="8" rx="8" ry="8" width="80" height="16" />
              </ContentLoader>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const CandidatesTabLoader = () => (
  <div data-testid="homepage-loader-candidates">
    <div className="row">
      <div className="col col--x-padded u-mt-space-40 u-flex u-flex-item-center">
        <div style={{ width: 850 }}></div>

        <ContentLoader width="70" height="16">
          <rect width="70" height="16" />
        </ContentLoader>

        <div style={{ width: 24 }}></div>

        <ContentLoader width="250" height="40">
          <rect width="250" height="40" />
        </ContentLoader>
      </div>
    </div>

    <div className="row">
      <div className="col col--x-padded u-mt-space-48 u-mb-space-24">
        <ContentLoader width="410" height="32">
          <>
            <rect width="180" height="32" rx="16" ry="16" y="0" x="0" />
            <rect width="70" height="32" rx="16" ry="16" y="0" x="190" />
            <rect width="140" height="32" rx="16" ry="16" y="0" x="270" />
          </>
        </ContentLoader>
      </div>
    </div>

    <div className="row">
      <div className="col col--x-padded">
        <ContentLoader width="100%" height="176">
          <rect x="0" y="0" rx="8" ry="8" width="410" height="16" />
          <rect x="420" y="0" rx="8" ry="8" width="320" height="16" />
          <rect x="750" y="0" rx="8" ry="8" width="160" height="16" />
          <rect x="0" y="32" rx="8" ry="8" width="380" height="16" />
          <rect x="390" y="32" rx="8" ry="8" width="120" height="16" />
          <rect x="520" y="32" rx="8" ry="8" width="240" height="16" />
          <rect x="0" y="96" rx="8" ry="8" width="360" height="16" />
          <rect x="370" y="96" rx="8" ry="8" width="180" height="16" />
          <rect x="560" y="96" rx="8" ry="8" width="260" height="16" />
          <rect x="0" y="128" rx="8" ry="8" width="240" height="16" />
          <rect x="250" y="128" rx="8" ry="8" width="160" height="16" />
          <rect x="420" y="128" rx="8" ry="8" width="420" height="16" />
          <rect x="0" y="160" rx="8" ry="8" width="180" height="16" />
          <rect x="190" y="160" rx="8" ry="8" width="80" height="16" />
        </ContentLoader>
      </div>
    </div>
  </div>
);
