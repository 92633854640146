import { action, thunk } from 'easy-peasy';
import { utilityGetRequestQueryParams } from '../utils/api-service';

export const ModelCustomer = {
  id: null,
  companyContactPersonName: null,
  companyContactPersonEmail: null,
  companyName: null,

  thunkFetchCustomerJobs: thunk(
    async (_actions, customerId, { getStoreActions }) => {
      const {
        session: { authFetch },
      } = getStoreActions();

      const queryParams = utilityGetRequestQueryParams({
        itemsPerPage: 0,
      });

      const { results } = await authFetch({
        endpoint: `/v1/employer-view/customers/${
          customerId ?? 'me'
        }/jobs${queryParams}`,
        skipAuthChecks: true,
      });

      return results;
    },
  ),

  thunkFetchCustomerApplications: thunk(
    async (
      _actions,
      {
        stateCategory,
        jobId,
        currentPage,
        sortBy,
        sortDirection,
        state,
        customerId,
      },
      { getStoreActions },
    ) => {
      const {
        session: { authFetch },
      } = getStoreActions();

      const queryParams = utilityGetRequestQueryParams({
        itemsPerPage: 10,
        currentPage,
        jobId,
        sortBy,
        sortDirection,
        state,
      });

      const response = await authFetch({
        endpoint: `/v1/employer-view/customers/${
          customerId ?? 'me'
        }/eligible-applications/${stateCategory}${queryParams}`,
      });

      return response;
    },
  ),

  thunkFetchCustomerUserData: thunk(
    async (actions, customerId, { getStoreActions }) => {
      const {
        session: { authFetch },
      } = getStoreActions();

      const response = await authFetch({
        endpoint: `/v1/employer-view/customers/${customerId ?? 'me'}`,
      });

      actions.setCustomer(response);
    },
  ),

  setCustomer: action((state, customerResponse) => {
    state.id = customerResponse.id;
    state.companyContactPersonName = customerResponse.companyContactPersonName;

    state.companyContactPersonEmail =
      customerResponse.companyContactPersonEmail;

    state.companyName = customerResponse.companyName;
  }),
};

export const selectorCustomer = state => state.customer;
export const selectorCustomerActions = actions => actions.customer;
