export const ERRORS_ES = {
  employer_view: {
    eligible_application: {
      property: {
        customer_interest_notes: {
          too_long:
            'La motivación que has introducido es demasiado larga, por favor introduce un texto más corto.',
        },
      },
    },
  },
};
