export const CANDIDATE_DETAILS_FR = {
  feedback: {
    bubble:
      'Que pensez-vous de {{ candidateFullName }}? <strong>Est-il/elle intéressant(e)?</strong>',
    bubble_interested:
      '<strong>{{ candidateFullName }}</strong> vous intéresse',
    bubble_notInterested:
      '<strong>{{ candidateFullName }}</strong> ne vous intéresse pas',
    bubble_discarded:
      '<strong>{{ candidateFullName }}</strong> ne continue pas.',
    actionYes: 'Oui',
    actionNo: 'Non',
    actionChangeFeedback: "As-tu changé d'avis?",
    interesting: "C'est intéressant",
  },
  intro: {
    editLink: 'Modifier la candidature',
  },
  dossier: {
    title: 'Curriculum vitae',
    subtitle: "Entretien d'embauche",
    button: 'Télécharger le dossier',
  },
  scout: {
    accordionOpen: "Plus d'infos",
    accordionClose: 'Fermer',
    title: 'Rapport talent scout',
    accordionTitle_skillsAssessment: 'Évaluation des compétences',
    accordionTitle_overallAssessment: 'Résumé du rôle',
    button: 'Télécharger le rapport',
  },
  negativeFeedback: {
    title: "Pourquoi n'est-il/elle pas intéressant(e)?",
    subtitle:
      'Savoir pourquoi vous ne trouvez pas ce candidat adapté au poste (par exemple, compétences, ancienneté, etc.) nous aide à ne présenter que des candidats parfaitement en adéquation avec vos attentes.',
    notesLabel: 'Écrivez la motivation:',
    notesPlaceholder:
      'La motivation sera envoyée au/ à la chargé.e de recrutement qui gère la sélection.',
    submit: 'Envoyer',
    cancel: 'Annuler',
    successTitle: 'Message envoyé:',
    successSubtitle:
      'Nous avons transmis votre évaluation au/ à la chargé.e de recrutement qui gère la recherche.',
    successClose: "D'accord, merci.",
  },
};
