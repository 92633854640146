import { useStoreActions, useStoreState } from 'easy-peasy';
import { selectorRootState } from '../../model';
import { MODAL_IDS_MAP } from '../../constants/modals';

const useShareModal = () => {
  const { isModalOpened } = useStoreState(selectorRootState);
  const { setModalId, setModalOpened } = useStoreActions(actions => actions);

  const handleShareButtonClick = () => {
    if (isModalOpened) return null;

    setModalId(MODAL_IDS_MAP.SHARE);
    setModalOpened(true);
  };

  return { handleShareButtonClick };
};

export default useShareModal;
