import PropTypes from 'prop-types';
import { Action, Alert } from '@reverse-hr/pattern-library';
import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const DownloadFileButton = ({ className, label, onClick }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchFailed, setIsFetchFailed] = useState(null);

  const wrapperClassNames = classNames('c-download-file-button', className, {
    'c-download-file-button--loading': isLoading,
  });

  const onDownloadClick = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setIsFetchFailed(false);

    try {
      const { filename, url } = await onClick();
      const virtualLink = document.createElement('a');

      virtualLink.href = url;
      virtualLink.download = filename;
      virtualLink.click();
    } catch (error) {
      setIsFetchFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={wrapperClassNames}>
      <Action
        type="simple-text"
        label={label}
        icon={isLoading ? 'icn-refresh-24' : 'icn-download-24'}
        onClick={onDownloadClick}
      />

      {isFetchFailed && (
        <Alert
          type="negative"
          icon="icn-warning-outline-24"
          closable
          modifier="c-alert--fixed c-download-file-button__alert"
        >
          {t('general.error.title')}
        </Alert>
      )}
    </div>
  );
};

DownloadFileButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
