import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useStoreActions } from 'easy-peasy';
import { showcaseTableSelector } from '../../../../../../store/selectors/showcase-table';
import classNames from 'classnames';
import { useState } from 'react';

const TableBody = ({ getTableBodyProps, prepareRow, rows, filteredRows }) => {
  const { setRowsOrder } = useStoreActions(showcaseTableSelector);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);

  const handleDragStart = event => {
    setDraggedRowIndex(String(event.source.index));
  };

  const handleDragEnd = event => {
    setDraggedRowIndex(null);

    const startPosition = event.source.index;
    const landingPosition = event?.destination?.index;
    if (!startPosition || !landingPosition) return;

    setRowsOrder({
      startPositionId: filteredRows[startPosition]?.id,
      landingPositionId: filteredRows[landingPosition]?.id,
    });
  };

  const renderDraggableRow = row => (
    <Draggable draggableId={row.id} key={row.id} index={row.index}>
      {draggable => {
        const rowClassNames = classNames('c-app-showcase__row', {
          'c-app-showcase__row--dragged': draggedRowIndex === row.id,
        });

        return (
          <tr
            {...row.getRowProps()}
            {...draggable.draggableProps}
            {...draggable.dragHandleProps}
            key={row.id}
            ref={draggable.innerRef}
            className={rowClassNames}
          >
            {row.cells.map(cell => cell.render('Cell'))}
          </tr>
        );
      }}
    </Draggable>
  );

  const renderHeadingRow = row => (
    <tr
      {...row.getRowProps()}
      key={row.id}
      className="c-app-showcase__row c-app-showcase__row--second"
      style={{}}
    >
      {row.cells.map(cell => cell.render('Cell'))}
    </tr>
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <Droppable droppableId="table-body" direction="vertical">
        {droppable => (
          <tbody
            {...getTableBodyProps()}
            {...droppable.droppableProps}
            ref={droppable.innerRef}
          >
            {rows.map(row => {
              prepareRow(row);
              return row.index === 0
                ? renderHeadingRow(row)
                : renderDraggableRow(row);
            })}

            {droppable.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TableBody;
