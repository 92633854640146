import React from 'react';
import ContentLoader from 'react-content-loader';

const Search = ({ status }) => {
  return status === 'done' ? (
    <ContentLoader viewBox="0 0 500 600">
      <rect x="0" y="10" width="80" height="12" />
      <rect x="0" y="30" width="120" height="80" />
      <rect x="127" y="30" width="120" height="80" />
      <rect x="254" y="30" width="120" height="80" />
      <rect x="380" y="30" width="120" height="70" />
      <rect x="0" y="120" width="374" height="80" />
      <rect x="380" y="110" width="120" height="100" />
    </ContentLoader>
  ) : (
    <ContentLoader viewBox="0 0 500 600">
      <rect x="0" y="10" width="80" height="12" />
      <rect x="0" y="30" width="220" height="8" />
      <rect x="0" y="42" width="200" height="8" />

      <rect x="0" y="80" width="20" height="6" />
      <rect x="0" y="90" width="40" height="60" />
      <rect x="45" y="110" width="60" height="15" />
      <rect x="0" y="155" width="130" height="12" />
      <rect x="0" y="170" width="100" height="12" />

      <rect x="350" y="70" width="80" height="90" />
    </ContentLoader>
  );
};

export default Search;
