import React from 'react';

const TableList = ({ modifier, rows, columns }) => {
  return (
    <div className={`c-app-table-list ${modifier}`}>
      {rows.map((row, index) => {
        return (
          <div
            key={`table-list-row-${index}`}
            className="c-app-table-list__row"
          >
            {columns.map((col, colIndex) => {
              return (
                <div
                  key={`table-list-row-${index}-col-${colIndex}`}
                  className={`c-app-table-list__col ${
                    colIndex === columns.length - 1
                      ? 'c-app-table-list__last-small'
                      : ''
                  }`}
                >
                  {row[col].children ? (
                    row[col].children
                  ) : (
                    <p className="col-text">{row[col]}</p>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

TableList.defaultProps = {
  modifier: '',
};

export default TableList;
