import React from 'react';

const TextBlock = ({ modifier, title, body }) => {
  return (
    <div className={`c-app-intro-flow ${modifier}`}>
      {title ? <p className="c-app-intro-flow__title">{title}</p> : null}

      {body ? <p className="c-app-intro-flow__body">{body}</p> : null}
    </div>
  );
};

TextBlock.defaultProps = {
  modifier: '',
};

export default TextBlock;
