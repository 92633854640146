import { differenceInDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { AppCardTask } from '../AppCardTask';

export const AppTasksStack = ({ activities }) => {
  const stacks = getStacks(activities);
  const sortedStacksKeys = Object.keys(stacks).sort(sortStacksKeys);

  return (
    <>
      {sortedStacksKeys.map(stackDate => (
        <div className="c-app-tasks c-app-tasks--stack" key={stackDate}>
          <div className="row">
            <div className="col col--x-padded small-12 large-5">
              <AppTasksStackDate stackDate={stackDate} />
            </div>

            <div className="col col--x-padded small-12 large-7">
              {stacks[stackDate].map((task, taskIdx) => (
                <div className="c-app-tasks__item" key={taskIdx}>
                  <AppCardTask task={task} />
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const AppTasksStackDate = ({ stackDate }) => {
  const { t } = useTranslation();

  const daysPast = differenceInDays(new Date(), new Date(stackDate));

  return (
    <p className="c-app-tasks__date">
      {daysPast <= 6
        ? t('common.formatting.date.daysAgo', { count: daysPast })
        : t('common.formatting.date.dateFormat', {
            context: 'pretty',
            date: new Date(stackDate),
          })}
    </p>
  );
};

const getStacks = activities =>
  activities.reduce((stacks, activity) => {
    const dateKey = format(new Date(activity.dateTime), 'yyyy-MM-dd');

    if (!stacks[dateKey]) {
      stacks[dateKey] = [];
    }

    stacks[dateKey].push(activity);

    return stacks;
  }, {});

const sortStacksKeys = (a, b) => (a < b ? 1 : -1);
