import { useTranslation } from 'react-i18next';
import { Parser } from 'html-to-react';

export const useDrivingTime = drivingTimeInSeconds => {
  const { t } = useTranslation();
  const htmlParser = new Parser();

  if (!drivingTimeInSeconds) return;

  const hours = Math.floor(drivingTimeInSeconds / 3600);
  const minutes = Math.floor((drivingTimeInSeconds - hours * 3600) / 60);
  const outputStrings = [];

  if (hours > 0) outputStrings.push(t('showcase.drivingTime.hours', { hours }));
  if (minutes > 0)
    outputStrings.push(t('showcase.drivingTime.minutes', { minutes }));

  return <>{htmlParser.parse(outputStrings.join(' '))}</>;
};
