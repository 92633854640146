import React from 'react';
import { useStoreState } from 'easy-peasy';

import TheHeader from '../components/TheHeader';
import TheFooter from '../components/TheFooter';

import { header } from '../utils/default-values';
import useShareModal from '../utils/hooks/use-share-modal';

const SecondaryLayout = ({ children, customClasses = '' }) => {
  const headerLabels = header();

  const { handleShareButtonClick } = useShareModal();

  return (
    <main data-testid="secondary-layout">
      <div className="container">
        <div className="row">
          <div className="col col--x-padded u-py-space-16">
            <TheHeader labels={headerLabels} onClick={handleShareButtonClick} />
          </div>
        </div>
      </div>
      <div className={`c-secondary-layout ${customClasses}`}>{children}</div>
      <TheFooter />
    </main>
  );
};

export default SecondaryLayout;
