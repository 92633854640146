export const CUSTOMER_APPLICATIONS_STATE_CATEGORIES = {
  ACTIVE: 'active',
  HIRED: 'hired',
  STOPPED: 'stopped',
};

const CUSTOMER_APPLICATIONS_COLUMNS = {
  CANDIDATE_FIRST_NAME: 'candidateFirstName',
  JOB_TITLE: 'jobTitle',
  STATE: 'state',
  HIRE_DATE: 'hireDate',
  NUMBER_OF_CLIENT_INTERVIEWS: 'numberOfClientInterviews',
  LAST_UPDATE: 'lastUpdate',
  LATEST_INTERVIEW_AT: 'latestInterviewAt',
  MISSED_REASON: 'missedReason',
};

export const CUSTOMER_APPLICATION_STATES = {
  PRESENTED: 'presented',
  POSITIVE_CURRICULUM_FEEDBACK: 'positive_curriculum_feedback',
  INTERVIEW_SCHEDULED: 'interview_scheduled',
  INTERVIEW_DONE: 'interview_done',
  INTERVIEW_INTERESTED: 'interview_interested',
  INTERVIEW_MEET_AGAIN: 'interview_meet_again',
  OFFER_INCOMING: 'offer_incoming',
  OFFER_MADE: 'offer_made',
};

export const CUSTOMER_APPLICATION_STATES_LIST = Object.values(
  CUSTOMER_APPLICATION_STATES,
);

const CUSTOMER_APPLICATION_MISSED_REASONS = {
  REJECTED: 'rejected',
  WITHDRAWN: 'withdrawn',
  OFFER_REJECTED: 'offer_rejected',
};

export const CUSTOMER_APPLICATION_MISSED_REASON_LIST = Object.values(
  CUSTOMER_APPLICATION_MISSED_REASONS,
);

export const CUSTOMER_APPLICATIONS_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const CUSTOMER_APPLICATIONS_STATE_CATEGORY_TO_TABLE_COLUMNS_MAP = {
  [CUSTOMER_APPLICATIONS_STATE_CATEGORIES.ACTIVE]: [
    CUSTOMER_APPLICATIONS_COLUMNS.CANDIDATE_FIRST_NAME,
    CUSTOMER_APPLICATIONS_COLUMNS.JOB_TITLE,
    CUSTOMER_APPLICATIONS_COLUMNS.STATE,
    CUSTOMER_APPLICATIONS_COLUMNS.LAST_UPDATE,
    CUSTOMER_APPLICATIONS_COLUMNS.NUMBER_OF_CLIENT_INTERVIEWS,
    CUSTOMER_APPLICATIONS_COLUMNS.LATEST_INTERVIEW_AT,
  ],
  [CUSTOMER_APPLICATIONS_STATE_CATEGORIES.HIRED]: [
    CUSTOMER_APPLICATIONS_COLUMNS.CANDIDATE_FIRST_NAME,
    CUSTOMER_APPLICATIONS_COLUMNS.JOB_TITLE,
    CUSTOMER_APPLICATIONS_COLUMNS.HIRE_DATE,
  ],
  [CUSTOMER_APPLICATIONS_STATE_CATEGORIES.STOPPED]: [
    CUSTOMER_APPLICATIONS_COLUMNS.CANDIDATE_FIRST_NAME,
    CUSTOMER_APPLICATIONS_COLUMNS.JOB_TITLE,
    CUSTOMER_APPLICATIONS_COLUMNS.STATE,
    CUSTOMER_APPLICATIONS_COLUMNS.LAST_UPDATE,
    CUSTOMER_APPLICATIONS_COLUMNS.NUMBER_OF_CLIENT_INTERVIEWS,
    CUSTOMER_APPLICATIONS_COLUMNS.MISSED_REASON,
  ],
};

const CUSTOMER_APPLICATIONS_SORT_BY_KEYS = {
  CANDIDATE_NAME: 'candidate_name',
  HIRE_DATE: 'hire_date',
  LAST_UPDATE: 'last_update',
  LATEST_INTERVIEW_AT: 'latest_interview',
  MISSED_REASON: 'missed_reason',
  STATE: 'state',
  NUMBER_OF_CLIENT_INTERVIEWS: 'number_of_interviews',
};

export const CUSTOMER_APPLICATIONS_TABLE_COLUMN_TO_SORT_BY_KEYS = {
  [CUSTOMER_APPLICATIONS_COLUMNS.CANDIDATE_FIRST_NAME]:
    CUSTOMER_APPLICATIONS_SORT_BY_KEYS.CANDIDATE_NAME,
  [CUSTOMER_APPLICATIONS_COLUMNS.HIRE_DATE]:
    CUSTOMER_APPLICATIONS_SORT_BY_KEYS.HIRE_DATE,
  [CUSTOMER_APPLICATIONS_COLUMNS.LAST_UPDATE]:
    CUSTOMER_APPLICATIONS_SORT_BY_KEYS.LAST_UPDATE,
  [CUSTOMER_APPLICATIONS_COLUMNS.LATEST_INTERVIEW_AT]:
    CUSTOMER_APPLICATIONS_SORT_BY_KEYS.LATEST_INTERVIEW_AT,
  [CUSTOMER_APPLICATIONS_COLUMNS.MISSED_REASON]:
    CUSTOMER_APPLICATIONS_SORT_BY_KEYS.MISSED_REASON,
  [CUSTOMER_APPLICATIONS_COLUMNS.STATE]:
    CUSTOMER_APPLICATIONS_SORT_BY_KEYS.STATE,
  [CUSTOMER_APPLICATIONS_COLUMNS.NUMBER_OF_CLIENT_INTERVIEWS]:
    CUSTOMER_APPLICATIONS_SORT_BY_KEYS.NUMBER_OF_CLIENT_INTERVIEWS,
};
