export const I18N_LOCALES = {
  DE: 'de',
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  IT: 'it',
};

export const I18N_LOCALE_LIST = Object.values(I18N_LOCALES);
export const I18N_DEFAULT_LOCALE = I18N_LOCALES.IT;
