export const CLOSING_FR = {
  header: {
    title: 'Clôture',
    subtitle:
      'Excellent travail! Vous trouvez ici un résumé des frais et les documents nécessaires.',
  },
  bingoHeader: {
    label: 'Vous avez embauché',
    sublabel: '',
  },
  links: {
    resignation_letter: 'Lettre de démission',
    commitment_letter: "Promesse d'embauche",
    prospect: 'Voir calcul',
  },
  tag: {
    text: 'Embauché/e le {{hiring_date}}',
  },
};
