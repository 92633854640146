import React from 'react';
import { Pictogram } from '@reverse-hr/pattern-library';
import { BenefitIcons } from '../../../../../constants/candidate';
import { useTranslation } from 'react-i18next';

const Benefits = props => {
  const { candidateCurrentBenefits } = props;
  const { t } = useTranslation();

  const renderBenefit = benefit => {
    return (
      <Pictogram
        icon={BenefitIcons[benefit.type]}
        modifier="u-color-primary-80"
        size="small"
        key={benefit.type}
      >
        {t(`showcase.benefitsCategories.${benefit.type}`)}
        {benefit.details ? `: ${benefit.details}` : ''}
      </Pictogram>
    );
  };

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--left-aligned c-app-showcase__cell--top-aligned">
      {candidateCurrentBenefits.map(renderBenefit)}
    </td>
  );
};

export default Benefits;
