import classNames from 'classnames';
import { Accordion } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const AppCount = ({
  className,
  title,
  price,
  description,
  currency,
  isStruckOut,
}) => {
  const { t } = useTranslation();

  const wrapperClassNames = classNames('c-app-count', className, {
    'c-app-count--with-accordion': !!description,
  });

  const priceWrapperClassName = classNames('c-app-count__item-price', {
    'c-app-count__item-price--striked-out': isStruckOut,
  });

  return (
    <div className={wrapperClassNames}>
      <div className="c-app-count__core">
        <div className="c-app-count__item">
          <p className="c-app-count__item-name">{title}</p>

          <p className={priceWrapperClassName}>
            {t('common.formatting.price', { value: price, currency })}
          </p>
        </div>

        {!!description && (
          <div className="c-app-count__accordion">
            <Accordion
              id={title}
              title={t('successFee.moreInfo')}
              text={description}
              small
              light
              labels={{ open: '', close: '' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

AppCount.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.oneOf(['CHF', 'EUR', 'USD', 'GBP', 'PLN', 'TND'])
    .isRequired,
  className: PropTypes.string,
  description: PropTypes.element,
  isStruckOut: PropTypes.bool,
};
