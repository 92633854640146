export const Benefits = {
  CAR: 'car',
  CANTEEN: 'canteen',
  TRAINING: 'training',
  WELFARE: 'welfare',
  MEAL_VOUCHERS: 'meal_vouchers',
  INSURANCE: 'insurance',
  SMARTPHONE: 'smartphone',
  REMOTE_WORKING: 'remote_working',
  PC: 'personal_computer',
};

export const BenefitIcons = {
  [Benefits.CANTEEN]: 'icn-coffee-togo-outline-24',
  [Benefits.CAR]: 'icn-car-outline-24',
  [Benefits.TRAINING]: 'icn-group-alt-outline-24',
  [Benefits.WELFARE]: 'icn-home-heart-outline-24',
  [Benefits.MEAL_VOUCHERS]: 'icn-credit-card-outline-24',
  [Benefits.INSURANCE]: 'icn-user-heart-outline-24',
  [Benefits.SMARTPHONE]: 'icn-phone-outline-24',
  [Benefits.REMOTE_WORKING]: 'icn-home-outline-24',
  [Benefits.PC]: 'icn-laptop-outline-24',
};

export const MotivationValues = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const MotivationIcons = {
  [MotivationValues.HIGH]: 'icn-super-happy-24',
  [MotivationValues.LOW]: 'icn-confused-24',
  [MotivationValues.MEDIUM]: 'icn-happy-24',
};

export const CANDIDATE_EVENT_STATES = {
  CLOSING: 'closing',
  ELIGIBLES: 'eligibles',
  INTERVIEWING: 'interviewing',
  SCOUT: 'scout',
}

export const CANDIDATE_EVENT_SUBSTATES = {
  AVAILABLE: 'available',
  CURRICULUM_NOT_SEEN: 'curriculum_not_seen',
  CURRICULUM_SEEN: 'curriculum_seen',
  DONE: 'done',
  INTERESTING: 'interesting',
  OFFERED: 'offered',
  OUT: 'out',
  TO_BE_CREATED: 'to_be_created',
  WINNER: 'winner',
}
