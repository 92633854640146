import { DEAL_PAGE_STATES } from '../../constants/deal';
import Video from '../Video';
import { useStoreState } from 'easy-peasy';
import { selectorDeal } from '../../model/Deal';
import classNames from 'classnames';
import { selectorCustomer } from '../../model/Customer';
import { AppBanner } from '../AppBanner';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { PageStateContext } from '../Providers/PageStateProvider';
import { GTM } from '../../gtm';

export const SearchingSidebar = () => {
  const { t } = useTranslation();
  const deal = useStoreState(selectorDeal);
  const customer = useStoreState(selectorCustomer);
  const pageState = useContext(PageStateContext);

  const isPageStateDone = pageState === DEAL_PAGE_STATES.DONE;

  const wrapperClassNames = classNames('col col--x-padded small-12', {
    'col--y-padded large-3': isPageStateDone,
    'large-4': !isPageStateDone,
  });

  const sidebarElementClassNames = classNames('o-sidebar', {
    'o-sidebar--y-bottom': isPageStateDone,
    'o-sidebar--y-center': !isPageStateDone,
  });

  const handleDownloadJraClick = () => {
    const eventAction = 'Download JRA';

    GTM.trackGTMEvent({
      action: eventAction,
      label: `${customer.companyName} - ${deal.id} - ${deal.jobTitle}`,
    });

    GTM.trackGA4Event(
      eventAction,
      customer.companyName,
      `${deal.id} - ${deal.jobTitle}`,
    );
  };

  return (
    <div className={wrapperClassNames}>
      <div className={sidebarElementClassNames}>
        {pageState === DEAL_PAGE_STATES.TO_DO ? (
          <Video video="smartphone" sources={['webm']} />
        ) : null}

        {pageState === DEAL_PAGE_STATES.DOING ? (
          <img
            width="256"
            src="/employer_view/assets/images/website.png"
            alt=""
          />
        ) : null}

        {isPageStateDone && !!deal.jraUrl ? (
          <AppBanner
            isLayoutSkyscraper
            title={t('searching.jraCard.title')}
            body={t('searching.jraCard.subtitle')}
            ctaLabel={t('searching.jraCard.download')}
            ctaUrl={deal.jraUrl}
            onClick={handleDownloadJraClick}
          />
        ) : null}
      </div>
    </div>
  );
};
