import Video from '../Video';

export const ClosingSidebar = ({ hasClosings }) => (
  <div className="o-sidebar o-sidebar--y-center">
    {hasClosings ? (
      <Video video="cubes" sources={['webm']} />
    ) : (
      <img src="/employer_view/assets/images/cube.png" alt="cube" />
    )}
  </div>
);
