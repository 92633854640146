import SecondaryLayout from '../layouts/SecondaryLayout';
import {
  SuccessFeeHeader,
  SuccessFeePrepayments,
  SuccessFeeSidebar,
  SuccessFeeSummary,
  SuccessFeeTotal,
} from '../components/SuccessFee';
import { useStoreState } from 'easy-peasy';
import { selectorDeal } from '../model/Deal';
import { useParams } from 'react-router-dom';
import { selectorRootState } from '../model';
import { ShareLinkModal } from '../components/ShareLinkModal';
import Modal from '../components/Modal';
import { MODAL_IDS_MAP } from '../constants/modals';
import { selectorApplications } from '../model/Applications';
import { useTranslation } from 'react-i18next';

export const SuccessFee = () => {
  const { t } = useTranslation();
  const applications = useStoreState(selectorApplications);
  const { isModalOpened, modalId } = useStoreState(selectorRootState);
  const { applicationId } = useParams();

  const currentClosing = applications.find(
    application => application.id.toString() === applicationId,
  );

  const isShareLinkModalVisible =
    isModalOpened && modalId === MODAL_IDS_MAP.SHARE;

  return (
    <SecondaryLayout>
      <SuccessFeeHeader />

      {!!currentClosing && (
        <div className="container">
          <div className="row u-mt-space-32">
            <SuccessFeeSidebar
              candidateName={t('common.formatting.fullName', {
                firstName: currentClosing.candidateFirstName,
                lastName: currentClosing.candidateLastName,
              })}
            />

            <div className="col col--x-padded small-12 large-8">
              <SuccessFeeSummary
                prospect={currentClosing.offer.successFeeRecap}
              />

              <SuccessFeePrepayments
                prospect={currentClosing.offer.successFeeRecap}
              />

              <SuccessFeeTotal
                prospect={currentClosing.offer.successFeeRecap}
              />
            </div>
          </div>
        </div>
      )}

      <Modal visible={isShareLinkModalVisible}>
        <ShareLinkModal />
      </Modal>
    </SecondaryLayout>
  );
};
