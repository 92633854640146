export const INTERVIEWING_DE = {
  header: {
    title: 'Gespräche',
    subtitle:
      'Hier werden Sie die Liste mit den Bewerbern finden: Sie werden immer die Möglichkeit haben, den aktuellen Status zu sehen und Feedback zu geben, um den Bewerbungsprozess voranzubringen.',
    subtitle_bis:
      'Hier finden Sie die Liste der Kandidaten. Sie können jederzeit sehen, in welchem Stadium sie sich befinden und Feedback geben, um sie im Auswahlprozess voranzubringen.',
    alert:
      'Es befinden sich Personen in der finalen Phase. <strong>Beseitigen Sie schnell alle Unklarheiten</strong>, um diese nicht zu verlieren. ',
  },
  referenceHeader: {
    title: 'Ihre Ansprechpartner',
  },
  tabs: {
    see: 'Anzeigen',
    all: 'Alle',
    active: 'Im Prozess',
    discarded: 'Gestoppt',
  },
  eligibles: {
    curriculum_seen: 'CV lesen',
    curriculum_seen_text: 'Feedback geben',
    curriculum_not_seen: 'CV nicht angesehen',
    curriculum_not_seen_text: 'Lesen und Feedback geben',
    interesting: 'Interessant',
    not_interesting: 'Uninteressant',
    withdrawn: 'Zieht sich zurück',
    out: 'Fährt nicht fort',
  },
  scout: {
    to_be_created: 'Warten auf den Bericht',
    available: 'Bericht veröffentlicht',
    available_text: 'Bericht lesen',
    done: 'Bericht veröffentlicht',
    withdrawn: 'Zieht sich zurück',
    out: 'Fährt nicht fort',
  },
  interviewing: {
    to_be_created: 'Zu interviewen ',
    to_be_created_text: 'In Planung',
    incoming: 'Zu interviewen',
    incoming_text: 'Interview Dossier herunterladen',
    outcome: 'Interview abgeschlossen',
    outcome_text: 'Feedback geben',
    done: 'Interview abgeschlossen',
    withdrawn: 'Zieht sich zurück',
    out: 'Fährt nicht fort',
  },
  closing: {
    to_be_created: 'Ein Angebot machen',
    to_be_created_text: 'Ihn/Sie nicht verlieren!',
    offered: 'Angebot ausgesprochen',
    offered_text: 'Warten auf {{ name }}',
    winner: 'Winner',
    withdrawn: 'Zieht sich zurück',
    out: 'Fährt nicht fort',
    offer_declined: 'Angebot nicht angenommen',
  },
};
