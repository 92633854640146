import React, { useState } from 'react';
import { Action, Label, TextArea, Alert } from '@reverse-hr/pattern-library';
import { useStoreActions } from 'easy-peasy';
import { selectorRootActions } from '../../model';
import { useTranslation } from 'react-i18next';

export const NegativeFeedbackModal = ({ onSendNegativeFeedback }) => {
  const { t } = useTranslation();
  const { setModalOpened, setModalId } = useStoreActions(selectorRootActions);

  const handleCloseModal = () => {
    setModalOpened(false);
    setModalId(null);
  };

  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const handleTextareaChange = ({ target }) => {
    setNotes(target.value);
  };

  const handleSubmit = async () => {
    setAlertError(null);
    setIsLoading(true);

    try {
      await onSendNegativeFeedback(notes);
      setIsFeedbackSent(true);
    } catch (error) {
      setAlertError(
        t(error.message || 'general.error.title', t('general.error.title')),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitButtonDisabled = !notes || isLoading;

  const title = isFeedbackSent
    ? t('candidateDetails.negativeFeedback.successTitle')
    : t('candidateDetails.negativeFeedback.title');

  const subtitle = isFeedbackSent
    ? t('candidateDetails.negativeFeedback.successSubtitle')
    : t('candidateDetails.negativeFeedback.subtitle');

  return (
    <div className="c-app-negative-feedback">
      <div className="c-app-negative-feedback__top-section">
        <div className="c-app-negative-feedback__close">
          <Action
            iconOnly
            size="small"
            icon="icn-close-big-24"
            type="outline"
            onClick={handleCloseModal}
          />
        </div>

        <div className="c-app-negative-feedback__title">{title}</div>
        <div className="c-app-negative-feedback__subtitle">{subtitle}</div>
      </div>

      {isFeedbackSent ? null : (
        <div className="c-form-field">
          <div className="c-form-field__label">
            <Label
              label={t('candidateDetails.negativeFeedback.notesLabel')}
              required
            />
          </div>
          <TextArea
            value={notes}
            onChange={handleTextareaChange}
            placeholder={t(
              'candidateDetails.negativeFeedback.notesPlaceholder',
            )}
          />
        </div>
      )}

      <div className="c-app-negative-feedback__actions">
        {isFeedbackSent ? (
          <Action
            label={t('candidateDetails.negativeFeedback.successClose')}
            modifier="c-app-negative-feedback__action"
            onClick={handleCloseModal}
          />
        ) : (
          <>
            <Action
              type="outline"
              label={t('candidateDetails.negativeFeedback.cancel')}
              modifier="c-app-negative-feedback__action"
              onClick={handleCloseModal}
            />
            <Action
              label={t('candidateDetails.negativeFeedback.submit')}
              modifier="c-app-negative-feedback__action"
              disabled={isSubmitButtonDisabled}
              onClick={handleSubmit}
            />
          </>
        )}
      </div>

      {alertError && (
        <Alert
          type="negative"
          icon="icn-warning-outline-24"
          closable
          modifier="c-alert--fixed"
          onClose={() => setAlertError(null)}
        >
          {alertError}
        </Alert>
      )}
    </div>
  );
};
