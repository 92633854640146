import { action, thunk } from 'easy-peasy';
import { utilityGetErrorInstance } from '../utils/errors';

const candidate = {
  // state
  loading: true,
  error: null,
  candidate: {},
  feedbacks: null,
  feedbacksLoading: true,
  feedbacksError: null,
  interestLoading: true,
  interestError: null,
  applicationId: null,
  // action
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setCandidate: action((state, payload) => {
    state.candidate = payload;
  }),
  setFeedbacksLoading: action((state, payload) => {
    state.feedbacksLoading = payload;
  }),
  setFeedbacksError: action((state, payload) => {
    state.feedbacksError = payload;
  }),
  setInterestLoading: action((state, payload) => {
    state.interestLoading = payload;
  }),
  setInterestError: action((state, payload) => {
    state.interestError = payload;
  }),
  setFeedbacks: action((state, payload) => {
    state.feedbacks = payload;
  }),
  setApplicationId: action((state, payload) => {
    state.applicationId = payload;
  }),
  // thunk
  fetchCandidate: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const {
        session: { authFetch },
      } = getStoreActions();
      const { deal } = getStoreState();

      actions.setLoading(true);
      actions.setError(null);
      actions.setCandidate({});
      actions.setApplicationId(payload);

      try {
        const { applicationId } = payload;

        const candidate = await authFetch({
          endpoint: `/v1/employer-view/deals/${deal.id}/eligible-applications/${applicationId}`,
        });

        if (!!candidate) {
          actions.setCandidate(candidate);
        }
      } catch (error) {
        actions.setError(
          utilityGetErrorInstance(
            error,
            'Candidate model failed to fetch a candidate.',
          ),
        );
      } finally {
        actions.setLoading(false);
      }
    },
  ),

  thunkFetchFeedbacks: thunk(
    async (actions, applicationId, { getStoreActions, getStoreState }) => {
      const {
        session: { authFetch },
      } = getStoreActions();

      const { deal } = getStoreState();

      actions.setFeedbacksLoading(true);
      actions.setFeedbacksError(null);
      actions.setFeedbacks(null);

      try {
        const feedbacks = await authFetch({
          endpoint: `/v1/employer-view/deals/${deal.id}/eligible-applications/${applicationId}/feedback-messages`,
        });

        actions.setFeedbacks(feedbacks.feedbackMessages);
      } catch (error) {
        actions.setFeedbacksError(
          utilityGetErrorInstance(
            error,
            "Candidate model failed to fetch candidate's feedback messages.",
          ),
        );
      } finally {
        actions.setFeedbacksLoading(false);
      }
    },
  ),

  thunkPostFeedbackMessage: thunk(
    async (
      actions,
      { applicationId, message },
      { getStoreActions, getStoreState },
    ) => {
      const {
        session: { authFetch },
      } = getStoreActions();

      const { deal } = getStoreState();

      actions.setFeedbacksLoading(true);
      actions.setFeedbacksError(false);

      try {
        await authFetch({
          endpoint: `/v1/employer-view/deals/${deal.id}/eligible-applications/${applicationId}/feedback-messages`,
          requestOptions: { method: 'POST', body: JSON.stringify({ message }) },
        });
      } catch (error) {
        actions.setFeedbacksError(
          utilityGetErrorInstance(
            error,
            'Candidate model failed to post feedback message for a candidate.',
          ),
        );
      } finally {
        actions.setFeedbacksLoading(false);
      }
    },
  ),

  fetchPdfDossier: thunk(
    async (actions, { applicationId }, { getStoreActions, getStoreState }) => {
      const {
        session: { authFetchPdf },
      } = getStoreActions();

      const { deal } = getStoreState();

      try {
        const { filename, blob } = await authFetchPdf({
          endpoint: `/v1/employer-view/deals/${deal.id}/eligible-applications/${applicationId}/dossier/download`,
          requestOptions: {
            headers: {
              'Content-Type': 'application/pdf',
            },
          },
        });

        const url = URL.createObjectURL(blob);

        if (!filename || !url) {
          return Promise.reject();
        }

        return { filename, url };
      } catch (error) {
        return Promise.reject(
          utilityGetErrorInstance(
            error,
            'Candidate model failed to download dossier of an application.',
          ),
        );
      }
    },
  ),

  fetchPdfReport: thunk(
    async (actions, { applicationId }, { getStoreActions, getStoreState }) => {
      const {
        session: { authFetchPdf },
      } = getStoreActions();

      const { deal } = getStoreState();

      try {
        const { filename, blob } = await authFetchPdf({
          endpoint: `/v1/employer-view/deals/${deal.id}/eligible-applications/${applicationId}/scout-report/download`,
          requestOptions: {
            headers: {
              'Content-Type': 'application/pdf',
            },
          },
        });

        const url = URL.createObjectURL(blob);

        if (!filename || !url) {
          return Promise.reject();
        }

        return { filename, url };
      } catch (error) {
        return Promise.reject(
          utilityGetErrorInstance(
            error,
            'Candidate model failed to download scout report of an application.',
          ),
        );
      }
    },
  ),

  sendInterestFeedback: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const {
        session: { authFetch },
      } = getStoreActions();
      const { deal } = getStoreState();

      return new Promise(async (resolve, reject) => {
        actions.setInterestLoading(true);
        actions.setInterestError(false);

        try {
          const { applicationId, isCustomerInterested, customerInterestNotes } =
            payload;

          const interest = await authFetch({
            endpoint: `/v1/employer-view/deals/${deal.id}/eligible-applications/${applicationId}/customer-interest`,
            requestOptions: {
              method: 'PUT',
              body: JSON.stringify({
                isCustomerInterested,
                customerInterestNotes,
              }),
            },
          });

          if (interest) {
            resolve(interest);
          }
        } catch (error) {
          console.warn(error);
          actions.setInterestError(true);
          reject(error);
        } finally {
          actions.setInterestLoading(false);
        }
      });
    },
  ),
};

export default candidate;
