export const CLOSING_DE = {
  header: {
    title: 'Closing',
    subtitle:
      'Gute Arbeit! Eine Übersicht über die Kosten und notwendigen Dokumente finden Sie hier.',
  },
  bingoHeader: {
    label: 'Sie haben',
    sublabel: 'eingestellt',
  },
  links: {
    resignation_letter: 'Das Kündigungsschreiben',
    commitment_letter: 'Das Anstellungsanschreiben ',
    prospect: 'Siehe Berechnung',
  },
  tag: {
    text: 'Eingestellt am {{hiring_date}}',
  },
};
