import React from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadFileButton } from '../DownloadFileButton';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Curriculum from '../Curriculum';
import { GTM } from '../../gtm';
import { selectorDeal } from '../../model/Deal';
import { selectorCustomer } from '../../model/Customer';
import PropTypes from 'prop-types';

export const CandidateDossier = ({
  candidateSummary,
  candidateCurriculumUrl,
  candidateSummaryDetails,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'candidateDetails.dossier',
  });

  const { application_id: applicationId } = useParams();
  const { id: dealId } = useStoreState(selectorDeal);
  const { companyName } = useStoreState(selectorCustomer);
  const { fetchPdfDossier } = useStoreActions(actions => actions.candidate);

  const onDownloadDossierClick = async () => {
    try {
      return await fetchPdfDossier({ applicationId });
    } finally {
      GTM.trackGTMEvent({
        action: 'dossier',
        label: `${dealId} - ${applicationId} - download`,
      });

      GTM.trackGA4Event(
        'dossier',
        companyName,
        `${dealId} - ${applicationId} - download`,
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col col--x-padded u-mt-space-40">
          <div className="o-antipodes u-py-space-32">
            <p className="o-antipodes__item typo-secondary-heading-05 u-color-primary-100">
              {t('title')}
            </p>

            <DownloadFileButton
              className="o-antipodes__item"
              onClick={onDownloadDossierClick}
              label={t('button')}
            />
          </div>

          <Curriculum
            modifier="c-curriculum--in-page"
            summary={candidateSummary}
            curriculum={candidateCurriculumUrl}
            details={candidateSummaryDetails}
          />
        </div>
      </div>
    </div>
  );
};

CandidateDossier.propTypes = {
  candidateSummary: PropTypes.string,
  candidateCurriculumUrl: PropTypes.string,
  candidateSummaryDetails: PropTypes.object,
};
