export const INTERVIEWING_EN = {
  header: {
    title: 'Interviews',
    subtitle:
      'Here there will be the list of candidates. You will always be able to check their current status and give feedback to make them move forward in the selection process.',
    subtitle_bis:
      'Here you will find the list of candidates. You can always see what stage they are at and give feedback to advance them in the selection process.',
    alert:
      "There are people in the final phase. <strong>Resolve doubts quickly</strong> if you don't want to miss them.",
  },
  referenceHeader: {
    title: 'Your contacts',
  },
  tabs: {
    see: 'See',
    all: 'All',
    active: 'In process',
    discarded: 'Do not proceed',
  },
  eligibles: {
    curriculum_seen: 'CV seen',
    curriculum_seen_text: 'Leave a feedback',
    curriculum_not_seen: 'CV not seen',
    curriculum_not_seen_text: 'Read and leave a feedback',
    interesting: 'Interesting',
    not_interesting: 'Not interesting',
    withdrawn: 'Application withdrawn',
    out: "Won't proceed",
  },
  scout: {
    to_be_created: 'Waiting for the report',
    available: 'Report published',
    available_text: 'Read report',
    done: 'Report published',
    withdrawn: 'Application withdrawn',
    out: "Won't proceed",
  },
  interviewing: {
    to_be_created: 'To interview',
    to_be_created_text: 'Being organized',
    incoming: 'To interview',
    incoming_text: "Download interview's dossier",
    outcome: 'Interviewed',
    outcome_text: 'Leave a feedback',
    done: 'Interviewed',
    withdrawn: 'Application withdrawn',
    out: "Won't proceed",
  },
  closing: {
    to_be_created: 'Make an offer',
    to_be_created_text: "Let's not lose him/her!",
    offered: 'Offered!',
    offered_text: 'waiting for {{ name }}',
    winner: 'Winner',
    withdrawn: 'Application withdrawn',
    out: "Won't proceed",
    offer_declined: 'Offer declined',
  },
};
