import React from 'react';
import { useStoreState } from 'easy-peasy';
import { selectorDeal } from '../model/Deal';
import { utilityGetPageState } from '../utils/get-page-state';
import { DEAL_PAGE_STATES, DEAL_PROGRESS_STATES } from '../constants/deal';
import { PageStateProvider } from '../components/Providers/PageStateProvider';
import {
  InterviewingHeader,
  InterviewingHorsejogs,
  InterviewingSidebar,
} from '../components/Interviewing';
import { Referents } from '../components/Referents';
import classNames from 'classnames';
import { selectorApplications } from '../model/Applications';

const Interviewing = () => {
  const deal = useStoreState(selectorDeal);
  const applications = useStoreState(selectorApplications);

  const pageState = utilityGetPageState({
    dealProgress: deal.progress,
    currentRoute: DEAL_PROGRESS_STATES.INTERVIEWING,
    hasApplications: applications.length > 0,
  });

  const isPageStateDoing = pageState === DEAL_PAGE_STATES.DOING;

  const pageBodyClassNames = classNames('container container--full', {
    'u-mt-space-80': isPageStateDoing,
  });

  const horsejogsWrapperClassNames = classNames('col', {
    'u-mt-space-48': pageState === DEAL_PAGE_STATES.DONE,
  });

  return (
    <PageStateProvider value={pageState}>
      <section data-testid="interviewing-page">
        <div className="container">
          <div className="row">
            <InterviewingHeader />
            <InterviewingSidebar />
          </div>
        </div>

        {pageState !== DEAL_PAGE_STATES.TO_DO ? (
          <>
            <div className={pageBodyClassNames}>
              <div className="row">
                <div className={horsejogsWrapperClassNames}>
                  <InterviewingHorsejogs />
                </div>
              </div>
            </div>
          </>
        ) : null}

        {isPageStateDoing ? (
          <div className="container">
            <div className="row">
              <div className="col col--x-padded small-12 large-6">
                <Referents />
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </PageStateProvider>
  );
};

export default Interviewing;
