import { Link } from 'react-router-dom';
import { LinkWrapper } from '@reverse-hr/pattern-library';
import Masthead from '../Masthead';
import { useTranslation } from 'react-i18next';
import { selectorDeal } from '../../model/Deal';
import { useStoreState } from 'easy-peasy';

export const SuccessFeeHeader = () => {
  const { t } = useTranslation();
  const deal = useStoreState(selectorDeal);

  return (
    <Masthead full deal={deal} title={t('hero.prospect.title')}>
      {{
        left: (
          <div className="c-app-masthead__back">
            <div className="c-back">
              <Link
                to={`/${deal.id}/closing`}
                modifier="c-back__anchor"
                component={LinkWrapper}
                type="simple-text"
                label={t('successFee.backCta')}
                icon="icn-chevron-big-left-24"
                iconPosition="left"
              />
            </div>
          </div>
        ),
      }}
    </Masthead>
  );
};
