export const CANDIDATE_DETAILS_IT = {
  feedback: {
    bubble:
      "Che ne pensate di {{ candidateFullName }}? <strong>E' interessante?</strong>",
    bubble_interested: '<strong>{{ candidateFullName }}</strong> vi interessa',
    bubble_notInterested:
      '<strong>{{ candidateFullName }}</strong> non vi interessa',
    bubble_discarded: '<strong>{{ candidateFullName }}</strong> non prosegue.',
    actionYes: 'Sì',
    actionNo: 'No',
    actionChangeFeedback: 'Hai cambiato idea?',
    interesting: 'È interessante',
  },
  intro: {
    editLink: 'Modifica candidatura',
  },
  dossier: {
    title: 'Curriculum vitae',
    subtitle: 'Colloquio',
    button: 'Scarica il dossier',
  },
  scout: {
    accordionOpen: 'Apri',
    accordionClose: 'Chiudi',
    title: 'Report scout',
    accordionTitle_skillsAssessment: 'Valutazione delle competenze',
    accordionTitle_overallAssessment: 'Riassunto del ruolo',
    button: 'Scarica il report',
  },
  negativeFeedback: {
    title: 'Perché non è interessante?',
    subtitle:
      'Sapere perché non ritieni idoneo alla posizione questo candidato (es.skills, seniority etc) ci aiuta a presentarti solo candidati perfettamente in linea con le tue aspettative.',
    notesLabel: 'Scrivi la motivazione',
    notesPlaceholder:
      "La motivazione verrà inviata all'head hunter che segue la selezione",
    submit: 'Invia',
    cancel: 'Annulla',
    successTitle: 'Messaggio inviato',
    successSubtitle:
      "Abbiamo inviato la tua valutazione all'head hunter che segue la ricerca.",
    successClose: 'Ok, grazie',
  },
};
