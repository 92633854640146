export const SUCCESS_FEE_ESTIMATE_COUNTS_MAP = {
  SUCCESS_FEE_PERCENTAGE: 'successFeePercentage',
  ANNUAL_SALARY: 'annualSalary',
  RETAINER_FEE: 'retainerFee',
  CONFIDENCE_FEE: 'confidenceFee',
  MINIMUM_SUCCESS_FEE_AMOUNT: 'minimumSuccessFeeAmount',
  DISCOUNT_PERCENTAGE: 'discountPercentage',
  BONUS_BENEFIT: 'bonusBenefit',
  CAR_BENEFIT: 'carBenefit',
  HEALTH_INSURANCE_BENEFIT: 'healthInsuranceBenefit',
  ACCOMMODATION_BENEFIT: 'accommodationBenefit',
  STOCK_OPTIONS_BENEFIT: 'stockOptionsBenefit',
  OVERALL_FEE: 'overallFee',
};
