import {
  CUSTOMER_APPLICATIONS_STATE_CATEGORIES,
  CUSTOMER_APPLICATIONS_STATE_CATEGORY_TO_TABLE_COLUMNS_MAP,
  CUSTOMER_APPLICATIONS_TABLE_COLUMN_TO_SORT_BY_KEYS,
} from '../../constants/applications';
import { HomepageContext } from '../Providers/HomepageProvider';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTING_BASE_NAME } from '../../constants/navigation';
import { differenceInDays } from 'date-fns';

export const useTable = ({ stateCategory, applications }) => {
  const { t } = useTranslation();
  const { customerJobs } = useContext(HomepageContext);

  const tableColumns =
    CUSTOMER_APPLICATIONS_STATE_CATEGORY_TO_TABLE_COLUMNS_MAP[
      stateCategory
    ].map(columnHeader => ({
      name: columnHeader,
      content: t(`homepage.candidates.table.header.${columnHeader}`, {
        context: stateCategory,
      }),
      isSortable:
        !!CUSTOMER_APPLICATIONS_TABLE_COLUMN_TO_SORT_BY_KEYS[columnHeader],
    }));

  const getRowCandidate = (application, dealId) => ({
    content: t('common.formatting.fullName', {
      firstName: application.candidateFirstName,
      lastName: application.candidateLastName,
    }),
    url: `/${ROUTING_BASE_NAME}/${dealId}/candidate_detail/${application.id}`,
  });

  const getRowJob = job => ({
    content: job.title,
    url: `/${ROUTING_BASE_NAME}/${job.dealId}`,
  });

  const getApplicationJob = application =>
    customerJobs.find(job => job.id === application.jobId);

  const getActiveApplications = () =>
    applications.map(application => {
      const applicationJob = getApplicationJob(application);

      return {
        candidateFirstName: getRowCandidate(application, applicationJob.dealId),
        jobTitle: getRowJob(applicationJob),
        state: {
          content: t(`homepage.candidates.table.states.${application.state}`, {
            context: 'active',
          }),
        },
        lastUpdate: {
          content: differenceInDays(
            new Date(),
            new Date(application.lastUpdate),
          ),
        },
        numberOfClientInterviews: {
          content: application.numberOfClientInterviews,
        },
        latestInterviewAt: {
          content: t('common.formatting.date.dateFormat', {
            date: !!application.latestInterviewAt
              ? new Date(application.latestInterviewAt)
              : null,
          }),
        },
      };
    });

  const getHiredApplications = () =>
    applications.map(application => {
      const applicationJob = getApplicationJob(application);

      return {
        candidateFirstName: getRowCandidate(application, applicationJob.dealId),
        jobTitle: getRowJob(applicationJob),
        hireDate: {
          content: t('common.formatting.date.dateFormat', {
            date: new Date(application.hireDate),
          }),
        },
      };
    });

  const getStoppedApplications = () =>
    applications.map(application => {
      const applicationJob = getApplicationJob(application);

      return {
        candidateFirstName: getRowCandidate(application, applicationJob.dealId),
        jobTitle: getRowJob(applicationJob),
        state: {
          content: t(`homepage.candidates.table.states.${application.state}`),
        },
        lastUpdate: {
          content: t('common.formatting.date.dateFormat', {
            date: new Date(application.lastUpdate),
          }),
        },
        missedReason: {
          content: t('homepage.candidates.table.missedReason', {
            context: application.missedReason,
          }),
        },
        numberOfClientInterviews: {
          content: application.numberOfClientInterviews,
        },
      };
    });

  const tableRows = useMemo(() => {
    switch (stateCategory) {
      case CUSTOMER_APPLICATIONS_STATE_CATEGORIES.ACTIVE:
        return getActiveApplications();

      case CUSTOMER_APPLICATIONS_STATE_CATEGORIES.HIRED:
        return getHiredApplications();

      case CUSTOMER_APPLICATIONS_STATE_CATEGORIES.STOPPED:
        return getStoppedApplications();
    }
  }, [applications, stateCategory]);

  return { tableColumns, tableRows };
};
