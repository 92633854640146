import { Trans } from 'react-i18next';
import { AppSwitchTasksView } from '../AppSwitchTasksView';
import { useState } from 'react';
import { AppTasks } from '../AppTasks';
import { SEARCHING_TASKS_VIEW_TYPES } from '../../constants/searching';

export const SearchingTasks = () => {
  const [currentView, setCurrentView] = useState(
    SEARCHING_TASKS_VIEW_TYPES.LIST,
  );

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col col--x-padded small-12 large-9">
            <p className="typo-secondary-heading-05 u-color-primary-100">
              <Trans
                i18nKey={'searching.tasks.title'}
                components={{
                  strong: <strong className="u-color-secondary-100" />,
                }}
              />
            </p>
          </div>

          <div className="col col--x-padded small-12 large-3">
            <div className="c-app-switch">
              <div className="c-app-switch__items">
                {TASKS_VIEWS.map((view, viewIdx) => (
                  <AppSwitchTasksView
                    key={viewIdx}
                    view={view}
                    currentView={currentView}
                    setCurrentView={setCurrentView}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            <AppTasks currentView={currentView} />
          </div>
        </div>
      </div>
    </>
  );
};

const TASKS_VIEWS = Object.values(SEARCHING_TASKS_VIEW_TYPES);
