import { captureException } from '@sentry/react';

export const utilityThrowError = (error, fallbackMessage) => {
  const thrownError = utilityGetErrorInstance(error, fallbackMessage);

  captureException(thrownError);
  throw thrownError;
};

export const utilityGetErrorInstance = (error, fallbackMessage) => {
  if (!error) {
    return new Error(fallbackMessage || DEFAULT_ERROR_MESSAGE);
  }

  if (error instanceof Error) {
    return !!fallbackMessage
      ? new Error(fallbackMessage, { cause: error })
      : error;
  }

  if (error.errors && error.errors.length > 0) {
    return new Error(error.errors[0].message, { cause: error });
  }

  if (error.title && error.detail) {
    return new Error(`${error.title}: ${error.detail}`, { cause: error });
  }

  if (typeof error === 'string') {
    return new Error(error);
  }

  return new Error(fallbackMessage || DEFAULT_ERROR_MESSAGE, { cause: error });
};

const DEFAULT_ERROR_MESSAGE = 'No details available about this error.';
