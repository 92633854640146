import type { FC, ReactNode } from 'react';
import { Logo, SpriteSVG } from '@reverse-hr/pattern-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import TheFooter from '../TheFooter';

interface TErrorScreenProps {
  children?: ReactNode;
  className?: string;
  title?: string;
}

export const ErrorScreen: FC<TErrorScreenProps> = ({
  children,
  className,
  title,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'general.error',
  });

  const wrapperClassNames = classNames('c-error-screen', className);

  return (
    <main className={wrapperClassNames}>
      <header className="c-error-screen__header">
        <Logo modifier="c-error-screen__logo" />
      </header>

      <div className="c-error-screen__wrapper">
        <h1 className="c-error-screen__title">{title ?? t('title')}</h1>
        <div className="c-error-screen__content">{children}</div>
      </div>

      <TheFooter />
      <SpriteSVG />
    </main>
  );
};
