import React from 'react';
import useSkills from '../../../utils/hooks/application/use-skills';
import { ProgressBar } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';

const SkillTypes = {
  MUST_HAVE: 'must-have',
  NICE_TO_HAVE: 'nice-to-have',
};

export const Skills = ({ candidateSkills, isScoutReportAvailable }) => {
  const { t } = useTranslation();
  const { must_have, nice_to_have } = useSkills(candidateSkills);

  return candidateSkills.length > 0 ? (
    <div className="container">
      {must_have.length > 0 ? (
        <SkillGroup
          type={SkillTypes.MUST_HAVE}
          collection={must_have}
          isScoutReportAvailable={isScoutReportAvailable}
        />
      ) : null}

      {nice_to_have.length > 0 ? (
        <SkillGroup
          type={SkillTypes.NICE_TO_HAVE}
          collection={nice_to_have}
          isScoutReportAvailable={isScoutReportAvailable}
        />
      ) : null}
    </div>
  ) : null;
};

const SkillGroup = ({ type, collection, isScoutReportAvailable }) => {
  const { t } = useTranslation();

  const title = getTitle(type);

  return (
    <>
      <div className="row">
        <div className="col col--x-padded u-mt-space-40">
          <div className="el-separator--solid u-pb-space-40 u-border-color-grey-60" />
          <p className="typo-secondary-heading-05 u-color-primary-100">
            {t(title)}
          </p>
        </div>
      </div>
      <div className="row">
        {collection.map((skill, idx) => (
          <SkillItem
            {...skill}
            key={`${skill.jobRequirement.id}_${idx}`}
            isScoutReportAvailable={isScoutReportAvailable}
          />
        ))}
      </div>
    </>
  );
};

const SkillItem = ({ evaluation, jobRequirement, isScoutReportAvailable }) => {
  const { t } = useTranslation();

  const { description, requiresScoutValidation } = jobRequirement;

  const { type, messageI18nKey } = getHint(
    isScoutReportAvailable,
    requiresScoutValidation,
  );

  return (
    <div className="col col--x-padded small-6 large-2 u-pt-space-32">
      <ProgressBar
        isPercentageHidden
        label={description}
        value={evaluation}
        type={type === 'warning' ? 'warning' : 'stale'}
        hint={{
          type,
          message: t(messageI18nKey),
        }}
      />
    </div>
  );
};

// --- Helpers
const getHint = (isScoutReportAvailable, isScoutValidationRequired) => {
  if (isScoutReportAvailable && isScoutValidationRequired) {
    return {
      type: 'success',
      messageI18nKey: 'validation.scout_approved',
    };
  }

  if (isScoutValidationRequired) {
    return {
      type: 'warning',
      messageI18nKey: 'validation.scout_required',
    };
  }

  return {
    type: 'stale',
    messageI18nKey: '',
  };
};

const getTitle = type => {
  switch (type) {
    case SkillTypes.MUST_HAVE:
      return 'showcase.tableRows.must_have';
    case SkillTypes.NICE_TO_HAVE:
      return 'showcase.tableRows.nice_to_have';
    default:
      return '';
  }
};
