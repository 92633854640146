import React, { useState, useEffect, forwardRef } from 'react';
import ChatBubble from './ChatBubble';
import { Action, Icon } from '@reverse-hr/pattern-library';
import { Parser } from 'html-to-react';
import Feedbacks from '../loaders/Feedbacks';

const Chat = forwardRef(
  (
    {
      id,
      manager,
      modifier,
      loading,
      title,
      messages,
      collapsable,
      closable,
      showInput,
      labels,
      onToggle,
      onSend,
    },
    ref,
  ) => {
    const htmlParser = new Parser();
    const [message, setMessage] = useState('');
    const [textareaKey, setTextareaKey] = useState(0);
    const [sending, setSending] = useState(false);

    const getToggleButtonIcon = () => {
      if (collapsable && !closable) return 'icn-chevron-big-up-24';
      if (closable) return 'icn-chevron-big-down-24';
      return 'icn-cast-24';
    };

    const handleOnChange = event => {
      setMessage(event.target.value);
    };

    const handleToggleChat = () => {
      onToggle(!closable);
    };

    const handleOnSend = async () => {
      setSending(true);
      try {
        await onSend(message);
        setMessage('');
        setTextareaKey(textareaKey + 1);
      } catch (error) {
        console.warn(error);
      } finally {
        setSending(false);
      }
    };

    useEffect(() => {
      setTimeout(() => {
        const container = document.getElementById(`chat-messages-${id}`);
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      }, 0);
    }, [id, messages]);

    return (
      <div data-testid="chat-wrapper" className={`c-app-chat ${modifier}`}>
        <div className="c-app-chat__header">
          <p data-testid="chat-title" className="c-app-chat__header-text">
            {htmlParser.parse(title)}
          </p>

          <Action
            data-testid="chat-toggle"
            size="default"
            modifier="c-app-chat__modal-trigger"
            onClick={handleToggleChat}
            type="raw"
            label={
              collapsable || closable
                ? closable
                  ? labels.close
                  : labels.open
                : labels.fullscreen
            }
            iconPosition="right"
            icon={getToggleButtonIcon()}
            shadow={false}
          />
        </div>

        {loading ? (
          <div data-testid="chat-loading" className="c-app-chat__body">
            <div className="c-app-chat__messages">
              <Feedbacks />
            </div>
          </div>
        ) : messages && messages.length ? (
          <div data-testid="chat-messages" className="c-app-chat__body">
            <div className="c-app-chat__messages" id={`chat-messages-${id}`}>
              {messages.map((chatMessage, index) => {
                return (
                  <div
                    key={`message-${index}`}
                    id={
                      index === messages.length - 1
                        ? 'message-last'
                        : `message-${index}`
                    }
                    className="c-app-chat__message"
                  >
                    <ChatBubble
                      {...chatMessage}
                      type={chatMessage.isAuthorManager ? 'receiver' : 'sender'}
                      hourLabel={labels.hour}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : labels.default_message ? (
          <div data-testid="chat-default-message" className="c-app-chat__body">
            <div className="c-app-chat__messages">
              <div className="c-app-chat__message">
                <ChatBubble
                  date={{ date: new Date() }}
                  message={labels.default_message}
                  manager_img_url={manager.icon}
                  type={'receiver'}
                  manager={true}
                  hourLabel={labels.hour}
                />
              </div>
            </div>
          </div>
        ) : null}
        {showInput ? (
          <div className="c-app-chat__footer">
            <div className="c-app-chat__interaction">
              <textarea
                key={textareaKey}
                defaultValue={message}
                className="c-app-chat__textarea"
                name="textarea-chat"
                id="chat-message"
                rows="1"
                onChange={handleOnChange}
                disabled={sending}
                placeholder={labels.textareaPlaceholder}
              />
              <Action
                iconOnly
                type="secondary"
                icon="icn-short-right-24"
                onClick={handleOnSend}
                disabled={!message || sending}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  },
);

Chat.defaultProps = {
  modifier: '',
  labels: {},
  collapsable: false,
  closable: false,
  showInput: true,
  onSend: () => {},
  onToggle: () => {},
};

export default Chat;
