import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Range } from '@reverse-hr/pattern-library';
import { selectorDeal } from '../../../../../model/Deal';

const Salary = props => {
  const { salary } = props;
  const { salaryRange } = useStoreState(selectorDeal);

  const rangeRemappedStatuses = {
    left: 'positive',
    right: 'warning',
    center: 'positive',
  };

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--center-aligned">
      {!!salary ? (
        <Range
          min={Math.round(salaryRange.min.value / 1000)}
          max={Math.round(salaryRange.max.value / 1000)}
          value={Math.round(salary.value / 1000)}
          mappedStatuses={rangeRemappedStatuses}
        />
      ) : null}
    </td>
  );
};

export default Salary;
