import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useStoreActions } from 'easy-peasy';

const Modal = ({ visible, modifier, children }) => {
  const setModalOpened = useStoreActions(actions => actions.setModalOpened);

  const ModalWrapper = ({ children }) => {
    const handleOutsideClick = e => {
      const arr = Array.from(e.target.classList);
      if (arr.includes('c-app-modal__overlay')) {
        setModalOpened(false);
        if (childRef && childRef.current && childRef.current.cleanModal) {
          childRef.current.cleanModal();
        }
      }
    };

    const childRef = useRef(null);
    const childrenWithProps = React.Children.map(children, child => {
      return React.cloneElement(child, { ref: childRef });
    });
    return visible ? (
      <div
        aria-hidden
        data-testid="modal"
        className={`c-app-modal ${modifier}`}
      >
        <div
          onClick={handleOutsideClick}
          tabIndex="-1"
          data-testid="modal-overlay"
          className="c-app-modal__overlay"
        >
          <div
            role="dialog"
            aria-modal
            data-testid="modal-container"
            className={`c-app-modal__container`}
          >
            {childrenWithProps}
          </div>
        </div>
      </div>
    ) : null;
  };

  const elRef = useRef(null);
  if (!elRef.current) {
    const div = document.createElement('div');
    div.dataset.testid = 'modal-root';
    elRef.current = div;
  }

  useEffect(() => {
    let modalRoot = document.getElementById('modal-root');
    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.setAttribute('id', 'modal-root');
      document.body.appendChild(modalRoot);
    }
    modalRoot.appendChild(elRef.current);

    return () => modalRoot.removeChild(elRef.current);
  }, []);

  return createPortal(<ModalWrapper>{children}</ModalWrapper>, elRef.current);
};

Modal.defaultProps = {
  modifier: '',
  canClose: false,
};

const MemoizedModal = React.memo(Modal, (props, nextProps) => {
  if (props.visible === nextProps.visible) {
    return true;
  }
});

export default MemoizedModal;
