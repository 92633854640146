import { useMemo } from 'react';

export const SkillTypes = {
  MUST_HAVE: 'must_have',
  NICE_TO_HAVE: 'nice_to_have',
};

export const skillsMemoFactory = skills => {
  let must_have = [];
  let nice_to_have = [];
  const orderedSkills = skills.sort((a, b) => {
    const first = a.jobRequirement.description;
    const second = b.jobRequirement.description;
    return first.localeCompare(second);
  });

  orderedSkills.forEach(skill => {
    if (skill.jobRequirement.type === SkillTypes.MUST_HAVE)
      must_have.push(skill);
    if (skill.jobRequirement.type === SkillTypes.NICE_TO_HAVE)
      nice_to_have.push(skill);
  });

  return { must_have, nice_to_have };
};

const useSkills = candidateSkills => {
  return useMemo(() => {
    const skills = candidateSkills || [];
    return skillsMemoFactory(skills);
  }, [candidateSkills]);
};

export default useSkills;
