import React from 'react';
import Picto from './Picto';

const CandidateProfileInfos = () => {
  const rowsFirstColumn = [
    {
      icon: 'icn-phone-outline-24',
      width: 180,
    },
    {
      icon: 'icn-mail-24',
      width: 220,
    },
    {
      icon: 'icn-LinkedIn-24',
      width: 200,
    },
    {
      icon: 'icn-message-circle-24',
      width: 200,
    },
  ];
  return (
    <div className="c-profile-info__body">
      <ul className="c-profile-info__list">
        {rowsFirstColumn.map((obj, index) => {
          if (Array.isArray(obj)) {
            return (
              <li
                key={`${obj.icon}-${index}`}
                className="c-profile-info__list-item c-profile-info__list-item--multiple"
              >
                {obj.map((innerObj, i) => {
                  return (
                    <Picto
                      key={i}
                      icon={innerObj.icon}
                      width={innerObj.width}
                    />
                  );
                })}
              </li>
            );
          }
          return (
            <li
              key={`${obj.icon}-${index}`}
              className="c-profile-info__list-item"
            >
              <Picto icon={obj.icon} width={obj.width} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CandidateProfileInfos;
