import { useTranslation } from 'react-i18next';

export const ShowcaseOtherSelections = ({
  isCandidateActivelySearching,
  candidateCurrentSelectionProcessesCount,
  candidateCurrentSelectionProcessesNotes,
}) => {
  const { t } = useTranslation();

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--center-aligned">
      {isCandidateActivelySearching ? (
        <div className="c-app-showcase__motivation u-color-primary-80">
          <p className="typo-text-caption u-ml-space-8 u-txt-center">
            <strong>
              {t('showcase.otherSelections.currentSelectionsCount', {
                count: candidateCurrentSelectionProcessesCount,
              })}
            </strong>
            <br />
            {candidateCurrentSelectionProcessesNotes ?? null}
          </p>
        </div>
      ) : null}
    </td>
  );
};
