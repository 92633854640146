import type { FC } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PageLoginForm } from './PageLoginForm';

export const PageLoginBody: FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { t } = useTranslation('', {
    keyPrefix: 'login.requireLink',
  });

  const i18nContext = isSubmitted ? 'submitted' : '';

  const onFormSubmit = (): void => setIsSubmitted(true);

  return (
    <div className="c-page-login__body">
      <div className="c-page-login__wrapper">
        <div className="c-page-login__banner">
          <img
            alt={t('bannerAlt')}
            className="c-page-login__banner-image"
            src="/employer_view/assets/images/login-banner.jpg"
          />
        </div>

        <div className="c-page-login__content">
          <div className="c-page-login__intro">
            <h1 className="c-page-login__title">
              {t('title', { context: i18nContext })}
            </h1>

            <p className="c-page-login__description">
              <Trans
                i18nKey="login.requireLink.description"
                context={i18nContext}
              />
            </p>
          </div>

          {isSubmitted ? null : <PageLoginForm onSubmit={onFormSubmit} />}
        </div>
      </div>
    </div>
  );
};
