import classNames from 'classnames';
import React, { useContext } from 'react';
import { PageStateContext } from '../Providers/PageStateProvider';
import { DEAL_PAGE_STATES } from '../../constants/deal';
import Video from '../Video';
import { Referents } from '../Referents';
import { useTranslation } from 'react-i18next';

export const InterviewingSidebar = () => {
  const { t } = useTranslation();
  const pageState = useContext(PageStateContext);

  const isPageStateDoing = pageState === DEAL_PAGE_STATES.DOING;

  const wrapperClassNames = classNames('col col--x-padded small-12', {
    'large-6': !isPageStateDoing,
    'large-3 u-hidden-medium-down': isPageStateDoing,
  });

  return (
    <div className={wrapperClassNames}>
      {pageState === DEAL_PAGE_STATES.TO_DO ? (
        <div className="o-sidebar">
          <Video video="laptop" sources={['webm']} />
        </div>
      ) : null}

      {isPageStateDoing && (
        <img
          data-testid="interviewing-page-img"
          className="u-mt-space-32"
          width="256"
          src="/employer_view/assets/images/chat.png"
          alt={t('interviewing.header.title')}
        />
      )}

      {pageState === DEAL_PAGE_STATES.DONE ? <Referents /> : null}
    </div>
  );
};
