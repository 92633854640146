import classNames from 'classnames';
import { Icon } from '@reverse-hr/pattern-library';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const AppSwitchTasksView = ({ setCurrentView, view, currentView }) => {
  const { t } = useTranslation();

  const wrapperClassNames = classNames('c-app-switch__item', {
    'c-app-switch__item--active': view === currentView,
  });

  const handleClick = () => setCurrentView(view);

  return (
    <button className={wrapperClassNames} onClick={handleClick}>
      <div className="c-app-switch__item-icon">
        <Icon name={t('searching.tasks.viewIcon', { context: view })} />
      </div>

      <p className="c-app-switch__item-text">
        {t('searching.tasks.viewLabel', { context: view })}
      </p>
    </button>
  );
};

AppSwitchTasksView.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  currentView: PropTypes.string.isRequired,
};
