const SHOWCASE_EN = {
  title: 'Showcase',
  subtitle:
    'This page enables you to compare the features of each of the presented profiles. A further mean to help you choose the right one(s).',
  mobileView:
    'We have optimised this experience on Desktop, to make the data and candidate evaluations more accessible.',
  lastViewUpdate:
    'View saved on {{ date, datetime(format: MM/dd/yyyy) }} at {{ date, datetime(format: hh:mm a) }}',
  yes: 'Yes',
  no: 'No',
  bubbleText:
    'This page enables you to compare the features of each of the presented profiles. A further mean to help you choose the right one(s).',
  emptyCandidateList: 'Here there will be the list of candidates.',
  tableRows: {
    photo: 'Photo',
    age: 'Age',
    seniority: 'Seniority',
    location: 'Location',
    must_have: 'Must have',
    nice_to_have: 'Nice to have',
    current_salary: 'Current salary',
    salary: 'Desired salary',
    company: 'Company',
    other_selections: 'Other<1></1>selection<1></1>processes',
    motivation: 'Motivation',
    summary: 'Summary',
    benefits: 'Benefits',
  },
  filters: {
    restoreView: 'Restore view',
    interestedBut: 'Show <1>Interested but</1>',
    out: 'Show <1>Out candidates</1>',
  },
  benefitsCategories: {
    car: 'Car',
    canteen: 'Canteen',
    training: 'Training',
    welfare: 'Welfare',
    meal_vouchers: 'Meal vouchers',
    insurance: 'Insurance',
    smartphone: 'Smartphone',
    remote_working: 'Remote Working',
    personal_computer: 'PC',
  },
  concernsCategories: {
    brand: 'Brand',
    role: 'Role',
    compensation: 'Salary',
    location: 'Location',
  },
  location: {
    locationAndDistance: '<strong>{{ location }}</strong>',
    locationAndDistance_withDistance:
      '<strong>{{ location }}</strong> ({{ distance }}km)',
  },
  summary: {
    readmore: 'Leggi tutto',
    hide: 'Nascondi',
  },
  chip: {
    interestedBut: 'Interested but',
    out: 'Out',
  },
  motivationToChangeValues: {
    low: 'Low',
    high: 'High',
    medium: 'Medium',
  },
  drivingTime: {
    hours: '<b>{{ hours }}</b> h',
    minutes: '<b>{{ minutes }}</b> min',
  },
  otherSelections: {
    currentSelectionsCount_one: '{{ count }} active selection',
    currentSelectionsCount_other: '{{ count }} active selections',
  },
};

export default SHOWCASE_EN;
