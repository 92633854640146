import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiVerificationCodePost } from '../api/session';
import { ROUTING_BASE_NAME } from '../constants/navigation';
import { setJwt } from '../utils/session';
import { LoaderFullPage } from '../loaders/LoaderFullPage';
import { ErrorScreenExpiredLink } from '../components/ErrorScreen';

interface TLoginVerificationCodeParams {
  userId: string;
  verificationCode: string;
}

export const PageLoginVerificationCode: FC = () => {
  const [isLinkExpired, setIsLinkExpired] = useState(false);

  const { userId, verificationCode } =
    useParams<TLoginVerificationCodeParams>();

  const redirectPage =
    localStorage.getItem('EmployerView::authRedirectPath') ||
    `/${ROUTING_BASE_NAME}/`;

  const verifyCode = async (): Promise<void> => {
    try {
      const jwtResponse = await apiVerificationCodePost({
        userId,
        verificationCode,
      });

      setJwt(jwtResponse);

      window.location.assign(redirectPage);
    } catch (_error) {
      setIsLinkExpired(true);
    }
  };

  useEffect(() => {
    verifyCode();
  }, [userId, verificationCode]);

  return isLinkExpired ? <ErrorScreenExpiredLink /> : <LoaderFullPage />;
};
