import React from 'react';
import ContentLoader from 'react-content-loader';

const Feedbacks = ({ color }) => (
  <ContentLoader
    viewBox="0 0 600 300"
    backgroundColor={color ? color : 'rgb(207 208 210)'}
    foregroundColor="rgb(213 215 216)"
  >
    <circle cx="20" cy="40" r="20" />
    <rect x="50" y="4" rx="2" ry="2" width="120" height="10" />
    <rect x="50" y="20" rx="0" ry="0" width="120" height="20" />
    <rect x="50" y="20" rx="20" ry="20" width="550" height="60" />

    <circle cx="580" cy="140" r="20" />
    <rect x="425" y="104" rx="2" ry="2" width="120" height="10" />
    <rect x="0" y="120" rx="0" ry="0" width="120" height="20" />
    <rect x="0" y="120" rx="20" ry="20" width="550" height="80" />

    <circle cx="20" cy="250" r="20" />
    <rect x="50" y="224" rx="2" ry="2" width="120" height="10" />
    <rect x="50" y="240" rx="0" ry="0" width="120" height="20" />
    <rect x="50" y="240" rx="20" ry="20" width="550" height="60" />
  </ContentLoader>
);

export default Feedbacks;
