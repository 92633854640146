import classNames from 'classnames';
import { AppNumbersCard } from './AppNumbersCard';

export const AppNumbersRow = ({ row, isFirstRow }) => {
  const parsedJobStats = getJobStatsCardContent(row);

  const wrapperClassNames = classNames('o-grid-numbers', {
    'u-mt-space-16': !isFirstRow,
  });

  return (
    <div className={wrapperClassNames}>
      {parsedJobStats.map(cardContent => (
        <AppNumbersCard
          key={cardContent.titleKey}
          titleKey={cardContent.titleKey}
          contents={cardContent.contents}
        />
      ))}
    </div>
  );
};

const getJobStatsCardContent = row => {
  return Object.keys(row).map(jobStatKey => {
    const jobStatValue = row[jobStatKey];

    const jobStatValuesList =
      typeof jobStatValue === 'number' ? [jobStatValue] : jobStatValue;

    const jobStatsCardContents = jobStatValuesList.map(value => {
      if (typeof value !== 'number') {
        return {
          value: value.value,
          descriptionKey: value.reason,
        };
      }

      return {
        value,
        descriptionKey: jobStatKey,
      };
    });

    return {
      titleKey: jobStatKey,
      contents: jobStatsCardContents,
    };
  });
};
