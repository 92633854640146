import React from 'react';

const SimpleText = props => {
  const { text } = props;

  return (
    <td className="c-app-showcase__cell c-app-showcase__cell--center-aligned">
      <p className="typo-text-caption u-color-primary-80">{text}</p>
    </td>
  );
};

export default SimpleText;
