export const INTERVIEWING_IT = {
  header: {
    title: 'Colloqui',
    subtitle:
      'Qui trovi la lista dei candidati. Puoi sempre visualizzare la fase in cui si trovano e dare feedback per farli avanzare nel percorso di selezione.',
    'subtitle_to-do':
      'Qui ci sarà la lista dei candidati. Potrai sempre visualizzare la fase in cui si troveranno e dare feedback per farli avanzare nel percorso di selezione.',
    alert:
      'Ci sono persone nella fase finale, per non fartele sfuggire <strong>risolvi i dubbi in fretta</strong>',
  },
  referenceHeader: {
    title: 'I tuoi referenti',
  },
  tabs: {
    see: 'Visualizza per',
    all: 'Tutti',
    active: 'In selezione',
    discarded: 'Non proseguono',
  },
  eligibles: {
    curriculum_seen: 'Curriculum visto',
    curriculum_seen_text: 'Lascia un feedback',
    curriculum_not_seen: 'Curriculum non visto',
    curriculum_not_seen_text: 'Leggi e lascia un feedback',
    interesting: 'Interessante',
    not_interesting: 'Non interessante',
    withdrawn: 'Si ritira',
    out: 'Non prosegue',
  },
  scout: {
    to_be_created: 'In attesa del report',
    available: 'Report pubblicato',
    available_text: 'Leggi report',
    done: 'Report pubblicato',
    withdrawn: 'Si ritira',
    out: 'Non prosegue',
  },
  interviewing: {
    to_be_created: 'Da colloquiare',
    to_be_created_text: 'in fase di organizzazione...',
    incoming: 'Da colloquiare',
    incoming_text: 'Scarica dossier colloquio',
    outcome: 'Colloquio avvenuto',
    outcome_text: 'Lascia un feedback',
    done: 'Colloquio avvenuto',
    withdrawn: 'Si ritira',
    out: 'Non prosegue',
  },
  closing: {
    to_be_created: 'Fare offerta',
    to_be_created_text: 'Evitiamo che ci sfugga!',
    offered: 'Offerta fatta',
    offered_text: 'in attesa di {{ name }}',
    winner: 'Winner',
    withdrawn: 'Si ritira',
    out: 'Non prosegue',
    offer_declined: 'Offerta rifiutata',
  },
};
